import {useEffect, useState} from "react";
import useUserInfo from "./useUserInfo";


const UseCheckAccess = (currentComponentName) => {
    const [access, setAccess] = useState(true)
    const {userInfo} = useUserInfo()
    useEffect(() => {
        checkAccess()
    }, [])
    const checkAccess = () => {
        let arrayAllowsInformationsForUser = allowsInformation.filter(x => userInfo?.roles?.some(ui => ui?.name === x?.role))
        if(arrayAllowsInformationsForUser?.some(x => x?.accessComponentsName?.some(w => w === currentComponentName)))
            setAccess(true)
        else
            setAccess(false)
    }

    return access;
};

export default UseCheckAccess;
const allowsInformation = [
    {
        role: 'Member',
        accessComponentsName: [
            'clients',
            'client/detail',
            'tkps',
            'tkp/detail',
            'users',
            'user/detail',
            'reporttkp',
            'logistics',
            'fields',
            'statuses',
            'guids'
        ]
    },
    {
        role: 'Admin',
        accessComponentsName: [
            'clients'
        ]
    },
    {
        role: 'Guest',
        accessComponentsName: [
            'clients'
        ]
    }
]
