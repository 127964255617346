import {useState} from 'react';
import './ProjectSellList.css'
import useLoader from "../../../../../hooks/useLoader";
import {useNavigate, useParams} from "react-router";
import DefaultTable from "../../../../../components/defaulttable/DefaultTable";
import DefaultButton from "../../../../../components/UI/buttons/button/DefaultButton";
import useGetComponentsForProjectSellVariation from "../features/useGetComponentsForProjectSellVariation";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
const VALUE_SIZE_ITEM = '2fr 0.5fr 1fr 0.7fr 1fr 0.4fr'
const ProjectSellList = ({currentProject, setCurrentProject, signalRFunk}) => {
    const [searchValue, setSearchValue] = useState('')
    const {hideAll} = useLoader()
    const navigate = useNavigate()

    const addNewVariation = () => signalRFunk('AddSellVariation', [Number(currentProject?.id || 0)], res => {
        setCurrentProject(prev => {
            return {
                ...prev,
                variationSells: [...prev?.variationSells, res]
            }
        })
    })
    const deleteVariation = (guid) => signalRFunk('RemoveSellVariation', [guid], res => {
        setCurrentProject(prev => {
            return {
                ...prev,
                variationSells: prev?.variationSells?.filter(x => x?.guid !== guid)
            }
        })
    })
    const {arrayToReturn} = useGetComponentsForProjectSellVariation({
        removeItem: deleteVariation,
        detailCard: id => navigate(`sell/detail/${id}`)
    })

    const access = useCheckAccess("client/detail")
    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК ВАРИАЦИЙ ПРОДАЖИ'}/>
                <div className={'flex center gap'}>
                    <GuidsSearchInput searchValue={searchValue} setSearchValue={setSearchValue}/>

                    <DefaultButton
                        title={'Добавить вариацию'}
                        background={'blue'}
                        classNames={'button-extension'}
                        onClick={addNewVariation}
                    />
                </div>
            </GuidsHeader>
            <GuidsBody>
                <DefaultTable
                    items={currentProject?.variationSells || []}
                    components={arrayToReturn}
                    selectKeys={item => item?.guid}
                    VALUE_SIZES={VALUE_SIZE_ITEM}
                />
            </GuidsBody>
        </GuidsWrapper>
    );
};

export default ProjectSellList;