import React from 'react';
import './StatusInfoList.css'
import useLoader from "../../../../../hooks/useLoader";
import useMessageHub from "../../../../../hooks/useMessageHub";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
import DefaultTable from "../../../../../components/defaulttable/DefaultTable";
import DefaultButton from "../../../../../components/UI/buttons/button/DefaultButton";
import useGetComponentsForStatusInfoList from "../../features/useGetComponentsForStatusInfoList";

const VALUE_SIZE_ITEM = '1fr 1fr 1fr 2fr 0.4fr'
const StatusInfoList = ({SignalRContext, connectionRef, statusInItemList, setNewStatusGroup}) => {
    const {show, hide} = useLoader()
    const addMessage = useMessageHub()

    const addStatusInfo = () => {
        show()
        SignalRContext.invoke('CreateNewStatusInfo', statusInItemList?.id)
            .then(res => {
                setNewStatusGroup({...statusInItemList, statuses: [...statusInItemList?.statuses, res]})
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const deleteStatusInfo = (guid) => {
        show()
        SignalRContext.invoke('DeleteStatusInfo', guid, statusInItemList?.id)
            .then(res => {
                setNewStatusGroup({...statusInItemList, statuses: statusInItemList?.statuses?.filter(x => x?.guid !== guid)})
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const {changeItem} = useChangeItemSignalR({
        SignalRContext: SignalRContext,
        connectionRef: connectionRef,
        delay: 1000,
        method: 'ChangeStatusInfo',
        argsExternal: [statusInItemList?.id],
        callbackChangeItemFunk: newItem => {
            setNewStatusGroup({...statusInItemList, statuses: statusInItemList?.statuses?.map(item => {
                    if(item?.guid === newItem?.guid) return newItem;
                    else return item;
                })})
        }
    })
    const {arrayToReturn} = useGetComponentsForStatusInfoList({
        removeItem: deleteStatusInfo,
        changeItem: changeItem
    })
    return (
        <div className={'status-info-table__wrapper'}>
            <div className={'status-info-table__container'}>
                <DefaultTable
                    items={statusInItemList?.statuses || []}
                    selectKeys={item => item?.guid}
                    VALUE_SIZES={VALUE_SIZE_ITEM}
                    components={arrayToReturn}
                />
            </div>
            <div className={'status-info-table__btns'}>
                <DefaultButton
                    title={'Добавить status info'}
                    background={'blue'}
                    classNames={'button-extension'}
                    onClick={() => addStatusInfo()}
                />
            </div>
        </div>
    );
};

export default StatusInfoList;