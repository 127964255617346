import React, {useState} from 'react';
import './UserList.css'
import useLoader from "../../../../../hooks/useLoader";
import useMessageHub from "../../../../../hooks/useMessageHub";
import {useNavigate} from "react-router";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import DefaultButton from "../../../../../components/UI/buttons/button/DefaultButton";
import DefaultTable from "../../../../../components/defaulttable/DefaultTable";
import {createSignalRContext} from "react-signalr/signalr";
import useGetComponentsForUserList from "../../features/useGetComponentsForUserList";

const SignalRContext = createSignalRContext()
const VALUE_SIZE_ITEM = '1fr 1fr 1fr 1fr 0.4fr'
const UserList = () => {
    const [items, setItems] = useState([])
    const {show, hide} = useLoader()
    const addMessage = useMessageHub()
    const navigate = useNavigate()
    const {
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateAllUsers()
        },
        onReconnectCallback: () => {
            updateAllUsers()
        }
    })
    const updateAllUsers = () => {
        show()
        SignalRContext.invoke('GetAllUsers')
            .then(res => {
                if(res && Array.isArray(res))
                    setItems(res)
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const addNewUser = () => {
        SignalRContext.invoke('CreateNewUser')
            .then(res => {
                navigate(`detail/${res?.id}`)
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const removeUser = (guid) => {
        SignalRContext.invoke('DeleteUser', guid)
            .then(() => {
                setItems(prev => prev.filter(x => x?.guid !== guid))
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }

    const { arrayToReturn} = useGetComponentsForUserList({
        removeItem: removeUser,
        detailCard: id => navigate(`detail/${id}`)
    })
    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/api/crm/userhublist/`}
            connectEnabled={true}
            withCredentials={false}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <div className={'users-list__wrapper'}>
                <div className={'users-list__header'}>
                    <DefaultButton
                        title={'Добавить пользователя'}
                        background={'blue'}
                        classNames={'button-extension'}
                        onClick={addNewUser}
                    />
                </div>
                <div className={'users-list__items'}>
                    <DefaultTable
                        items={items}
                        components={arrayToReturn}
                        selectKeys={item => item?.guid}
                        VALUE_SIZES={VALUE_SIZE_ITEM}
                    />
                </div>
            </div>
        </SignalRContext.Provider>
    );
};

export default UserList;