import React from 'react';
import './LimitingContainer.css'

const LimitingContainer = ({children, width, height, styles}) => {
    return (
        <div style={{width: width, height: height, ...styles}} className={'limiting__container'}>
            {children}
        </div>
    );
};

export default LimitingContainer;