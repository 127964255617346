import {useState} from 'react';
import './ProjectList.css'
import {createSignalRContext} from "react-signalr/signalr";
import useLoader from "../../../../../hooks/useLoader";
import {useNavigate} from "react-router";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import DefaultButton from "../../../../../components/UI/buttons/button/DefaultButton";
import DefaultTable from "../../../../../components/defaulttable/DefaultTable";
import useGetComponentsForProjectList from "../features/useGetComponentsForProjectList";
import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import useSignalRFunk from "../../../../../hooks/useSignalRFunk";
import useUserInfo from "../../../../../hooks/useUserInfo";

const VALUE_SIZE_ITEM = '1fr 0.5fr 2fr 0.5fr 0.5fr'
const SignalRContext = createSignalRContext()
const ProjectList = () => {
    const [searchValue, setSearchValue] = useState('')
    const [items, setItems] = useState([])
    const {hideAll} = useLoader()
    const navigate = useNavigate()
    const {userInfo} = useUserInfo()
    const {
        checkConnection,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateAllProjects()
        },
        onReconnectCallback: () => {
            updateAllProjects()
        }
    })
    const {signalRFunk} = useSignalRFunk({
        SignalRContext,
        checkConnection
    })
    const updateAllProjects = () => signalRFunk('GetAllProjects', [], res => {
        if(res && Array.isArray(res))
            setItems(res)
    })

    const addNewProject = () => signalRFunk('AddNewProject', [], res => {
        navigate(`detail/${res?.id}`)
    })
    const removeProject = (guid) => signalRFunk('RemoveProject', [guid], () => {
        setItems(prev => prev.filter(x => x?.guid !== guid))
    })

    const { arrayToReturn} = useGetComponentsForProjectList({
        removeItem: removeProject,
        detailCard: id => navigate(`detail/${id}`)
    })

    const access = useCheckAccess("client/detail")
    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/api/crm/projecthublist/?token=${userInfo?.accessToken || ''}`}
            connectEnabled={true}
            withCredentials={false}
            accessTokenFactory={() => userInfo?.accessToken || ''}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <GuidsWrapper>
                <GuidsHeader>
                    <TitleDetailCard text={'СПИСОК ПРОЕКТОВ'}/>
                    <div className={'flex center gap'}>
                        <GuidsSearchInput searchValue={searchValue} setSearchValue={setSearchValue}/>

                        <DefaultButton
                            title={'Добавить проект'}
                            background={'blue'}
                            classNames={'button-extension'}
                            onClick={addNewProject}
                        />
                    </div>
                </GuidsHeader>
                <GuidsBody>
                    <DefaultTable
                        items={items}
                        components={arrayToReturn}
                        selectKeys={item => item?.guid}
                        VALUE_SIZES={VALUE_SIZE_ITEM}
                    />
                </GuidsBody>
            </GuidsWrapper>
        </SignalRContext.Provider>
    );
};

export default ProjectList;