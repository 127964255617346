import {useState} from 'react';
import './CompaniesList.css'
import DefaultTable from "../../../../../../components/defaulttable/DefaultTable";
import useGetComponentForCompaniesClient from "./useGetComponentForCompaniesClient";
import DefaultButton from "../../../../../../components/UI/buttons/button/DefaultButton";
import DefaultModal from "../../../../../../components/UI/modals/DefaultModal";
import DefaultInputSuffics from "../../../../../../components/UI/inputs/tkpdefaultinputsuffics/DefaultInputSuffics";
import useLoader from "../../../../../../hooks/useLoader";
import useMessageHub from "../../../../../../hooks/useMessageHub";
import useChangeItemSignalR from "../../../../../../hooks/useChangeItemSignalR";
const VALUE_SIZE_ITEM = '2fr 2fr 1fr 1fr 0.4fr'
const CompaniesList = ({currentClient, setCurrentClient, SignalRContext, connectionRef}) => {
    const {show, hide, hideAll} = useLoader()
    const addMessage = useMessageHub()
    const [showModalCheckInn, setShowModalCheckInn] = useState(false)
    const [innToCheck, setInnToCheck] = useState(0)

    const addCompany = () => {
        show()
        SignalRContext.invoke('CreateNewCompany', innToCheck, currentClient?.id)
            .then(res => {
                if(!currentClient?.companyInfo?.some(x => x?.company?.guid === res?.company?.guid))
                    setCurrentClient(prev => {
                        return {
                            ...prev,
                            companyInfo: [...prev?.companyInfo, res]
                        }
                    })
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
                setShowModalCheckInn(false)
                setInnToCheck(0)
            })
    }
    const deleteCompanyFromClient = (guid) => {
        show()
        SignalRContext.invoke('DeleteCompanyFromClient', guid, currentClient?.id)
            .then(res => {
                setCurrentClient(prev => {
                    return {
                        ...prev,
                        companyInfo: prev?.companyInfo?.filter(x => x?.guid !== guid)
                    }
                })
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const {changeItem} = useChangeItemSignalR({
        SignalRContext: SignalRContext,
        connectionRef: connectionRef,
        delay: 1000,
        method: 'ChangeCompanyForClient',
        argsExternal: [currentClient?.id],
        callbackChangeItemFunk: newItem => {
            setCurrentClient(prev => {
                return {
                    ...prev,
                    companyInfo: prev?.companyInfo?.map(item => {
                        if(item?.guid === newItem?.guid) return newItem
                        else return item;
                    })
                }
            })
        }
    })
    const {arrayToReturn} = useGetComponentForCompaniesClient({
        removeItem: deleteCompanyFromClient,
        changeItem: changeItem
    })

    return (
        <div className={'companies-table__wrapper'}>
            <div className={'companies-table__container'}>
                <DefaultTable
                    items={currentClient?.companyInfo || []}
                    selectKeys={item => item?.guid}
                    VALUE_SIZES={VALUE_SIZE_ITEM}
                    components={arrayToReturn}
                />
            </div>
            <div className={'companies-table__btns'}>
                <DefaultButton
                    title={'Добавить компанию'}
                    background={'blue'}
                    classNames={'button-extension'}
                    onClick={() => setShowModalCheckInn(true)}
                />
            </div>
            {showModalCheckInn &&
                <DefaultModal visible={showModalCheckInn} setVisible={e => setShowModalCheckInn(e)}>
                    <div className={'check-modal-inn__wrapper'}>
                        <h2 className={'check-modal-inn__title'}>Введите ИНН</h2>
                        <DefaultInputSuffics
                            value={innToCheck}
                            placeholderDefault={'ИНН'}
                            setValue={e => setInnToCheck(e)}
                            classNames={'input-extension'}
                        />
                        <DefaultButton
                            title={'Создать'}
                            background={'blue'}
                            classNames={'button-extension'}
                            onClick={() => addCompany()}
                        />
                    </div>
                </DefaultModal>
            }
        </div>
    );
};

export default CompaniesList;