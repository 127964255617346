import './GuidsSearchInput.css'
import SearchIcon from "../../UI/icons/SearchIcon";
import MainInput from "../../UI/inputs/maininput/MainInput";
const GuidsSearchInput = ({searchValue, setSearchValue}) => {
    return (
        <div className={'flex center guids__header_btn'}>
            <SearchIcon />
            <MainInput
                value={searchValue}
                setValue={e => setSearchValue(e)}
                placeholderDefault={'Поиск'}
            />
        </div>
    );
};

export default GuidsSearchInput;