import React from 'react';
import LimitingContainer from "../../../../../components/containers/limitingcontainer/LimitingContainer";
import {StylesObjects} from "../../../../../features/stylesobjects/StylesObjects";
import DefaultLabel from "../../../../../components/UI/labels/defaultlabel/DefaultLabel";

const UseGetComponentsForProjectList = ({removeItem, detailCard}) => {
    const arrayToReturn = [
        {
            Key: 1,
            TitleColumn: 'Наименование',
            invokeComponent: (item, callbackAction) => {


                return (
                    <LimitingContainer width={'100%'} height={'100%'} styles={{...StylesObjects.displayFlexCenter, ...StylesObjects.paddingSliceLimitingContainer}}>
                        <DefaultLabel text={item?.name || ''}/>
                    </LimitingContainer>
                )

            }
        },
        {
            Key: 2,
            TitleColumn: 'Ответственный',
            invokeComponent: (item, callbackAction) => {


                return (
                    <LimitingContainer width={'100%'} height={'100%'} styles={{...StylesObjects.displayFlexCenter, ...StylesObjects.paddingSliceLimitingContainer}}>
                        <DefaultLabel text={item?.assignedBtProject?.fio || ''}/>
                    </LimitingContainer>
                )

            }
        },
        {
            Key: 3,
            TitleColumn: 'Адресс',
            invokeComponent: (item, callbackAction) => {


                return (
                    <LimitingContainer width={'100%'} height={'100%'} styles={{...StylesObjects.displayFlexCenter, ...StylesObjects.paddingSliceLimitingContainer}}>
                        <DefaultLabel text={item?.address || ''}/>
                    </LimitingContainer>
                )

            }
        },
        {
            Key: 4,
            TitleColumn: 'Дата создания',
            invokeComponent: (item, callbackAction) => {
                return (
                    <LimitingContainer width={'100%'} height={'100%'} styles={{...StylesObjects.displayFlexCenter, ...StylesObjects.paddingSliceLimitingContainer}}>
                        <DefaultLabel text={new Date(item?.createdTime || null)?.toLocaleDateString() || ''}/>
                    </LimitingContainer>
                )

            }
        },
        {
            Key: 5,
            TitleColumn: 'Действия',
            invokeComponent: (item, callbackAction) => {
                return (
                    <LimitingContainer width={'100%'} height={'100%'} styles={{...StylesObjects.displayFlexCenter, ...StylesObjects.paddingSliceLimitingContainer}}>
                        <div className={'btns__slices'}>
                            <div className={'icon'}>
                                <i title={'Детальная карточка'} onClick={() => detailCard(item?.id)}
                                   className="fa-solid fa-pen-to-square"></i>
                            </div>
                            <div className={'icon'}>
                                <i title={'Удалить'} onClick={() => removeItem(item?.guid)}
                                   className="fa-solid fa-trash"></i>
                            </div>
                        </div>

                    </LimitingContainer>
                )

            }
        },
    ]
    return {
        arrayToReturn
    };
};

export default UseGetComponentsForProjectList;