
const InvoiceIcon = ({classNames}) => {
    return (
        <svg className={classNames} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.33337 1.33334V5.33334H13.3334M2.66671 14.6667H12C12.3537 14.6667 12.6928 14.5262 12.9428 14.2762C13.1929 14.0261 13.3334 13.687 13.3334 13.3333V5.00001L9.66671 1.33334H4.00004C3.64642 1.33334 3.30728 1.47382 3.05723 1.72387C2.80718 1.97392 2.66671 2.31305 2.66671 2.66668V5.33334M6.66671 8.00001H8.00004V12M6.66671 12H9.33337M2.66671 8.00001C3.40309 8.00001 4.00004 8.59696 4.00004 9.33334V10.6667C4.00004 11.4031 3.40309 12 2.66671 12C1.93033 12 1.33337 11.4031 1.33337 10.6667V9.33334C1.33337 8.59696 1.93033 8.00001 2.66671 8.00001Z" stroke="#6C7275" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default InvoiceIcon;