import {useEffect, useRef, useState} from "react";
import './MainInput.css'
import {animated, config, useSpring} from "@react-spring/web";

const MainInput = ({value = '', setValue, suffics = '', isPrefix = false, valid = true, toFixedValue, placeholderDefault = "", classNames = '', ...props}) => {

    const defaultType = typeof (value)
    const [onFocus, setOnFocus] = useState(false)
    const checkValue = defaultType === 'number' && ((value % 2) !== 1) ? Number(value.toFixed(2)) : value
    const prepareValue = defaultType === 'number' && toFixedValue ? checkValue : checkValue;
    const handleValue = isPrefix ? suffics + prepareValue : prepareValue + suffics
    const [inputStyle, api] = useSpring(() => ({
        color:  value ? 'black' : '#6C727580',
        config: config.wobbly
    }))

    useEffect(() => {
        if(valid){
            api.start(() => ({
                color:  value ? 'black' : '#6C727580',
            }))
        }
        else{
            api.start(() => ({
                color: 'var(--color-red)',
            }))
        }

    }, [valid])
    useEffect(() => {
        if(!onFocus && value){
            refInput.current.value = handleValue;
        }
    }, [value])
    const refInput = useRef(null)

    return (
        <div className={`main__input_wrapper ${classNames}`}>
            <animated.input
                className={`main__input`}
                ref={refInput}
                style={{...inputStyle, ...props.style}}
                defaultValue={value ? handleValue : suffics}
                placeholder={placeholderDefault}
                onBlur={e => {
                    api.start(() => ({
                        color: value ? 'black' : '#6C727580',
                    }))
                    setOnFocus(false)
                    e.target.value = handleValue

                }}
                onFocus={e => {
                    api.start(() => ({
                        color: 'black',
                    }))
                    setOnFocus(true)
                    if(defaultType === 'number'){
                        if(value !== 0){
                            if(toFixedValue){
                                e.target.value = checkValue.toFixed(toFixedValue)
                            }
                            else{
                                e.target.value = checkValue
                            }
                        }
                        else {
                            e.target.value = ''
                        }
                    }
                    else{
                        e.target.value = e.target.value.replace(suffics, "")
                    }
                }}
                onChange={e => {

                    if(defaultType === 'number'){
                        setValue?.((Number(e.target.value)) || 0)
                    }
                    else{
                        setValue?.(e.target.value)
                    }
                }}
                {...props}
            />
        </div>
    );
};

export default MainInput;