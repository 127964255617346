import './AppealBuyDetailInformation.css'
import {useState} from "react";
import TitleDetailCard from "../../../../../../../components/UI/titledetailcard/TitleDetailCard";
import DetailCardInformationBlock
    from "../../../../../../../components/detailcard/information/DetailCardInformationBlock";
import FieldBlock from "../../../../../../../components/fieldblock/FieldBlock";
import Field from "../../../../../../../components/UI/field/Field";
import ListIcon from "../../../../../../../components/UI/icons/ListIcon";
import ClockIcon from "../../../../../../../components/UI/icons/ClockIcon";
import {
    converterEntityToOptionOrReverse,
    getOptionsFromArrayEntities
} from "../../../../../../../features/entityhelper/EntityHelper";
const AppealBuyDetailInformation = ({
                                        changeItem,
                                        currentAppeal,
                                        setShowSelectorUsers,
                                        typeRealtyTypeOptions,
                                        typeCommunicationOptions,
                                        typeFloorObjectOptions,
                                        typeLandCategoryOptions,
                                        typeEntranceOptions,
                                        typeHeatingsOptions,
                                        typeIntendedPurposeOptions,
                                        typeObjectStatusOptions,
                                        typeParkingAvailabilityOptions,
                                        typeDistrictOptions,
                                        typeLineOfPlacementOptions,
                                        dataIsLoadedRef,
                                        isOficeManagerStage,
                                        stages,
                                        sources,
                                        typeUnloadingAvalabilityOptions,
                                        typeSeparateEntranceOptions,

                                    }) => {
    const [isEdit, setIsEdit] = useState(false)

    return (
        <>
            <TitleDetailCard text={'Изменить данные обращения'}/>
            <DetailCardInformationBlock>
                <FieldBlock title={'Общая информация'}>
                    <Field
                        isEdit={isEdit}
                        icon={<ListIcon/>}
                        labelText={'Наименование'}
                        type={'input'}
                        value={currentAppeal?.generalName || ''}
                        titleValue={currentAppeal?.generalName || ''}
                        placeholder={'Наименование'}
                        setValue={e => changeItem({...currentAppeal, generalName: e})}
                    />
                    <Field
                        isEdit={isEdit}
                        icon={<ListIcon/>}
                        labelText={'Информация о обращении'}
                        type={'input'}
                        value={currentAppeal?.note || ''}
                        titleValue={currentAppeal?.note || ''}
                        placeholder={'Информация'}
                        setValue={e => changeItem({...currentAppeal, note: e})}
                    />
                    {!isOficeManagerStage
                        ?
                            <Field
                                labelText={'Стадия'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Стадия'}
                                options={getOptionsFromArrayEntities({
                                    entities: stages || [],
                                    accessorName: 'stages',
                                    valueCallback: item => item?.statusName,
                                    titleCallback: item => item?.statusName,
                                    idCallback: item => item?.id
                                })}
                                value={converterEntityToOptionOrReverse({
                                    endpoint: 'option',
                                    arrayEntities: stages,
                                    accessorName: 'stages',
                                    valueCallback: item => item?.statusName,
                                    titleCallback: item => item?.statusName,
                                    idCallback: item => item?.id,
                                    searchPropertyForArray: currentAppeal?.stage?.id
                                })}
                                setValue={e => changeItem({...currentAppeal, stage: converterEntityToOptionOrReverse({
                                        endpoint: 'entity',
                                        arrayEntities: stages,
                                        accessorName: 'stages',
                                        valueCallback: item => item?.statusName,
                                        titleCallback: item => item?.statusName,
                                        idCallback: item => item?.id,
                                        searchPropertyForArray: e?.id
                                    })}, false)}
                                titleValue={currentAppeal?.stage?.statusName || ""}
                                icon={<ListIcon/>}
                            />
                        :
                            <Field
                                type={'label'}
                                value={currentAppeal?.stage?.statusName || ""}
                                labelText={'Стадия'}
                            />
                    }
                    <Field
                        labelText={'Источник'}
                        isEdit={isEdit}
                        type={'selector'}
                        placeholder={'Источник'}
                        options={getOptionsFromArrayEntities({
                            entities: sources || [],
                            accessorName: 'sources',
                            valueCallback: item => item?.statusName,
                            titleCallback: item => item?.statusName,
                            idCallback: item => item?.id
                        })}
                        value={converterEntityToOptionOrReverse({
                            endpoint: 'option',
                            arrayEntities: sources,
                            accessorName: 'sources',
                            valueCallback: item => item?.statusName,
                            titleCallback: item => item?.statusName,
                            idCallback: item => item?.id,
                            searchPropertyForArray: currentAppeal?.source?.id
                        })}
                        setValue={e => changeItem({...currentAppeal, source: converterEntityToOptionOrReverse({
                                endpoint: 'entity',
                                arrayEntities: sources,
                                accessorName: 'sources',
                                valueCallback: item => item?.statusName,
                                titleCallback: item => item?.statusName,
                                idCallback: item => item?.id,
                                searchPropertyForArray: e?.id
                            })}, false)}
                        titleValue={currentAppeal?.source?.statusName || ""}
                        icon={<ListIcon/>}
                    />
                    <Field
                        icon={<ClockIcon/>}
                        type={'label'}
                        value={currentAppeal?.lastActivity || "В разработке"}
                        labelText={'Дата крайнего взаимодействия'}
                    />
                    <Field
                        type={'label'}
                        value={currentAppeal?.assigned?.fio || ""}
                        labelText={'Ответственный'}
                    />
                    {!isOficeManagerStage && dataIsLoadedRef
                        ?
                            <FieldBlock title={'Коммерческие требования'}>
                                <Field
                                    labelText={'Цена за квадратный метр'}
                                    isEdit={isEdit}
                                    type={'multi-input'}
                                    placeholder={'от'}
                                    placeholderTwo={'до'}
                                    value={currentAppeal?.commercialRequirements?.priceForSquareMeterFrom || 0}
                                    valueTwo={currentAppeal?.commercialRequirements?.priceForSquareMeterTo || 0}
                                    setValue={e => changeItem({...currentAppeal, commercialRequirements: {...currentAppeal?.commercialRequirements, priceForSquareMeterFrom: e}})}
                                    setValueTwo={e => changeItem({...currentAppeal, commercialRequirements: {...currentAppeal?.commercialRequirements, priceForSquareMeterTo: e}})}
                                    titleValue={currentAppeal?.commercialRequirements?.priceForSquareMeterFrom || ""}
                                    titleValueTwo={currentAppeal?.commercialRequirements?.priceForSquareMeterTo || ""}
                                    suffics={' ₽'}
                                    sufficsTwo={' ₽'}
                                    icon={<ListIcon/>}
                                    inputClassNames={'input-extension-multi'}
                                />
                                <Field
                                    labelText={'Бюджет'}
                                    isEdit={isEdit}
                                    type={'multi-input'}
                                    placeholder={'от'}
                                    placeholderTwo={'до'}
                                    value={currentAppeal?.commercialRequirements?.priceFrom || 0}
                                    valueTwo={currentAppeal?.commercialRequirements?.priceTo || 0}
                                    setValue={e => changeItem({...currentAppeal, commercialRequirements: {...currentAppeal?.commercialRequirements, priceFrom: e}})}
                                    setValueTwo={e => changeItem({...currentAppeal, commercialRequirements: {...currentAppeal?.commercialRequirements, priceTo: e}})}
                                    titleValue={currentAppeal?.commercialRequirements?.priceFrom || ""}
                                    titleValueTwo={currentAppeal?.commercialRequirements?.priceTo || ""}
                                    suffics={' ₽'}
                                    sufficsTwo={' ₽'}
                                    icon={<ListIcon/>}
                                    isMoney={true}
                                    inputClassNames={'input-extension-multi'}
                                />
                                <Field
                                    labelText={'Стоимость коммунальных расходов'}
                                    isEdit={isEdit}
                                    type={'multi-input'}
                                    placeholder={'от'}
                                    placeholderTwo={'до'}
                                    value={currentAppeal?.commercialRequirements?.utilitiesCostsFrom || 0}
                                    valueTwo={currentAppeal?.commercialRequirements?.utilitiesCostsTo || 0}
                                    setValue={e => changeItem({...currentAppeal, commercialRequirements: {...currentAppeal?.commercialRequirements, utilitiesCostsFrom: e}})}
                                    setValueTwo={e => changeItem({...currentAppeal, commercialRequirements: {...currentAppeal?.commercialRequirements, utilitiesCostsTo: e}})}
                                    titleValue={currentAppeal?.commercialRequirements?.utilitiesCostsFrom || ""}
                                    titleValueTwo={currentAppeal?.commercialRequirements?.utilitiesCostsTo || ""}
                                    suffics={' ₽'}
                                    sufficsTwo={' ₽'}
                                    icon={<ListIcon/>}
                                    isMoney={true}
                                    inputClassNames={'input-extension-multi'}
                                />
                                <Field
                                    labelText={'Окупаемость'}
                                    isEdit={isEdit}
                                    type={'multi-input'}
                                    placeholder={'от'}
                                    placeholderTwo={'до'}
                                    value={currentAppeal?.commercialRequirements?.paybackFrom || 0}
                                    valueTwo={currentAppeal?.commercialRequirements?.paybackTo || 0}
                                    setValue={e => changeItem({...currentAppeal, commercialRequirements: {...currentAppeal?.commercialRequirements, paybackFrom: e}})}
                                    setValueTwo={e => changeItem({...currentAppeal, commercialRequirements: {...currentAppeal?.commercialRequirements, paybackTo: e}})}
                                    titleValue={currentAppeal?.commercialRequirements?.paybackFrom || ""}
                                    titleValueTwo={currentAppeal?.commercialRequirements?.paybackTo || ""}
                                    suffics={' лет'}
                                    sufficsTwo={' лет'}
                                    icon={<ListIcon/>}
                                    inputClassNames={'input-extension-multi'}
                                />
                                <FieldBlock title={'Локационные требования'}>
                                    <Field
                                        labelText={'Рассматриваемые районы'}
                                        isEdit={isEdit}
                                        type={'multi-selector'}
                                        placeholder={'Выбрать'}
                                        options={typeDistrictOptions}
                                        value={currentAppeal?.locationRequirements?.districts || []}
                                        setValue={newArray => changeItem({...currentAppeal, locationRequirements: {...currentAppeal?.locationRequirements, districts: newArray}}, true, [], false)}
                                        icon={<ListIcon/>}
                                    />
                                    <Field
                                        labelText={'Линия размещения'}
                                        isEdit={isEdit}
                                        type={'multi-selector'}
                                        placeholder={'Выбрать'}
                                        options={typeLineOfPlacementOptions}
                                        value={currentAppeal?.locationRequirements?.lineOfPlacement || []}
                                        setValue={newArray => changeItem({...currentAppeal, locationRequirements: {...currentAppeal?.locationRequirements, lineOfPlacement: newArray}}, true, [], false)}
                                        icon={<ListIcon/>}
                                    />
                                </FieldBlock>
                            </FieldBlock>
                        :   null
                    }
                    {currentAppeal?.stage?.statusValue === 'new' &&
                        <Field
                            type={'button'}
                            setValue={() => changeItem({...currentAppeal, stage: stages?.find(x => x.statusValue === "officeManager")}, false)}
                            labelText={'Взять в работу'}
                            background={'blue'}
                            classNames={'button-extension'}
                        />
                    }
                    {currentAppeal?.stage?.statusValue === 'officeManager' &&
                        <Field
                            type={'button'}
                            setValue={() => setShowSelectorUsers(true)}
                            labelText={'Передать брокеру'}
                            background={'blue'}
                            classNames={'button-extension'}
                        />
                    }
                    {!isOficeManagerStage && dataIsLoadedRef ?
                        <Field
                            type={'button'}
                            setValue={() => setShowSelectorUsers(true)}
                            labelText={'Сменить ответственного'}
                            background={'blue'}
                            classNames={'button-extension'}
                        />
                        : null
                    }

                    <Field
                        type={'button'}
                        setValue={() => setIsEdit(!isEdit)}
                        labelText={isEdit ? "Сохранить" : 'Редактировать'}
                        background={isEdit ? "red" : 'blue'}
                        classNames={'button-extension'}
                    />
                </FieldBlock>
                {!isOficeManagerStage && dataIsLoadedRef
                    ?
                        <>
                            <FieldBlock title={'Технические требования'}>
                                <Field
                                    labelText={'Тип недвижимости'}
                                    isEdit={isEdit}
                                    type={'multi-selector'}
                                    placeholder={'Выбрать'}
                                    options={typeRealtyTypeOptions}
                                    value={currentAppeal?.technicalRequirements?.realtyType || []}
                                    setValue={newArray => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, realtyType: newArray}}, true, [], false)}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Коммуникации'}
                                    isEdit={isEdit}
                                    type={'multi-selector'}
                                    placeholder={'Выбрать'}
                                    options={typeCommunicationOptions}
                                    value={currentAppeal?.technicalRequirements?.communication || []}
                                    setValue={newArray => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, communication: newArray}}, true, [], false)}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Этажи'}
                                    isEdit={isEdit}
                                    type={'multi-selector'}
                                    placeholder={'Выбрать'}
                                    options={typeFloorObjectOptions}
                                    value={currentAppeal?.technicalRequirements?.floorObject || []}
                                    setValue={newArray => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, floorObject: newArray}}, true, [], false)}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Категория земли'}
                                    isEdit={isEdit}
                                    type={'multi-selector'}
                                    placeholder={'Выбрать'}
                                    options={typeLandCategoryOptions}
                                    value={currentAppeal?.technicalRequirements?.landCategory || []}
                                    setValue={newArray => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, landCategory: newArray}}, true, [], false)}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Вход'}
                                    isEdit={isEdit}
                                    type={'multi-selector'}
                                    placeholder={'Выбрать'}
                                    options={typeEntranceOptions}
                                    value={currentAppeal?.technicalRequirements?.entrance || []}
                                    setValue={newArray => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, entrance: newArray}}, true, [], false)}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Тип недвижимости'}
                                    isEdit={isEdit}
                                    type={'multi-selector'}
                                    placeholder={'Выбрать'}
                                    options={typeRealtyTypeOptions}
                                    value={currentAppeal?.technicalRequirements?.realtyType || []}
                                    setValue={newArray => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, realtyType: newArray}}, true, [], false)}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Отопление'}
                                    isEdit={isEdit}
                                    type={'multi-selector'}
                                    placeholder={'Выбрать'}
                                    options={typeHeatingsOptions}
                                    value={currentAppeal?.technicalRequirements?.heatings || []}
                                    setValue={newArray => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, heatings: newArray}}, true, [], false)}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Целевое назначение'}
                                    isEdit={isEdit}
                                    type={'multi-selector'}
                                    placeholder={'Выбрать'}
                                    options={typeIntendedPurposeOptions}
                                    value={currentAppeal?.technicalRequirements?.intendedPurpose || []}
                                    setValue={newArray => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, intendedPurpose: newArray}}, true, [], false)}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Состояние объекта'}
                                    isEdit={isEdit}
                                    type={'multi-selector'}
                                    placeholder={'Выбрать'}
                                    options={typeObjectStatusOptions}
                                    value={currentAppeal?.technicalRequirements?.objectStatus || []}
                                    setValue={newArray => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, objectStatus: newArray}}, true, [], false)}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Парковка'}
                                    isEdit={isEdit}
                                    type={'multi-selector'}
                                    placeholder={'Выбрать'}
                                    options={typeParkingAvailabilityOptions}
                                    value={currentAppeal?.technicalRequirements?.parkingAvailability || []}
                                    setValue={newArray => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, parkingAvailability: newArray}}, true, [], false)}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Отдельный вход'}
                                    isEdit={isEdit}
                                    type={'selector'}
                                    placeholder={'Вход'}
                                    options={typeSeparateEntranceOptions}
                                    value={currentAppeal?.technicalRequirements?.separateEntrance}
                                    setValue={e => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, separateEntrance: e}}, false)}
                                    titleValue={currentAppeal?.technicalRequirements?.separateEntrance?.value || ""}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Разгрузка'}
                                    isEdit={isEdit}
                                    type={'selector'}
                                    placeholder={'Разгрузка'}
                                    options={typeUnloadingAvalabilityOptions}
                                    value={currentAppeal?.technicalRequirements?.unloadingAvalability}
                                    setValue={e => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, unloadingAvalability: e}}, false)}
                                    titleValue={currentAppeal?.technicalRequirements?.unloadingAvalability?.value || ""}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Мощность электросети'}
                                    isEdit={isEdit}
                                    type={'multi-input'}
                                    placeholder={'от'}
                                    placeholderTwo={'до'}
                                    value={currentAppeal?.technicalRequirements?.powerFrom || 0}
                                    valueTwo={currentAppeal?.technicalRequirements?.powerTo || 0}
                                    setValue={e => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, powerFrom: e}})}
                                    setValueTwo={e => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, powerTo: e}})}
                                    titleValue={currentAppeal?.technicalRequirements?.powerFrom || ""}
                                    titleValueTwo={currentAppeal?.technicalRequirements?.powerTo || ''}
                                    suffics={' кВт'}
                                    sufficsTwo={' кВт'}
                                    icon={<ListIcon/>}
                                    inputClassNames={'input-extension-multi'}
                                />
                                <Field
                                    labelText={'Требуемая площадь'}
                                    isEdit={isEdit}
                                    type={'multi-input'}
                                    placeholder={'от'}
                                    placeholderTwo={'до'}
                                    value={currentAppeal?.technicalRequirements?.squareFrom || 0}
                                    valueTwo={currentAppeal?.technicalRequirements?.squareTo || 0}
                                    setValue={e => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, squareFrom: e}})}
                                    setValueTwo={e => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, squareTo: e}})}
                                    titleValue={currentAppeal?.technicalRequirements?.squareFrom || ""}
                                    titleValueTwo={currentAppeal?.technicalRequirements?.squareTo || ""}
                                    suffics={' м²'}
                                    sufficsTwo={' м²'}
                                    icon={<ListIcon/>}
                                    inputClassNames={'input-extension-multi'}
                                />
                                <Field
                                    labelText={'Высота потолков'}
                                    isEdit={isEdit}
                                    type={'multi-input'}
                                    placeholder={'от'}
                                    placeholderTwo={'до'}
                                    value={currentAppeal?.technicalRequirements?.ceilingFrom || 0}
                                    valueTwo={currentAppeal?.technicalRequirements?.ceilingTo || 0}
                                    setValue={e => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, ceilingFrom: e}})}
                                    setValueTwo={e => changeItem({...currentAppeal, technicalRequirements: {...currentAppeal?.technicalRequirements, ceilingTo: e}})}
                                    titleValue={currentAppeal?.technicalRequirements?.ceilingFrom || ""}
                                    titleValueTwo={currentAppeal?.technicalRequirements?.ceilingTo || ""}
                                    suffics={' м'}
                                    sufficsTwo={' м'}
                                    icon={<ListIcon/>}
                                    inputClassNames={'input-extension-multi'}
                                />
                            </FieldBlock>
                        </>
                    : null
                }
            </DetailCardInformationBlock>
        </>
    );
};

export default AppealBuyDetailInformation;