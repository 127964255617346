import {createContext, useRef} from 'react';
import MessageHub from "../../components/messagehub/MessageHub";
export const MessageHubContext = createContext(null)

const MessageHubProvider = ({children}) => {
    const messageHubFunctionAdd = useRef(null)
    const add = (text, options) => {
        messageHubFunctionAdd.current(text)
    }
    return (
        <MessageHubContext.Provider value={{add: add,}}>
            {children}
            <MessageHub children={(add) => {
                messageHubFunctionAdd.current = add;
            }}/>
        </MessageHubContext.Provider>
    );
};

export default MessageHubProvider;