import {useState} from "react";

export function useMultiStepComponent(components) {

    const [currentIndex, setCurrentIndex] = useState(0)

    const back = () => {
        setCurrentIndex(prev => {
            if(prev <= 0) return prev;
            return prev - 1;
        })
    }
    const next = () => {
        setCurrentIndex(prev => {
            if(prev >= components.length - 1) return prev;
            return prev + 1;
        })
    }
    const goTO = (index) => {
        setCurrentIndex(index)
    }

    return {
        step: components[currentIndex].component,
        isLastItem: (components.length - 1) === currentIndex,
        isFirstItem: currentIndex === 0,
        titleStep: components[currentIndex].titleStep,
        currentIndex,
        back,
        next,
        goTO
    }
}