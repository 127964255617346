import React from 'react';
import './TableBody.css'
import {animated} from "@react-spring/web";

const TableBody = ({children, classNames = '', ...props}) => {
    return (
        <animated.div className={`table__body ${classNames && classNames}`} {...props}>
            {children}
        </animated.div>
    );
};

export default TableBody;