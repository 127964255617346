import {useEffect, useState} from 'react';
import './SelectDefault.css'
import {animated, useSpring, config} from "@react-spring/web";
const SelectDefault = ({selectedValue, setSelectedValue, options = [], placeholder, isCamelCase = false, classNames}) => {

    const [optionsArray, setOptionsArray] = useState([])
    useEffect(() => {
        if(Array.isArray(options)) {
            if (isCamelCase && !options?.find(x => x?.id === 99999)) {
                setOptionsArray([{
                    accessor: 'default',
                    name: 'Не выбрано',
                    value: 'Не выбрано',
                    id: 99999,
                }, ...options])
            }
            else if(!isCamelCase && !options?.find(x => x?.Id === 99999)) {
                setOptionsArray([{
                    Accessor: 'default',
                    name: 'Не выбрано',
                    Value: 'Не выбрано',
                    Id: 99999,
                }, ...options])
            }
        }
    }, [options])

    const titleAccessor = isCamelCase ? 'value' : 'Value'
    const idAccessor = isCamelCase ? 'id' : 'Id'
    const [show, setShow] = useState(false)
    const [stylesSelects, apiSelect] = useSpring(() => ({
        y: '-10%',
        opacity: 0,
        config: config.wobbly

    }))
    const titleValue = () => {
        let valueInOptions = options?.find(x => selectedValue && selectedValue?.[idAccessor] && x?.[idAccessor] === selectedValue?.[idAccessor] )
        if(valueInOptions) {
            return valueInOptions?.[titleAccessor]
        }
        else {
            return placeholder ? placeholder : 'Не выбрано'
        }
    }
    const setShowAnim = (e, fastHide) => {
        if(fastHide){
            setShow(e)
        }
        if(e){
            apiSelect.start(() => ({
                to: async (next, cansel) => {
                    await next({
                        y: '0',
                        opacity: 1,
                    })
                    await next({
                        y: '0',
                        opacity: 1,
                    })
                },
                onStart: () => {
                    setShow(e)
                }
            }))

        }
        else {
            apiSelect.start(() => ({
                to: async (next, cansel) => {
                    await next({
                        y: '-20%',
                        opacity: 0,
                        config: { duration: 200 },
                    })
                    await next({
                        config: config.wobbly
                    })
                },
                onRest: () => {
                    setShow(e)
                }
            }))
        }
    }
    return (
        <div onMouseLeave={() => setShowAnim(false)}  style={show ? {borderBottom: 'none', border: '2px solid #00a3ff'} : {}} className={`default-select__container ${classNames}`}>
            <div onClick={() => setShowAnim(!show)} className={'default-label__container'}>
                <label className="default-label__title">{titleValue()}</label>
                <div style={show ? {transform: 'rotate(180deg)'} : {}} className={'default-icon__container'}>
                    <i className="fa-solid fa-chevron-down fa-xs default-label__icon"></i>
                </div>
            </div>
            {show &&
                <animated.div
                    className={'default-selects'}
                    style={show ? {borderBottom: '2px solid #00a3ff', borderRight: '2px solid #00a3ff', borderLeft: '2px solid #00a3ff', ...stylesSelects} : {stylesSelects}}
                >
                    {optionsArray.map(opt =>
                        <div
                            key={opt[idAccessor]}
                            onClick={() => {
                                setShowAnim(false)
                                if(opt?.id === 99999)
                                    setSelectedValue(null)
                                else
                                    setSelectedValue(opt)
                            }}
                            className={'default-option'}
                        >
                            {opt[titleAccessor]}
                        </div>
                    )}
                </animated.div>
            }
        </div>
    );
};

export default SelectDefault;