import {useContext, useEffect, useRef, useState} from 'react';
import './Login.css'
import DefaultButton from "../../../components/UI/buttons/button/DefaultButton";
import DefaultInputSuffics from "../../../components/UI/inputs/tkpdefaultinputsuffics/DefaultInputSuffics";
import {UserContext} from "../../../features/authprovider/AuthProvider";
import CastleIcon from "../../../components/UI/icons/CastleIcon";
import EmailIcon from "../../../components/UI/icons/EmailIcon";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";

const Login = () => {
    const context = useContext(UserContext)
    const navigate = useNavigate()
    const location = useLocation()
    const [loginInfo, setLoginInfo] = useState({
        email: '',
        password: ''
    })
    const loginInfoRef = useRef({
        email: '',
        password: ''
    })
    const [isBadCredentials, setIsBadCredentials] = useState(false)
    useEffect(() => {
        document.addEventListener('keyup', keyDownHandler)

        return () => {
            document.removeEventListener('keyup', keyDownHandler)
        }
    }, [])
    useEffect(() => {
        loginInfoRef.current.email = loginInfo.email;
        loginInfoRef.current.password = loginInfo.password;
    }, [loginInfo])
    const loginCallback = res => {
        if(res?.status === 400)
            setIsBadCredentials(true)
        else if(res?.email){
            if(location?.pathname?.toLowerCase()?.includes('login'))
                navigate('/')
        }
    }
    const keyDownHandler = e => {
        setIsBadCredentials(false)
        if(e.key === 'Enter')
            context?.login(loginInfoRef.current, loginCallback)
    }


    return (
        <div className={'login__wrapper'}>
            <div className="login__sidebar">
                <h1 className={'login__sidebar_title'}>EVA-ОНЛАЙН</h1>
                <h3 className={'login__sidebar_subtitle'}>Управление бизнес-процессами</h3>
            </div>
            <div className={'login__container'} style={isBadCredentials ? {border: '1px solid var(--color-red)'} : null}>
                <div className={'login__header'}>
                    <img src="https://io.tsrealty.ru/photo/img/logo.png" className={'login__img'} alt=""/>
                    <div className={'login__tabs'}>
                        <div className="login__tab login__tab_active">Войти</div>
                        <div className="login__tab">Запрос на регистрацию</div>
                    </div>
                </div>
                <div className={'login__inputs'}>
                    <div className="login__input">
                        <EmailIcon/>
                        <DefaultInputSuffics
                            suffics={''}
                            placeholderDefault={'Логин'}
                            value={loginInfo?.email}
                            setValue={e => setLoginInfo({...loginInfo, email: e})}
                            classNames={`input-extension-login ${isBadCredentials ? 'input-extension-incorrect' : ''}`}
                        />
                    </div>
                    <div className="login__input">
                        <CastleIcon/>
                        <DefaultInputSuffics
                            suffics={''}
                            placeholderDefault={'Пароль'}
                            value={loginInfo?.password}
                            setValue={e => setLoginInfo({...loginInfo, password: e})}
                            classNames={`input-extension-login ${isBadCredentials ? 'input-extension-incorrect' : ''}`}
                            type={'password'}
                        />
                    </div>
                    <span className={'login__recovery'}>Забыли пароль?</span>
                    { isBadCredentials && <h5 className={'login__info'} style={{color: 'var(--color-red)', textAlign: 'left'}}>Неверный логин или пароль</h5>}

                </div>
                <div className={'login__btns'}>
                    <DefaultButton
                        title={'Авторизоваться'}
                        onClick={() => context?.login(loginInfo, loginCallback)}
                        classNames={'button-extension-login'}

                    />
                </div>
            </div>
        </div>
    );
};

export default Login;