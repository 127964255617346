import Main from "../pages/main/Main";
import ClientList from "../pages/clients/modules/list/ClientList";
import ClientDetail from "../pages/clients/modules/detail/ClientDetail";
import UserList from "../pages/guids/users/modules/list/UserList";
import UserDetailCard from "../pages/guids/users/modules/detail/UserDetailCard";
import AppealsRentList from "../pages/appeals/modules/rent/list/AppealsRentList";
import AppealsRentDetail from "../pages/appeals/modules/rent/detail/AppealsRentDetail";
import StatusGroupList from "../pages/guids/statuses/modules/list/StatusGroupList";
import AppealsBuyList from "../pages/appeals/modules/buy/list/AppealsBuyList";
import AppealsBuyDetail from "../pages/appeals/modules/buy/detail/AppealsBuyDetail";
import ProjectRentDetail from "../pages/projects/modules/projectsrent/detail/ProjectRentDetail";
import ProjectSellDetail from "../pages/projects/modules/projectssell/detail/ProjectSellDetail";
import BaseLayout from "../shared/baselayout/BaseLayout";
import Test from "../pages/testpage/Test";
import Sales from "../pages/sales/Sales";
import Profile from "../pages/profile/Profile";
import FieldList from "../pages/guids/fieldsnew/modules/list/FieldList";
import ProjectSellList from "../pages/projects/modules/projectssell/list/ProjectSellList";
import ProjectRentList from "../pages/projects/modules/projectsrent/list/ProjectRentList";
import ProjectList from "../pages/projects/modules/projects/list/ProjectList";
import ProjectDetail from "../pages/projects/modules/projects/detail/ProjectDetail";

export const routes = [
    {
        path: '',
        element: <BaseLayout/>,
        children: [
            {
                path: '',
                element: <Main />
            },
            {
                path: '/profile',
                element: <Profile />
            },
            {
                path: '/sales',
                element: <Sales />
            },
            {
                path: 'sales/kp',
                element: <Test />
            },
            {
                path: 'sales/clients',
                element: <ClientList />
            },
            {
                path: 'sales/clients/detail/:id',
                element: <ClientDetail />,
            },
            {
                path: 'sales/appeals/rent',
                element: <AppealsRentList />
            },
            {
                path: 'sales/appeals/buy',
                element: <AppealsBuyList />
            },
            {
                path: 'sales/appeals/rent/detail/:id',
                element: <AppealsRentDetail />,
            },
            {
                path: 'sales/appeals/buy/detail/:id',
                element: <AppealsBuyDetail />,
            },
            {
                path: 'sales/documents',
                element: <Test />
            },
            {
                path: 'projects',
                element: <ProjectList />
            },
            {
                path: 'projects/detail/:id',
                element: <ProjectDetail />
            },
            {
                path: 'projects/detail/:id/rent/detail/:idVariation',
                element: <ProjectRentDetail />
            },
            {
                path: 'projects/detail/:id/sell/detail/:idVariation',
                element: <ProjectSellDetail />
            },
            {
                path: '/admins',
                element: <Test />
            },
            {
                path: 'admins/users',
                element: <UserList />
            },
            {
                path: 'admins/users/detail/:id',
                element: <UserDetailCard />
            },
            {
                path: 'admins/fields',
                element: <FieldList />
            },
            {
                path: 'admins/statuses',
                element: <StatusGroupList />
            },
            {
                path: 'users/detail/:id',
                element: <UserDetailCard />
            },
            {
                path: "*",
                element: <Test/>
            }
        ]
    },
]
