import React, {createContext, useEffect, useState} from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {loginroutes} from "../../router/loginrouter";
import axios from "axios";
import {BaseUrl} from "../databaseinfo/BaseUrl";
import useLoader from "../../hooks/useLoader";
import useMessageHub from "../../hooks/useMessageHub";
export const UserContext = createContext(null)

const loginrouter = createBrowserRouter(loginroutes)
const AuthProvider = ({children}) => {
    const {show, hide} = useLoader()
    const addMessage = useMessageHub()
    const [isLogin, setIsLogin] = useState(false)
    const [userInfo, setUserInfo] = useState(null)

    useEffect(() => {
        let uInfoStr = localStorage.getItem('auth')
        if(uInfoStr){
            let uInfo = JSON.parse(uInfoStr)
            setUserInfo(uInfo)
            checkAuth({
                userName: uInfo?.userName,
                accessToken: uInfo?.accessToken,
                refreshToken: uInfo?.refreshToken,
            })
        }
    }, [])

    useEffect(() => {
        if(userInfo?.accessToken && !isLogin)
            setIsLogin(true)


    }, [userInfo]);

    const logout = () => {
        localStorage.removeItem('auth')
        setUserInfo(null)
    }
    const sendAuthRequest = (loginInfo, callback) => {
        show()
        axios.post(`${BaseUrl}/accounts/login/`, JSON.stringify(loginInfo), {
            headers: {
                "Content-Type": 'application/json'
            }
        })
            .then(res => {
                setUserInfo(res?.data)
                localStorage.setItem("auth", JSON.stringify(res?.data))
                if(callback)
                    callback?.(res?.data)
            })
            .catch(res => {
                if(callback)
                    callback?.(res?.response)
                addMessage(`Ошибка: ${res}`)
                setUserInfo(null)
                localStorage.removeItem("auth")
                hide()
            })
            .finally(res => {
                hide()
            })
    }
    const checkAuth = (checkModel, callback) => {
        show()
        axios.post(`${BaseUrl}/accounts/check`, JSON.stringify(checkModel), {
            headers: {
                "Content-Type": 'application/json'
            }
        })
            .then(res => {
                setUserInfo(res?.data)
                localStorage.setItem("auth", JSON.stringify(res?.data))
                if(callback)
                    callback?.(res?.data)
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
                setUserInfo(null)
                localStorage.removeItem("auth")
                hide()
            })
            .finally(() => {
                hide()
            })
    }
    const refreshToken = (tokenModel, callback) => {
        show()
        axios.post(`${BaseUrl}/accounts/refresh-token/`, JSON.stringify(tokenModel), {
            headers: {
                "Content-Type": 'application/json'
            }
        })
            .then(res => {
                setUserInfo(prev => {
                    return {
                        ...prev,
                        accessToken: res?.data?.accessToken,
                        refreshToken: res?.data?.refreshToken
                    }
                })
                localStorage.setItem("auth", JSON.stringify({
                    userInfo,
                    accessToken: res?.data?.accessToken,
                    refreshToken: res?.data?.refreshToken
                }))
                if(callback)
                    callback?.(res?.data)
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
                setUserInfo(null)
                localStorage.removeItem("auth")
                hide()
            })
            .finally(() => {
                hide()
            })
    }
    return (
        <UserContext.Provider value={{userInfo: userInfo, login: sendAuthRequest, logout: logout, checkAuth: checkAuth, refreshToken: refreshToken}}>
            {isLogin && userInfo?.accessToken
                ? children
                : <RouterProvider router={loginrouter} />
            }
        </UserContext.Provider>
    );
};

export default AuthProvider;