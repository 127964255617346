export const converterEntityToOptionOrReverse = ({endpoint, arrayEntities, accessorName, valueCallback, titleCallback, idCallback, searchPropertyForArray}) => {
    if(!searchPropertyForArray)
        return null;
    let findEntity = arrayEntities?.find(x => x?.id === searchPropertyForArray)
    if(findEntity){
        if(endpoint === 'option'){
            return {
                accessor: accessorName,
                name: titleCallback?.(findEntity),
                value: valueCallback?.(findEntity),
                id: idCallback?.(findEntity),
            }
        }
        else if(endpoint === 'entity') return findEntity;
        else return null
    }
}
export const getOptionsFromArrayEntities = ({entities, accessorName, valueCallback, titleCallback, idCallback}) => {
    let arr = []
    entities?.forEach(item => {
        arr?.push({
            accessor: accessorName,
            name: titleCallback?.(item),
            value: valueCallback?.(item),
            id: idCallback ? idCallback?.(item) : arr.length + 1,
        })
    })
    return arr;
}