import {useState} from 'react';
import './ClientDetail.css'
import useLoader from "../../../../hooks/useLoader";
import useMessageHub from "../../../../hooks/useMessageHub";
import useCheckConnectionToHub from "../../../../hooks/useCheckConnectionToHub";
import {BaseUrl} from "../../../../features/databaseinfo/BaseUrl";
import DefaultModal from "../../../../components/UI/modals/DefaultModal";
import {useNavigate, useParams} from "react-router";
import {createSignalRContext} from "react-signalr/signalr";
import CompaniesList from "./components/companieslist/CompaniesList";
import ContactsList from "./components/contactslist/ContactsList";
import useChangeItemSignalR from "../../../../hooks/useChangeItemSignalR";
import UserSelectorList from "../../../../components/selectoruser/UserSelectorList";
import useUserInfo from "../../../../hooks/useUserInfo";
import useCheckAccess from "../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../components/access/AccessDenied";
import ClientInformation from "./components/information/ClientInformation";
import {useMultiStepComponent} from "../../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import ButtonTab from "../../../../components/UI/buttons/buttontab/ButtonTab";
import CrossIcon from "../../../../components/UI/icons/CrossIcon";
import RequisitesIcon from "../../../../components/UI/icons/RequisitesIcon";
import PaperStroke from "../../../../components/UI/icons/PaperStroke";
import ContactIcon from "../../../../components/UI/icons/ContactIcon";
import InvoiceIcon from "../../../../components/UI/icons/InvoiceIcon";
import CompanyIcon from "../../../../components/UI/icons/CompanyIcon";
import Test from "../../../testpage/Test";
import TabsBlock from "../../../../components/tabsblock/TabsBlock";
import useSignalRFunk from "../../../../hooks/useSignalRFunk";
import DetailBody from "../../../../components/detailcard/body/DetailBody";
import DetailWrapper from "../../../../components/detailcard/wrapper/DetailWrapper";
const SignalRContext = createSignalRContext()
const ClientDetail = () => {
    const [showModalContacts, setShowModalContacts] = useState(false)
    const [showModalCompanies, setShowModalCompanies] = useState(false)
    const [showSelectorUsers, setShowSelectorUsers] = useState(false)
    const [showModalDev, setShowModalDev] = useState(false)
    const [currentClient, setCurrentClient] = useState(null)
    const [typeActivityOptions, setTypeActivityOptions] = useState([])
    const [typeClientOptions, setTypeClientOptions] = useState([])
    const [categoryClientOptions, setCategoryClientOptions] = useState([])
    const [businessTypesOptions, setBusinessTypesOptions] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const {hideAll} = useLoader()
    const params = useParams()
    const navigate = useNavigate()
    const {userInfo} = useUserInfo()

    const {
        connectionRef,
        checkConnection,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateCurrentClient()
            updateAllUsers()
            updateEnums("ClientType", res => {
                setTypeClientOptions(res)
            });
            updateEnums("CategoryClient", res => {
                setCategoryClientOptions(res)
            });
            updateEnums("TypeActivity", res => {
                setTypeActivityOptions(res)
            });
            updateEnums("BusinessTypes", res => {
                setBusinessTypesOptions(res)
            });
        },
        onReconnectCallback: () => {
            updateCurrentClient()
            updateAllUsers()
            updateEnums("ClientType", res => {
                setTypeClientOptions(res)
            });
            updateEnums("CategoryClient", res => {
                setCategoryClientOptions(res)
            });
            updateEnums("TypeActivity", res => {
                setTypeActivityOptions(res)
            });
        },
    })
    const {signalRFunk} = useSignalRFunk({
        SignalRContext,
        checkConnection
    })
    const updateCurrentClient = () => signalRFunk('GetClientForId', [Number(params['id'] || 0)], res => {
        setCurrentClient(res)
    })
    const updateEnums = (accessor, callback) => signalRFunk('GetAllEnumsByAccessor', [accessor], callback)
    const updateAllUsers = () => signalRFunk('GetAllUsers', [], res => {
        setAllUsers(res)
    })

    const {changeItem} = useChangeItemSignalR({
        SignalRContext: SignalRContext,
        checkConnection,
        delay: 1000,
        method: 'ChangeClient',
        callbackChangeItemFunk: newItem => setCurrentClient(newItem)
    })

    const {
        titleStep,
        step,
        goTO
    } = useMultiStepComponent([
        {
            component: <ClientInformation
                            currentClient={currentClient}
                            changeItem={changeItem}
                            categoryClientOptions={categoryClientOptions}
                            typeClientOptions={typeClientOptions}
                            typeActivityOptions={typeActivityOptions}
                            businessTypesOptions={businessTypesOptions}
                            setShowSelectorUsers={setShowSelectorUsers}
                        />,
            titleStep: 'Информация'
        },
        {
            component: <CompaniesList
                connectionRef={connectionRef}
                currentClient={currentClient}
                setCurrentClient={setCurrentClient}
                SignalRContext={SignalRContext}
            />,
            titleStep: 'Компании'
        },
        {
            component: <ContactsList
                            connectionRef={connectionRef}
                            setCurrentClient={setCurrentClient}
                            currentClient={currentClient}
                            SignalRContext={SignalRContext}
                        />,
            titleStep: 'Контакты'
        },
        {
            component: <Test/>,
            titleStep: 'Обращения'
        },
        {
            component: <Test/>,
            titleStep: 'Сделки'
        },
        {
            component: <Test/>,
            titleStep: 'Счета'
        },
        {
            component: <Test/>,
            titleStep: 'КП'
        },
    ])

    const access = useCheckAccess("client/detail")

    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/api/crm/clienthubdetail/?token=${userInfo?.accessToken || ''}`}
            connectEnabled={true}
            withCredentials={false}
            accessTokenFactory={() => userInfo?.accessToken || ''}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <DetailWrapper>
                <TabsBlock>
                    <ButtonTab
                        icon={<RequisitesIcon classNames={'icon-extension'}/>}
                        title={'Информация'}
                        onClick={() => goTO(0)}
                        isActive={titleStep === 'Информация'}
                    />
                    <ButtonTab
                        icon={<CompanyIcon classNames={'icon-extension'}/>}
                        title={'Компании'}
                        onClick={() => goTO(1)}
                        isActive={titleStep === 'Компании'}
                    />
                    <ButtonTab
                        icon={<ContactIcon classNames={'icon-extension'}/>}
                        title={'Контакты'}
                        onClick={() => goTO(2)}
                        isActive={titleStep === 'Контакты'}
                    />
                    <ButtonTab
                        icon={<InvoiceIcon classNames={'icon-extension'}/>}
                        title={'Обращения'}
                        onClick={() => goTO(3)}
                        isActive={titleStep === 'Обращения'}
                    />
                    <ButtonTab
                        icon={<InvoiceIcon classNames={'icon-extension'}/>}
                        title={'Сделки'}
                        onClick={() => goTO(4)}
                        isActive={titleStep === 'Сделки'}
                    />
                    <ButtonTab
                        icon={<InvoiceIcon classNames={'icon-extension'}/>}
                        title={'Счета'}
                        onClick={() => goTO(5)}
                        isActive={titleStep === 'Счета'}
                    />
                    <ButtonTab
                        icon={<PaperStroke classNames={'icon-extension'}/>}
                        title={'КП'}
                        onClick={() => goTO(6)}
                        isActive={titleStep === 'КП'}
                    />
                    <ButtonTab
                        title={'Назад к списку'}
                        onClick={() => navigate(-1)}

                    />
                    <ButtonTab
                        icon={<CrossIcon classNames={'icon-extension'}/>}
                        title={'Удалить клиента'}
                        classNames={'button-extension_delete'}
                        color={'red'}
                        onClick={() => {}}
                    />
                </TabsBlock>
                <DetailBody>
                    {step}
                </DetailBody>
                {showSelectorUsers &&
                    <DefaultModal visible={showSelectorUsers} setVisible={e => setShowSelectorUsers(e)}>
                        <UserSelectorList
                            users={allUsers}
                            cancel={() => setShowSelectorUsers(false)}
                            currentUser={currentClient?.assignedForClients}
                            setSelectedUser={newUser => changeItem({...currentClient, assignedForClients: newUser}, false)}
                        />
                    </DefaultModal>
                }
                {showModalCompanies &&
                    <DefaultModal visible={showModalCompanies} setVisible={e => setShowModalCompanies(e)}>
                        <CompaniesList currentClient={currentClient} SignalRContext={SignalRContext} setCurrentClient={setCurrentClient} connectionRef={connectionRef}/>
                    </DefaultModal>
                }
                {showModalContacts &&
                    <DefaultModal visible={showModalContacts} setVisible={e => setShowModalContacts(e)}>
                        <ContactsList connectionRef={connectionRef} setCurrentClient={setCurrentClient} currentClient={currentClient} SignalRContext={SignalRContext}/>
                    </DefaultModal>
                }
                {showModalDev &&
                    <DefaultModal visible={showModalDev} setVisible={e => setShowModalDev(e)}>
                        <div className={'dev'}>
                            <h1>В разработке...</h1>
                        </div>
                    </DefaultModal>
                }
            </DetailWrapper>
        </SignalRContext.Provider>
    );
};

export default ClientDetail;