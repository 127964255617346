import React from 'react';
import './FieldItemList.css'
import useLoader from "../../../../../hooks/useLoader";
import useMessageHub from "../../../../../hooks/useMessageHub";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
import DefaultTable from "../../../../../components/defaulttable/DefaultTable";
import DefaultButton from "../../../../../components/UI/buttons/button/DefaultButton";
import useGetComponentsForFieldItemList from "../../features/useGetComponentsForFieldItemList";
const VALUE_SIZE_ITEM = '1fr 2fr 0.4fr'
const FieldItemList = ({SignalRContext, connectionRef, fieldInItemList, setNewField}) => {
    const {show, hide} = useLoader()
    const addMessage = useMessageHub()

    const addFieldItem = () => {
        show()
        SignalRContext.invoke('CreateNewFieldItem', fieldInItemList?.id)
            .then(res => {
                setNewField({...fieldInItemList, items: [...fieldInItemList?.items, res]})
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const deleteFieldItem = (guid) => {
        show()
        SignalRContext.invoke('DeleteFieldItem', guid, fieldInItemList?.id)
            .then(res => {
                setNewField({...fieldInItemList, items: fieldInItemList?.items?.filter(x => x?.guid !== guid)})
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const {changeItem} = useChangeItemSignalR({
        SignalRContext: SignalRContext,
        connectionRef: connectionRef,
        delay: 1000,
        method: 'ChangeFieldItem',
        argsExternal: [fieldInItemList?.id],
        callbackChangeItemFunk: newItem => {
            setNewField({...fieldInItemList, items: fieldInItemList?.items?.map(item => {
                if(item?.guid === newItem?.guid) return newItem;
                else return item;
            })})
        }
    })
    const {arrayToReturn} = useGetComponentsForFieldItemList({
        removeItem: deleteFieldItem,
        changeItem: changeItem
    })
    return (
        <div className={'field-item-table__wrapper'}>
            <div className={'field-item-table__container'}>
                <DefaultTable
                    items={fieldInItemList?.items || []}
                    selectKeys={item => item?.guid}
                    VALUE_SIZES={VALUE_SIZE_ITEM}
                    components={arrayToReturn}
                />
            </div>
            <div className={'field-item-table__btns'}>
                <DefaultButton
                    title={'Добавить item'}
                    background={'blue'}
                    classNames={'button-extension'}
                    onClick={() => addFieldItem()}
                />
            </div>
        </div>
    );
};

export default FieldItemList;