import {useState} from 'react';
import './ContactsList.css'
import useLoader from "../../../../../../hooks/useLoader";
import useMessageHub from "../../../../../../hooks/useMessageHub";
import useChangeItemSignalR from "../../../../../../hooks/useChangeItemSignalR";
import DefaultTable from "../../../../../../components/defaulttable/DefaultTable";
import DefaultButton from "../../../../../../components/UI/buttons/button/DefaultButton";
import DefaultModal from "../../../../../../components/UI/modals/DefaultModal";
import DefaultInputSuffics from "../../../../../../components/UI/inputs/tkpdefaultinputsuffics/DefaultInputSuffics";
import useGetComponentsForContactsClient from "./useGetComponentsForContactsClient";
const VALUE_SIZE_ITEM = '1.5fr 2fr 2.5fr 1fr 0.4fr'

const ContactsList = ({currentClient, setCurrentClient, SignalRContext, connectionRef}) => {
    const {show, hide} = useLoader()
    const addMessage = useMessageHub()
    const [showModalCheckPhone, setShowModalCheckPhone] = useState(false)
    const [phoneToCheck, setPhoneToCheck] = useState(0)

    const addContact = () => {
        show()
        SignalRContext.invoke('CreateNewContact', phoneToCheck, currentClient?.id)
            .then(res => {
                if(!currentClient?.contactsInfo?.some(x => x?.guid === res?.guid))
                    setCurrentClient(prev => {
                        return {
                            ...prev,
                            contactsInfo: [...prev?.contactsInfo, res]
                        }
                    })
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
                setShowModalCheckPhone(false)
                setPhoneToCheck(0)
            })
    }
    const deleteContactFromClient = (guid) => {
        show()
        SignalRContext.invoke('DeleteContactFromClient', guid, currentClient?.id)
            .then(res => {
                setCurrentClient(prev => {
                    return {
                        ...prev,
                        contactsInfo: prev?.contactsInfo?.filter(x => x?.guid !== guid)
                    }
                })
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const {changeItem} = useChangeItemSignalR({
        SignalRContext: SignalRContext,
        connectionRef: connectionRef,
        delay: 1000,
        method: 'ChangeContactForClient',
        argsExternal: [currentClient?.id],
        callbackChangeItemFunk: newItem => {
            setCurrentClient(prev => {
                return {
                    ...prev,
                    contactsInfo: prev?.contactsInfo?.map(item => {
                        if(item?.guid === newItem?.guid) return newItem
                        else return item;
                    })
                }
            })
        }
    })
    const {arrayToReturn} = useGetComponentsForContactsClient({
        removeItem: deleteContactFromClient,
        changeItem: changeItem
    })
    return (
        <div className={'contacts-table__wrapper'}>
            <div className={'contacts-table__container'}>
                <DefaultTable
                    items={currentClient?.contactsInfo || []}
                    selectKeys={item => item?.guid}
                    VALUE_SIZES={VALUE_SIZE_ITEM}
                    components={arrayToReturn}
                />
            </div>
            <div className={'contacts-table__btns'}>
                <DefaultButton
                    title={'Добавить контактные данные'}
                    background={'blue'}
                    classNames={'button-extension'}
                    onClick={() => setShowModalCheckPhone(true)}
                />
            </div>
            {showModalCheckPhone &&
                <DefaultModal visible={showModalCheckPhone} setVisible={e => setShowModalCheckPhone(e)}>
                    <div className={'check-modal-phone__wrapper'}>
                        <h2 className={'check-modal-phone__title'}>Введите номер телефона</h2>
                        <DefaultInputSuffics
                            value={phoneToCheck}
                            placeholderDefault={'Номер'}
                            setValue={e => setPhoneToCheck(e)}
                            classNames={'input-extension'}
                        />
                        <DefaultButton
                            title={'Создать'}
                            background={'blue'}
                            classNames={'button-extension'}
                            onClick={() => addContact()}
                        />
                    </div>
                </DefaultModal>
            }
        </div>
    );
};

export default ContactsList;