import {useState} from 'react';
import './StatusGroupList.css'
import useLoader from "../../../../../hooks/useLoader";
import useMessageHub from "../../../../../hooks/useMessageHub";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import DefaultButton from "../../../../../components/UI/buttons/button/DefaultButton";
import DefaultTable from "../../../../../components/defaulttable/DefaultTable";
import DefaultModal from "../../../../../components/UI/modals/DefaultModal";
import {createSignalRContext} from "react-signalr/signalr";
import StatusInfoList from "../../components/statusinfolist/StatusInfoList";
import useGetComponentsForStatuseGroupList from "../../features/useGetComponentsForStatuseGroupList";

const SignalRContext = createSignalRContext()
const VALUE_SIZE_ITEM = '1fr 1fr 0.4fr 0.5fr'
const StatusGroupList = () => {
    const [showStatusItemList, setShowStatusItemList] = useState(false)
    const [statusInItemList, setStatusInItemList] = useState(null)
    const [items, setItems] = useState([])
    const {show, hide} = useLoader()
    const addMessage = useMessageHub()
    const {
        connectionRef,
        onError,
        onConnect,
        onReconnect
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateAllStatuses()
        },
        onReconnectCallback: () => {
            updateAllStatuses()
        }
    })

    const updateAllStatuses = () => {
        show()
        SignalRContext.invoke('GetAllStatusGroups')
            .then(res => {
                if(res && Array.isArray(res))
                    setItems(res)
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const addNewStatus = () => {
        SignalRContext.invoke('CreateNewStatusGroup')
            .then(res => {
                setItems(prev => [...prev, res])
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const removeStatus = (guid) => {
        SignalRContext.invoke('DeleteStatusGroup', guid)
            .then(() => {
                setItems(prev => prev.filter(x => x?.guid !== guid))
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const {changeItem} = useChangeItemSignalR({
        method: 'ChangeStatusGroup',
        SignalRContext: SignalRContext,
        connectionRef: connectionRef,
        delay: 1000,
        callbackChangeItemFunk: newItem => setItems(prev => prev.map(item => {
            if(item?.guid === newItem?.guid) return newItem;
            else return item;
        }))
    })

    const {arrayToReturn} = useGetComponentsForStatuseGroupList({
        changeItem: changeItem,
        removeItem: removeStatus,
        openListItems: field => {
            setShowStatusItemList(true)
            setStatusInItemList(field);
        }

    })
    return (
        <SignalRContext.Provider
            connectEnabled={true}
            withCredentials={false}
            url={`${BaseUrl}/api/crm/statusgrouphublist/`}
            onOpen={onConnect}
            onReconnect={onReconnect}
            onError={onError}
        >
            <div className={'statuses-list__wrapper'}>
                <div className={'statuses-list__header'}>
                    <DefaultButton
                        title={'Добавить status'}
                        background={'blue'}
                        classNames={'button-extension'}
                        onClick={addNewStatus}
                    />
                </div>
                <div className={'statuses-list__items'}>
                    <DefaultTable
                        items={items}
                        components={arrayToReturn}
                        selectKeys={item => item?.guid}
                        VALUE_SIZES={VALUE_SIZE_ITEM}
                    />
                </div>
            </div>
            {showStatusItemList &&
                <DefaultModal visible={showStatusItemList} setVisible={e => {setShowStatusItemList(e); setStatusInItemList(null)}}>
                    <StatusInfoList
                        statusInItemList={statusInItemList}
                        SignalRContext={SignalRContext}
                        connectionRef={connectionRef}
                        setNewStatusGroup={newStatusGroup => {
                            setItems(prev => prev.map(item => {
                                if(item?.guid === newStatusGroup?.guid) return newStatusGroup;
                                else return item;
                            }))
                            setStatusInItemList(newStatusGroup)
                        }}
                    />
                </DefaultModal>
            }
        </SignalRContext.Provider>
    );
};

export default StatusGroupList;