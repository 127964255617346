import React, {useEffect, useState} from 'react';

const UseDebounce = ({value, delay, callback}) => {
    const [endpointValue, setEndpointValue] = useState(value)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setEndpointValue(value)
            if(callback)
                callback()
        }, delay || 500)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [value, delay])

    return endpointValue;
};

export default UseDebounce;