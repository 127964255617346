import './ProjectRentInformation.css'
import {useState} from "react";
import TitleDetailCard from "../../../../../../../components/UI/titledetailcard/TitleDetailCard";
import DetailCardInformationBlock
    from "../../../../../../../components/detailcard/information/DetailCardInformationBlock";
import FieldBlock from "../../../../../../../components/fieldblock/FieldBlock";
import Field from "../../../../../../../components/UI/field/Field";
import ListIcon from "../../../../../../../components/UI/icons/ListIcon";
import ClockIcon from "../../../../../../../components/UI/icons/ClockIcon";
import {
    converterEntityToOptionOrReverse,
    getOptionsFromArrayEntities
} from "../../../../../../../features/entityhelper/EntityHelper";
import CalendarIcon from "../../../../../../../components/UI/icons/CalendarIcon";
const ProjectRentInformation = ({
                                    changeItem,
                                    currentProject,
                                    setShowSelectorUsers,
                                    typeRealtyTypeOptions,
                                    typeCommunicationOptions,
                                    typeFloorObjectOptions,
                                    typeEntranceOptions,
                                    sendFilePhoto,
                                    typeHeatingsOptions,
                                    typeIntendedPurposeOptions,
                                    typeObjectStatusOptions,
                                    typeParkingAvailabilityOptions,
                                    typeDivisionPossibleOptions,
                                    typeExclusiveContractOptions,
                                    dataIsLoadedRef,
                                    stages,
                                    typeUnloadingAvalabilityOptions,
                                    typeSeparateEntranceOptions,
                                    typeHolidaysRequirementOptions,
                                    typeIndexOptions,
                                }) => {
    const [isEdit, setIsEdit] = useState(false)
    return (
        <>
            <TitleDetailCard text={'Изменить данные вариации'}/>
            <DetailCardInformationBlock>
                <FieldBlock title={'Общая информация'}>
                    <Field
                        isEdit={isEdit}
                        icon={<ListIcon/>}
                        labelText={'Наименование'}
                        type={'input'}
                        value={currentProject?.name || ''}
                        titleValue={currentProject?.name || ''}
                        placeholder={'Наименование'}
                        setValue={e => changeItem({...currentProject, name: e})}
                    />
                    <Field
                        labelText={'Стадия'}
                        isEdit={isEdit}
                        type={'selector'}
                        placeholder={'Стадия'}
                        options={getOptionsFromArrayEntities({
                            entities: stages || [],
                            accessorName: 'stages',
                            valueCallback: item => item?.statusName,
                            titleCallback: item => item?.statusName,
                            idCallback: item => item?.id
                        })}
                        value={converterEntityToOptionOrReverse({
                            endpoint: 'option',
                            arrayEntities: stages,
                            accessorName: 'stages',
                            valueCallback: item => item?.statusName,
                            titleCallback: item => item?.statusName,
                            idCallback: item => item?.id,
                            searchPropertyForArray: currentProject?.stage?.id
                        })}
                        setValue={e => changeItem({...currentProject, stage: converterEntityToOptionOrReverse({
                                endpoint: 'entity',
                                arrayEntities: stages,
                                accessorName: 'stages',
                                valueCallback: item => item?.statusName,
                                titleCallback: item => item?.statusName,
                                idCallback: item => item?.id,
                                searchPropertyForArray: e?.id
                            })}, false)}
                        titleValue={currentProject?.stage?.statusName || ""}
                        icon={<ListIcon/>}
                    />
                    <Field
                        icon={<ClockIcon/>}
                        type={'label'}
                        value={currentProject?.lastActivity || "В разработке"}
                        labelText={'Дата крайнего взаимодействия'}
                    />
                    <Field
                        type={'label'}
                        value={currentProject?.assignedForVariation?.fio || ""}
                        labelText={'Ответственный'}
                    />
                    {dataIsLoadedRef
                        ?
                        <>
                            <FieldBlock title={'Маркетинг'}>
                                <Field
                                    icon={<ClockIcon/>}
                                    type={'label'}
                                    value={currentProject?.lastActivity || "В разработке"}
                                    labelText={'Преимущества'}
                                />
                            </FieldBlock>
                            <FieldBlock title={'Документы'}>
                                <Field
                                    isEdit={isEdit}
                                    icon={<ListIcon/>}
                                    labelText={'Кадастровый номер'}
                                    type={'input'}
                                    value={currentProject?.cadastralNumber || ''}
                                    titleValue={currentProject?.cadastralNumber || ''}
                                    placeholder={'Кадастровый номер'}
                                    setValue={e => changeItem({...currentProject, cadastralNumber: e})}
                                />
                                <Field
                                    isEdit={isEdit}
                                    icon={<CalendarIcon/>}
                                    labelText={'Дата заключения договора'}
                                    type={'date'}
                                    value={currentProject?.document?.dateAgencyAgreement || ''}
                                    setValue={newDate => changeItem({...currentProject, document: {...currentProject?.document, dateAgencyAgreement: newDate}})}
                                />
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Агентский договор'}
                                    type={'file'}
                                    value={currentProject?.document?.additionalDocument?.name}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, document: {...currentProject?.document, additionalDocument: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Коммунальный договор'}
                                    type={'file'}
                                    value={currentProject?.document?.additionalDocument?.name}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, document: {...currentProject?.document, additionalDocument: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Выписка из ЕГРН'}
                                    type={'file'}
                                    value={currentProject?.document?.additionalDocument?.name}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, document: {...currentProject?.document, additionalDocument: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Технический или кадастровый паспорт'}
                                    type={'file'}
                                    value={currentProject?.document?.additionalDocument?.name}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, document: {...currentProject?.document, additionalDocument: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                            </FieldBlock>
                            <FieldBlock title={'Прочее'}>
                                <Field
                                    type={'checkbox'}
                                    isEdit={isEdit}
                                    icon={<ListIcon/>}
                                    labelText={'Автовыгрузка на Авито'}
                                    value={currentProject?.additional?.autoUnloadAvito || false}
                                    setValue={() => changeItem({...currentProject, additional: {...currentProject?.additional, autoUnloadAvito: !currentProject.additional.autoUnloadAvito}}, false)}
                                />

                            </FieldBlock>
                        </>
                        : null
                    }
                    {dataIsLoadedRef ?
                        <Field
                            type={'button'}
                            setValue={() => setShowSelectorUsers(true)}
                            labelText={'Сменить ответственного'}
                            background={'blue'}
                            classNames={'button-extension'}
                        />
                        : null
                    }

                    <Field
                        type={'button'}
                        setValue={() => setIsEdit(!isEdit)}
                        labelText={isEdit ? "Сохранить" : 'Редактировать'}
                        background={isEdit ? "red" : 'blue'}
                        classNames={'button-extension'}
                    />
                </FieldBlock>
                {dataIsLoadedRef
                    ?
                    <>
                        <FieldBlock title={'Технические показатели'}>
                            <Field
                                labelText={'Тип недвижимости'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Тип'}
                                options={typeRealtyTypeOptions}
                                value={currentProject?.technicalSpecifications?.realtyType}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, realtyType: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.realtyType?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Коммуникации'}
                                isEdit={isEdit}
                                type={'multi-selector'}
                                placeholder={'Выбрать'}
                                options={typeCommunicationOptions}
                                value={currentProject?.technicalSpecifications?.communication || []}
                                setValue={newArray => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, communication: newArray}}, true, [], false)}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Этажи'}
                                isEdit={isEdit}
                                type={'multi-selector'}
                                placeholder={'Выбрать'}
                                options={typeFloorObjectOptions}
                                value={currentProject?.technicalSpecifications?.floorObject || []}
                                setValue={newArray => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, floorObject: newArray}}, true, [], false)}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Вход'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Вход'}
                                options={typeEntranceOptions}
                                value={currentProject?.technicalSpecifications?.entrance}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, entrance: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.entrance?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Отдельный вход'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Вход'}
                                options={typeSeparateEntranceOptions}
                                value={currentProject?.technicalSpecifications?.separateEntrance}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, separateEntrance: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.separateEntrance?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Разгрузка'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Разгрузка'}
                                options={typeUnloadingAvalabilityOptions}
                                value={currentProject?.technicalSpecifications?.unloadingAvailability}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, unloadingAvailability: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.unloadingAvailability?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Парковка'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Парковка'}
                                options={typeParkingAvailabilityOptions}
                                value={currentProject?.technicalSpecifications?.parkingAvailability}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, parkingAvailability: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.parkingAvailability?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Состояние объекта'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Состояние'}
                                options={typeObjectStatusOptions}
                                value={currentProject?.technicalSpecifications?.objectStatus}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, objectStatus: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.objectStatus?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Отопление'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Отопление'}
                                options={typeHeatingsOptions}
                                value={currentProject?.technicalSpecifications?.heating}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, heating: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.heating?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Возможность деления'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Деление'}
                                options={typeDivisionPossibleOptions}
                                value={currentProject?.technicalSpecifications?.divisionPossible}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, divisionPossible: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.divisionPossible?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Мощность электросети'}
                                isEdit={isEdit}
                                type={'input'}
                                placeholder={' кВт'}
                                value={currentProject?.technicalSpecifications?.powerElectricity || 0}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, powerElectricity: e}})}
                                titleValue={currentProject?.technicalSpecifications?.powerElectricity || ""}
                                suffics={' кВт'}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Площадь'}
                                isEdit={isEdit}
                                type={'input'}
                                placeholder={'м²'}
                                value={currentProject?.technicalSpecifications?.square || 0}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, square: e}})}
                                titleValue={currentProject?.technicalSpecifications?.square || ""}
                                suffics={' м²'}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Высота потолков'}
                                isEdit={isEdit}
                                type={'input'}
                                placeholder={'м'}
                                value={currentProject?.technicalSpecifications?.ceilingHeight || 0}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, ceilingHeight: e}})}
                                titleValue={currentProject?.technicalSpecifications?.ceilingHeight || ""}
                                suffics={' м'}
                                icon={<ListIcon/>}
                            />
                            <FieldBlock title={'Коммерческие показатели'}>
                                <Field
                                    labelText={'Назначения объекта'}
                                    isEdit={isEdit}
                                    type={'multi-selector'}
                                    placeholder={'Выбрать'}
                                    options={typeIntendedPurposeOptions}
                                    value={currentProject?.commercialSpecification?.intendedPurpose || []}
                                    setValue={newArray => changeItem({...currentProject, commercialSpecification: {...currentProject?.commercialSpecification, intendedPurpose: newArray}}, true, [], false)}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Арендные каникулы'}
                                    isEdit={isEdit}
                                    type={'selector'}
                                    placeholder={'Каникулы'}
                                    options={typeHolidaysRequirementOptions}
                                    value={currentProject?.commercialSpecification?.rentalHolidays}
                                    setValue={e => changeItem({...currentProject, commercialSpecification: {...currentProject?.commercialSpecification, rentalHolidays: e}}, false)}
                                    titleValue={currentProject?.commercialSpecification?.rentalHolidays?.value || ""}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Эксклюзивный договор'}
                                    isEdit={isEdit}
                                    type={'selector'}
                                    placeholder={'Эксклюзив'}
                                    options={typeExclusiveContractOptions}
                                    value={currentProject?.commercialSpecification?.exclusiveContract}
                                    setValue={e => changeItem({...currentProject, commercialSpecification: {...currentProject?.commercialSpecification, exclusiveContract: e}}, false)}
                                    titleValue={currentProject?.commercialSpecification?.exclusiveContract?.value || ""}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Индексация'}
                                    isEdit={isEdit}
                                    type={'selector'}
                                    placeholder={'Индексация'}
                                    options={typeIndexOptions}
                                    value={currentProject?.commercialSpecification?.indexType}
                                    setValue={e => changeItem({...currentProject, commercialSpecification: {...currentProject?.commercialSpecification, indexType: e}}, false)}
                                    titleValue={currentProject?.commercialSpecification?.indexType?.value || ""}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Кто оплачивает коммунальные платежи'}
                                    isEdit={isEdit}
                                    type={'selector'}
                                    placeholder={'Выбрать'}
                                    options={typeIndexOptions}
                                    value={currentProject?.commercialSpecification?.whoPaysUtilities}
                                    setValue={e => changeItem({...currentProject, commercialSpecification: {...currentProject?.commercialSpecification, whoPaysUtilities: e}}, false)}
                                    titleValue={currentProject?.commercialSpecification?.whoPaysUtilities?.value || ""}
                                    icon={<ListIcon/>}
                                />
                                <Field
                                    labelText={'Арендная плата'}
                                    isEdit={isEdit}
                                    type={'input'}
                                    placeholder={'₽'}
                                    value={currentProject?.commercialSpecification?.rentalPrice || 0}
                                    setValue={e => changeItem({...currentProject, commercialSpecification: {...currentProject?.commercialSpecification, rentalPrice: e}})}
                                    titleValue={currentProject?.commercialSpecification?.rentalPrice || ""}
                                    suffics={' ₽'}
                                    icon={<ListIcon/>}
                                    isMoney={true}
                                />
                                <Field
                                    labelText={'Комиссия'}
                                    isEdit={isEdit}
                                    type={'input'}
                                    placeholder={'₽'}
                                    value={currentProject?.commercialSpecification?.commission || 0}
                                    setValue={e => changeItem({...currentProject, commercialSpecification: {...currentProject?.commercialSpecification, commission: e}})}
                                    titleValue={currentProject?.commercialSpecification?.commission || ""}
                                    suffics={' ₽'}
                                    icon={<ListIcon/>}
                                    isMoney={true}
                                />
                                <Field
                                    labelText={'Цена за квадратный метр'}
                                    isEdit={isEdit}
                                    type={'input'}
                                    placeholder={'₽'}
                                    value={currentProject?.commercialSpecification?.priceForSquareMeter || 0}
                                    setValue={e => changeItem({...currentProject, commercialSpecification: {...currentProject?.commercialSpecification, priceForSquareMeter: e}})}
                                    titleValue={currentProject?.commercialSpecification?.priceForSquareMeter || ""}
                                    suffics={' ₽'}
                                    icon={<ListIcon/>}
                                    isMoney={true}
                                />
                                <Field
                                    labelText={'Процент индексации'}
                                    isEdit={isEdit}
                                    type={'input'}
                                    placeholder={'%'}
                                    value={currentProject?.commercialSpecification?.percentIndex || 0}
                                    setValue={e => changeItem({...currentProject, commercialSpecification: {...currentProject?.commercialSpecification, percentIndex: e}})}
                                    titleValue={currentProject?.commercialSpecification?.percentIndex || ""}
                                    suffics={'%'}
                                    icon={<ListIcon/>}
                                />
                            </FieldBlock>
                            <FieldBlock title={'Фото'}>
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Титульное фото'}
                                    type={'photo'}
                                    value={currentProject?.photo?.titulPhoto?.name}
                                    srcImage={currentProject?.photo?.titulPhoto?.urlPath}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, photo: {...currentProject?.photo, titulPhoto: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Фото входной группы'}
                                    type={'photo'}
                                    value={currentProject?.photo?.titulPhoto?.name}
                                    srcImage={currentProject?.photo?.titulPhoto?.urlPath}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, photo: {...currentProject?.photo, titulPhoto: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Фото разгрузки'}
                                    type={'photo'}
                                    value={currentProject?.photo?.titulPhoto?.name}
                                    srcImage={currentProject?.photo?.titulPhoto?.urlPath}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, photo: {...currentProject?.photo, titulPhoto: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Фото локации с карты'}
                                    type={'photo'}
                                    value={currentProject?.photo?.titulPhoto?.name}
                                    srcImage={currentProject?.photo?.titulPhoto?.urlPath}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, photo: {...currentProject?.photo, titulPhoto: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Фото планировок'}
                                    type={'photo'}
                                    value={currentProject?.photo?.titulPhoto?.name}
                                    srcImage={currentProject?.photo?.titulPhoto?.urlPath}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, photo: {...currentProject?.photo, titulPhoto: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Внешние фото'}
                                    type={'photo'}
                                    value={currentProject?.photo?.titulPhoto?.name}
                                    srcImage={currentProject?.photo?.titulPhoto?.urlPath}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, photo: {...currentProject?.photo, titulPhoto: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Внутренние фото'}
                                    type={'photo'}
                                    value={currentProject?.photo?.titulPhoto?.name}
                                    srcImage={currentProject?.photo?.titulPhoto?.urlPath}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, photo: {...currentProject?.photo, titulPhoto: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Остальные фото'}
                                    type={'photo'}
                                    value={currentProject?.photo?.titulPhoto?.name}
                                    srcImage={currentProject?.photo?.titulPhoto?.urlPath}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, photo: {...currentProject?.photo, titulPhoto: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                            </FieldBlock>
                        </FieldBlock>
                    </>
                    : null
                }
            </DetailCardInformationBlock>
        </>
    );
};

export default ProjectRentInformation;