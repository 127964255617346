

export const StylesObjects = {
    displayFlexCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    texTkpTableHeader: {
        fontSize: '13px',
        color: '#6F767E',
        fontWeight: 600,
        textAlign: 'center'
    },
    paddingSliceLimitingContainer: {
        padding: '5px',
        width: 'calc(100% - 10px)',
        height: 'calc(70% - 10px)'
    }
}



