import './DefaultSidebar.css'
import {NavLink, useLocation, useMatch} from "react-router-dom";
import {useEffect, useState} from "react";
import useUserInfo from "../../hooks/useUserInfo";
import DefaultButton from "../UI/buttons/button/DefaultButton";
import {useNavigate} from "react-router";
const DefaultSidebar = ({setIsOpenMenu}) => {
    const [isOpen, setIsOpen] = useState(true)
    useEffect(() => {
        setIsOpenMenu(isOpen)
    }, [isOpen])
    const {isAdmin, userInfo} = useUserInfo()
    const navigate = useNavigate()
    const [items, setItems] = useState(menuItems)
    const location = useLocation()
    const match = useMatch(location?.pathname)
    const checkPermission = (itemIsAdmin) => {
        if(itemIsAdmin && isAdmin)
            return true
        else if(itemIsAdmin && !isAdmin)
            return false
        else return true
    }
    return (
        <nav className={`default-sidebar__wrapper ${isOpen ? '' : 'hide__menu'}`}>
            <div className={'gradient'}></div>
            <div className={`default-sidebar__body ${isOpen ? '' : 'hide__body'}`}>
                <div className={`default-sidebar__header ${isOpen ? '' : 'hide__header'}`}>
                    {isOpen && <img className={'default-sidebar__logo'} src="https://io.tsrealty.ru/photo/img/logo-footer.png" alt=""/>}
                    <div className={`logout__container ${isOpen ? '' : 'hide__header'}`} onClick={() => setIsOpen(!isOpen)}>
                        <i className={`fa-solid fa-arrow-right-from-bracket fa-sm logout-icon ${isOpen ? '' : 'logout-icon-rotate'}`}></i>
                    </div>
                </div>
                <div className={`default-sidebar__menu ${isOpen ? '' : 'hide-menu'}`}>
                    {items?.map(item => (
                        checkPermission(item?.isAdmin) &&
                            <div key={item?.key} className={`default-sidebar__item_container ${isOpen ? '' : 'hide__item'}`}>
                                <NavLink
                                    to={item?.path}
                                    className={({ isActive, isPending, isTransitioning}) => [
                                        isPending ? 'default-sidebar__item_pending' : '',
                                        isActive ? 'default-sidebar__item_active' : '',
                                        isTransitioning ? 'default-sidebar__transitioning' : ''
                                    ].join(" ") + `default-sidebar__item ${isOpen ? '' : 'hide__item'}`}
                                >
                                    <div className={'default-sidebar__color'} style={match?.pathname?.toLowerCase()?.includes(item?.path?.toLowerCase()) ? {background: item?.color, '--color-neon': item?.color} : {background: item?.color, '--color-neon': ''}}></div>
                                    {isOpen && <div className={'default-sidebar__text'}>
                                        {item?.title}
                                    </div>}
                                </NavLink>
                                {isOpen && match?.pathname?.toLowerCase()?.includes(item?.path?.toLowerCase()) && item?.subitems?.map(subitem => (
                                    <NavLink
                                        key={subitem?.key}
                                        to={subitem?.path}
                                        className={({ isActive, isPending, isTransitioning}) => [
                                            isPending ? 'default-sidebar__item_pending' : '',
                                            isActive ? 'default-sidebar__item_active' : '',
                                            isTransitioning ? 'default-sidebar__transitioning' : ''
                                        ].join(" ") + `default-sidebar__subitem`}
                                    >
                                        <div className={'default-sidebar__color'} style={match?.pathname?.toLowerCase()?.includes(subitem?.path?.toLowerCase()) ? {background: '#3E90F0', '--color-neon': '#3E90F0'} : {background: subitem?.color, '--color-neon': subitem?.color}}></div>
                                        <div className={'default-sidebar__text'}>
                                            {subitem?.title}
                                        </div>
                                        <div className={'default-sidebar__crumbs'}>
                                            0
                                        </div>
                                    </NavLink>
                                ))}
                            </div>

                    ))}
                </div>
            </div>
            <div className={`default-sidebar__footer ${isOpen ? '' : 'hide__footer'}`}>
                {isOpen && <div className={`user__profile ${isOpen ? '' : 'hide__profile'}`}>
                    <div className={'user__block'}>
                        <div className={'user__avatar'}>
                            <img className={'user-img__avatar_sidebar'} src={userInfo?.avatarPath} onError={e => {
                                e.target.src = 'https://static.tildacdn.com/tild3238-3834-4566-b939-643034303766/no-profile.png'
                            }} alt=""/>
                        </div>
                        <div className={'user__info'}>
                            <div className={'user__name'}>
                                {`${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`}
                            </div>
                            <div className={'user_email'}>
                                {userInfo?.email || ''}
                            </div>
                        </div>
                        <div className={'user__other'}>
                            <div className={'user__role'}>
                                {userInfo?.role || ''}
                            </div>
                        </div>
                    </div>
                    <DefaultButton
                        title={'Редактировать профиль'}
                        onClick={() => navigate(`users/detail/${userInfo?.id}`)}
                        classNames={'button__profile'}
                    />
                </div>}
            </div>
        </nav>
    );
};

export default DefaultSidebar;

const menuItems = [
    {
        key: 1,
        isAdmin: false,
        name: 'Главная',
        title: 'Главная',
        path: '/',
        color: '#9BBF2D',
        subitems: []
    },
    {
        key: 2,
        isAdmin: false,
        name: 'Профиль компании',
        title: 'Профиль компании',
        path: 'profile',
        color: '#3E90F0',
        subitems: []
    },
    {
        key: 3,
        isAdmin: false,
        name: 'Управление продажами',
        title: 'Управление продажами',
        path: 'sales',
        color: '#DDA73F',
        subitems: [
            {
                key: 4,
                isAdmin: false,
                name: 'Клиенты',
                title: 'Клиенты',
                path: 'sales/clients',
                color: '#6C727580',
            },
            {
                key: 17,
                isAdmin: false,
                name: 'Обращения аренды',
                title: 'Обращения аренды',
                path: 'sales/appeals/rent',
                color: '#6C727580',
            },
            {
                key: 18,
                isAdmin: false,
                name: 'Обращения покупки',
                title: 'Обращения покупки',
                path: 'sales/appeals/buy',
                color: '#6C727580',
            },
            {
                key: 19,
                isAdmin: false,
                name: 'Сделки по аренде',
                title: 'Сделки по аренде',
                path: 'sales/deals/rent',
                color: '#6C727580',
            },
            {
                key: 20,
                isAdmin: false,
                name: 'Сделки по попкупке',
                title: 'Сделки по попкупке',
                path: 'sales/deals/sell',
                color: '#6C727580',
            },
            {
                key: 6,
                isAdmin: false,
                name: 'КП',
                title: 'КП',
                path: 'sales/kp',
                color: '#6C727580',
            },
            {
                key: 7,
                isAdmin: false,
                name: 'Документы',
                title: 'Документы',
                path: 'sales/documents',
                color: '#6C727580',
            },
        ]
    },
    {
        key: 8,
        isAdmin: false,
        name: 'Управление объектами',
        title: 'Управление объектами',
        path: 'projects',
        color: '#D84C10',
        subitems: [
            {
                key: 15,
                isAdmin: false,
                name: 'Проекты',
                title: 'Проекты',
                path: 'projects',
                color: '#6C727580',
            },
        ]
    },
    {
        key: 9,
        isAdmin: false,
        name: 'Тайм менеджмент',
        title: 'Тайм менеджмент',
        path: 'tasks',
        color: '#8E55EA',
        subitems: []
    },
    {
        key: 10,
        isAdmin: false,
        name: 'Мессенджер',
        title: 'Мессенджер',
        path: 'messenger',
        color: '#46DEFF',
        subitems: []
    },
    {
        key: 11,
        isAdmin: true,
        name: 'Администрирование',
        title: 'Администрирование',
        path: 'admins',
        color: 'white',
        subitems: [
            {
                key: 12,
                isAdmin: true,
                name: 'Пользователи',
                title: 'Пользователи',
                path: 'admins/users',
                color: '#6C727580',
            },
            {
                key: 13,
                isAdmin: true,
                name: 'Поля',
                title: 'Поля',
                path: 'admins/fields',
                color: '#6C727580',
            },
            {
                key: 14,
                isAdmin: true,
                name: 'Статусы',
                title: 'Статусы',
                path: 'admins/statuses',
                color: '#6C727580',
            }
        ]
    },
]