export const formatter = new Intl.NumberFormat('ru-RU' ,{
    style: 'currency',
    currency: 'RUB'
})
export const getRubString = (text) => {
    return formatter.format(text)
}
const warehouses = [
    { short: 'ярославл', full: 'Ярославль'},
    { short: 'ростов', full: 'Ростов-На-Дону'},
    { short: 'астрахан', full: 'Астрахань'}
]
export const getFullOutputWarehouseFromShortString = (shortString) => {
    let warehouse = warehouses.find(x => x.short.toLowerCase().includes(shortString?.toLowerCase()))
    return warehouse?.full || 'Нет данных'
}