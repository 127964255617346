import axios from "axios";
import {BaseUrl} from "../features/databaseinfo/BaseUrl";
import useMessageHub from "./useMessageHub";
import useLoader from "./useLoader";

const UseFileUploaderToBackend = () => {
    const addMessage = useMessageHub()
    const {show, hide} = useLoader()

    const sendFile = (newFile, uniqName = '', useLoader = false, successCallback, errorCallback, finallyCallback) => {
        let fd = new FormData();
        fd.append("file", newFile)
        if(useLoader)
            show()
        axios.post(`${BaseUrl}/ancorr/api/files/add?uniqName=${uniqName}`, fd)
            .then(({data}) => {
                if(successCallback)
                    successCallback?.(data)
            })
            .catch(res => {
                addMessage(`Ошибка: ${res.message}`)
                if(errorCallback)
                    errorCallback?.(res)
            })
            .finally(res => {
                if(useLoader)
                    hide()
                if(finallyCallback)
                    finallyCallback?.(res)
            }
        )
    }
    const sendFiles = (newFiles, uniqName = '', useLoader = false, successCallback, errorCallback, finallyCallback) => {
        let fd = new FormData();
        fd.append("listFiles", newFiles)

        axios.post(`${BaseUrl}/ancorr/api/files/addRange?uniqName=${uniqName}`, fd)
            .then(({data}) => {
                if(successCallback)
                    successCallback?.(data)
            })
            .catch(res => {
                addMessage(`Ошибка: ${res.message}`)
                if(errorCallback)
                    errorCallback?.(res)
            })
            .finally(res => {
                if(useLoader)
                    hide()
                if(finallyCallback)
                    finallyCallback?.(res)
            }
        )
    }
    return {
        sendFile,
        sendFiles
    };
};

export default UseFileUploaderToBackend;