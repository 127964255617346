
const CompanyIcon = ({classNames}) => {
    return (
        <svg className={classNames} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_123_40)">
                <path d="M4.00004 14.6667V2.66668C4.00004 2.31305 4.14052 1.97392 4.39057 1.72387C4.64061 1.47382 4.97975 1.33334 5.33337 1.33334H10.6667C11.0203 1.33334 11.3595 1.47382 11.6095 1.72387C11.8596 1.97392 12 2.31305 12 2.66668V14.6667M4.00004 14.6667H12M4.00004 14.6667H2.66671C2.31309 14.6667 1.97395 14.5262 1.7239 14.2762C1.47385 14.0261 1.33337 13.687 1.33337 13.3333V9.33334C1.33337 8.97972 1.47385 8.64058 1.7239 8.39053C1.97395 8.14049 2.31309 8.00001 2.66671 8.00001H4.00004M12 14.6667H13.3334C13.687 14.6667 14.0261 14.5262 14.2762 14.2762C14.5262 14.0261 14.6667 13.687 14.6667 13.3333V7.33334C14.6667 6.97972 14.5262 6.64058 14.2762 6.39053C14.0261 6.14049 13.687 6.00001 13.3334 6.00001H12M6.66671 4.00001H9.33337M6.66671 6.66668H9.33337M6.66671 9.33334H9.33337M6.66671 12H9.33337" stroke="#6C7275" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_123_40">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default CompanyIcon;