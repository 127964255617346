import {useState} from 'react';
import './AppealsBuyList.css'
import useLoader from "../../../../../hooks/useLoader";
import {useNavigate} from "react-router";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import DefaultButton from "../../../../../components/UI/buttons/button/DefaultButton";
import DefaultTable from "../../../../../components/defaulttable/DefaultTable";
import {createSignalRContext} from "react-signalr/signalr";
import useGetComponentsForAppealBuyList from "./features/useGetComponentsForAppealBuyList";
import useSignalRFunk from "../../../../../hooks/useSignalRFunk";
import useUserInfo from "../../../../../hooks/useUserInfo";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import TitleDetailCard from "../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";

const VALUE_SIZE_ITEM = '1fr 0.5fr 2fr 0.5fr 0.5fr 0.5fr'
const SignalRContext = createSignalRContext()
const AppealsBuyList = () => {
    const [searchValue, setSearchValue] = useState('')
    const [items, setItems] = useState([])
    const {hideAll} = useLoader()
    const navigate = useNavigate()
    const {userInfo} = useUserInfo()
    const {
        checkConnection,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateAllAppeals()
        },
        onReconnectCallback: () => {
            updateAllAppeals()
        }
    })
    const {signalRFunk} = useSignalRFunk({
        SignalRContext,
        checkConnection
    })
    const updateAllAppeals = () => signalRFunk('GetAllAppealBuyVariation', [], res => {
        if(res && Array.isArray(res))
            setItems(res)
    })

    const addNewAppeal = () => signalRFunk('AddNewAppealBuyVariation', [], res => {
        navigate(`detail/${res?.id}`)
    })
    const removeAppeal = (guid) => signalRFunk('RemoveAppealBuyVariation', [guid], () => {
        setItems(prev => prev.filter(x => x?.guid !== guid))
    })

    const { arrayToReturn} = useGetComponentsForAppealBuyList({
        removeItem: removeAppeal,
        detailCard: id => navigate(`detail/${id}`)
    })

    const access = useCheckAccess("client/detail")
    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/api/crm/appealbuyhublist/?token=${userInfo?.accessToken || ''}`}
            connectEnabled={true}
            withCredentials={false}
            accessTokenFactory={() => userInfo?.accessToken || ''}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <GuidsWrapper>
                <GuidsHeader>
                    <TitleDetailCard text={'СПИСОК ОБРАЩЕНИЙ ПОКУПКИ'}/>
                    <div className={'flex center gap'}>
                        <GuidsSearchInput searchValue={searchValue} setSearchValue={setSearchValue}/>
                        <DefaultButton
                            title={'Добавить обращение'}
                            background={'blue'}
                            classNames={'button-extension'}
                            onClick={addNewAppeal}
                        />
                    </div>
                </GuidsHeader>
                <GuidsBody>
                    <DefaultTable
                        items={items}
                        components={arrayToReturn}
                        selectKeys={item => item?.guid}
                        VALUE_SIZES={VALUE_SIZE_ITEM}
                    />
                </GuidsBody>
            </GuidsWrapper>
        </SignalRContext.Provider>
    );
};

export default AppealsBuyList;