import './DefaultInputFile.css'
const DefaultInputFile = ({currentFileName, onLoadFile}) => {
    return (
        <div className={'input-file__container'}>
            <label htmlFor="upload-photo" className={'input-file__label'}>
                <svg className={'input-file__icon'} width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" width="64" height="64" rx="32" fill="#FEFEFE"/>
                    <path d="M36.5 32L32.5 28M32.5 28L28.5 32M32.5 28V37.2C32.5 38.5907 32.5 39.2861 33.0505 40.0646C33.4163 40.5819 34.4694 41.2203 35.0972 41.3054C36.0421 41.4334 36.4009 41.2462 37.1186 40.8719C40.3167 39.2036 42.5 35.8568 42.5 32C42.5 26.4772 38.0228 22 32.5 22C26.9772 22 22.5 26.4772 22.5 32C22.5 35.7014 24.511 38.9331 27.5 40.6622" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <div className={'input-file__info'}>
                    <p className={'input-file__title'}>{currentFileName ? currentFileName : 'Загрузить файл'}</p>
                </div>
            </label>
            <input type="file" className={'hide-input-file'} name="avatar" id="upload-photo" onChange={e => onLoadFile(e.target.files[0])}/>
        </div>
    );
};

export default DefaultInputFile;