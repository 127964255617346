import React from "react";
import Login from "../pages/auth/login/Login";
import Register from "../pages/auth/register/Register";
import Recovery from "../pages/auth/recovery/Recovery";
export const loginroutes = [
    {
        path: 'auth/login',
        element: <Login />,
    },
    {
        path: 'auth/register',
        element: <Register />,
    },
    {
        path: 'auth/recovery',
        element: <Recovery />,
    },
    {
        path: '*',
        element: <Login />,
    },
]