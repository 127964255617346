import React, {useMemo, useState} from 'react';
import './UserSelectorList.css'
import DefaultButton from "../UI/buttons/button/DefaultButton";
import DefaultInputSuffics from "../UI/inputs/tkpdefaultinputsuffics/DefaultInputSuffics";
const UserSelectorList = ({users, cancel, setSelectedUser, currentUser}) => {
    const [searchText, setSearchText] = useState('')
    const memoUsersAndSorting = useMemo(() => {
        return users.filter(x => x?.fio?.toLowerCase()?.includes(searchText?.toLowerCase()))
    }, [users, searchText])
    return (
        <div className={'user-selector__wrapper'}>
            <div className={'user-selector__header'}>
                <DefaultInputSuffics
                    value={searchText}
                    setValue={e => setSearchText(e)}
                    placeholderDefault={'Поиск'}
                    classNames={'input-extension'}
                />
            </div>
            <div className={'user-selector__items'}>
                {memoUsersAndSorting?.map(item => (
                    <div key={item?.guid} className={'user-selector__item'} onClick={() => {setSelectedUser(item); cancel()}}>
                        {currentUser?.id === item?.id && <i className={`fa-solid fa-check default-option-checked`}></i>}
                        <span className={'user-selector__fio'}>{item?.fio}</span>
                        <span className={'user-selector__fio'}>|</span>
                        <span className={'user-selector__fio'}>{item?.role}</span>
                    </div>
                ))}
            </div>
            <div className={'user-selector__footer'}>
                <DefaultButton
                    title={'Закрыть'}
                    background={'red'}
                    onClick={() => cancel()}
                    classNames={'button-extension'}
                />
            </div>
        </div>
    );
};

export default UserSelectorList;