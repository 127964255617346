import React from 'react';
import './TableColumn.css'
const TableColumn = ({children, classNames = '', ...props}) => {
    return (
        <div className={`table__column ${classNames && classNames}`} {...props}>
            {children}
        </div>
    );
};

export default TableColumn;