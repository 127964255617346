import {useState} from 'react';
import './ClientList.css'
import useCheckConnectionToHub from "../../../../hooks/useCheckConnectionToHub";
import {BaseUrl} from "../../../../features/databaseinfo/BaseUrl";
import DefaultButton from "../../../../components/UI/buttons/button/DefaultButton";
import {createSignalRContext} from "react-signalr/signalr";
import useLoader from "../../../../hooks/useLoader";
import useGetComponentsForClientList from "./features/useGetComponentsForClientList";
import DefaultTable from "../../../../components/defaulttable/DefaultTable";
import {
    useNavigate,
} from "react-router";
import useCheckAccess from "../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../components/access/AccessDenied";
import TitleDetailCard from "../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsWrapper from "../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../components/guids/header/GuidsHeader";
import GuidsBody from "../../../../components/guids/body/GuidsBody";
import useSignalRFunk from "../../../../hooks/useSignalRFunk";
import useUserInfo from "../../../../hooks/useUserInfo";
import GuidsSearchInput from "../../../../components/guids/search/GuidsSearchInput";

const SignalRContext = createSignalRContext()
const VALUE_SIZE_ITEM = '0.5fr 1fr 1fr 1fr 0.6fr 1fr 1fr 0.4fr'

const ClientList = () => {
    const [searchValue, setSearchValue] = useState('')
    const [items, setItems] = useState([])
    const {hideAll} = useLoader()
    const navigate = useNavigate()
    const {userInfo} = useUserInfo()

    const {
        checkConnection,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateAllClients()
        },
        onReconnectCallback: () => {
            updateAllClients()
        },
    })
    const {signalRFunk} = useSignalRFunk({
        checkConnection,
        SignalRContext
    })
    const updateAllClients = () => signalRFunk('GetAllClients', [], res => {
        if(res && Array.isArray(res))
            setItems(res)
    })

    const addNewClient = () => signalRFunk('CreateNewClient', [], res => {
        navigate(`detail/${res?.id}`)
    })

    const removeClient = (guid) => signalRFunk('DeleteClient', [guid], () => {
        setItems(prev => prev.filter(x => x?.guid !== guid))
    })

    const { arrayToReturn} = useGetComponentsForClientList({
        removeItem: removeClient,
        detailCard: id => navigate(`detail/${id}`)
    })

    const access = useCheckAccess("client/detail")
    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/api/crm/clienthublist/?token=${userInfo?.accessToken || ''}`}
            connectEnabled={true}
            withCredentials={false}
            accessTokenFactory={() => userInfo?.accessToken || ''}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <GuidsWrapper>
                <GuidsHeader>
                    <TitleDetailCard text={'СПИСОК КЛИЕНТОВ'}/>
                    <div className={'flex center gap'}>
                        <GuidsSearchInput searchValue={searchValue} setSearchValue={setSearchValue}/>
                        <DefaultButton
                            title={'Добавить клиента'}
                            background={'blue'}
                            classNames={'button-extension'}
                            onClick={addNewClient}
                        />
                    </div>
                </GuidsHeader>
                <GuidsBody>
                    <DefaultTable
                        items={items}
                        components={arrayToReturn}
                        selectKeys={item => item?.guid}
                        VALUE_SIZES={VALUE_SIZE_ITEM}
                    />
                </GuidsBody>
            </GuidsWrapper>
        </SignalRContext.Provider>
    );
};

export default ClientList;