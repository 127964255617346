import React, {useRef, useState} from 'react';
import './AppealsRentDetail.css'
import useLoader from "../../../../../hooks/useLoader";
import {useNavigate, useParams} from "react-router";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import {createSignalRContext} from "react-signalr/signalr";
import DefaultModal from "../../../../../components/UI/modals/DefaultModal";
import UserSelectorList from "../../../../../components/selectoruser/UserSelectorList";
import TabsBlock from "../../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../../components/UI/buttons/buttontab/ButtonTab";
import RequisitesIcon from "../../../../../components/UI/icons/RequisitesIcon";
import InvoiceIcon from "../../../../../components/UI/icons/InvoiceIcon";
import CrossIcon from "../../../../../components/UI/icons/CrossIcon";
import DetailBody from "../../../../../components/detailcard/body/DetailBody";
import DetailWrapper from "../../../../../components/detailcard/wrapper/DetailWrapper";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import {useMultiStepComponent} from "../../../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import Test from "../../../../testpage/Test";
import useUserInfo from "../../../../../hooks/useUserInfo";
import useSignalRFunk from "../../../../../hooks/useSignalRFunk";
import AppealRentDetailInformation from "./components/information/AppealRentDetailInformation";

const SignalRContext = createSignalRContext()
const AppealsRentDetail = () => {
    const [showSelectorUsers, setShowSelectorUsers] = useState(false)
    const [currentAppeal, setCurrentAppeal] = useState(null)
    const dataIsLoadedRef = useRef(false)
    const [sources, setSources] = useState([])
    const [stages, setStages] = useState([])
    const [typeDistrictOptions, setTypeDistrictOptions] = useState([])
    const [typeLineOfPlacementOptions, setTypeLineOfPlacementOptions] = useState([])
    const [typeRealtyTypeOptions, setTypeRealtyTypeOptions] = useState([])
    const [typeCommunicationOptions, setTypeCommunicationOptions] = useState([])
    const [typeFloorObjectOptions, setTypeFloorObjectOptions] = useState([])
    const [typeEntranceOptions, setTypeEntranceOptions] = useState([])
    const [typeHeatingsOptions, setTypeHeatingsOptions] = useState([])
    const [typeIntendedPurposeOptions, setTypeIntendedPurposeptions] = useState([])
    const [typeObjectStatusOptions, setTypeObjectStatusOptions] = useState([])
    const [typeParkingAvailabilityOptions, setTypeParkingAvailabilityOptions] = useState([])
    const [typeSeparateEntranceOptions, setTypeSeparateEntranceOptions] = useState([])
    const [typeUnloadingAvalabilityOptions, setTypeUnloadingAvalabilityOptions] = useState([])
    const [typeNetworkAppealOptions, setTypeNetworkAppealOptions] = useState([])
    const [typeIndexOptions, setTypeIndexOptions] = useState([])
    const [typeHolidaysRequirementOptions, setTypeHolidaysRequirementOptions] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const {hideAll} = useLoader()
    const params = useParams()
    const navigate = useNavigate()
    const {userInfo} = useUserInfo()
    const {
        checkConnection,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateCurrentClient()
            updateAllUsers()
            updateEnums("Districts", res => {
                setTypeDistrictOptions(res)
            });
            updateEnums("LineOfPlacement", res => {
                setTypeLineOfPlacementOptions(res)
            });
            updateEnums("RealtyType", res => {
                setTypeRealtyTypeOptions(res)
            });
            updateEnums("Communication", res => {
                setTypeCommunicationOptions(res)
            });
            updateEnums("FloorObject", res => {
                setTypeFloorObjectOptions(res)
            });
            updateEnums("Entrance", res => {
                setTypeEntranceOptions(res)
            });
            updateEnums("Heatings", res => {
                setTypeHeatingsOptions(res)
            });
            updateEnums("IntendedPurpose", res => {
                setTypeIntendedPurposeptions(res)
            });
            updateEnums("ObjectStatus", res => {
                setTypeObjectStatusOptions(res)
            });
            updateEnums("ParkingAvailability", res => {
                setTypeParkingAvailabilityOptions(res)
            });
            updateEnums("SeparateEntrance", res => {
                setTypeSeparateEntranceOptions(res)
            });
            updateEnums("UnloadingAvalability", res => {
                setTypeUnloadingAvalabilityOptions(res)
            });
            updateEnums("NetworkAppeal", res => {
                setTypeNetworkAppealOptions(res)
            });
            updateEnums("Index", res => {
                setTypeIndexOptions(res)
            });
            updateEnums("HolidaysRequirement", res => {
                setTypeHolidaysRequirementOptions(res)
            });
            updateStatusesForTypeAndEntity("stage", "appealRent", res => {
                setStages(res)
            });
            updateStatusesForTypeAndEntity("source", "all", res => {
                setSources(res)
            });
        },
        onReconnectCallback: () => {
            updateCurrentClient()
            updateAllUsers()
            updateEnums("Districts", res => {
                setTypeDistrictOptions(res)
            });
            updateEnums("LineOfPlacement", res => {
                setTypeLineOfPlacementOptions(res)
            });
            updateEnums("RealtyType", res => {
                setTypeRealtyTypeOptions(res)
            });
            updateEnums("Communication", res => {
                setTypeCommunicationOptions(res)
            });
            updateEnums("FloorObject", res => {
                setTypeFloorObjectOptions(res)
            });
            updateEnums("Entrance", res => {
                setTypeEntranceOptions(res)
            });
            updateEnums("Heatings", res => {
                setTypeHeatingsOptions(res)
            });
            updateEnums("IntendedPurpose", res => {
                setTypeIntendedPurposeptions(res)
            });
            updateEnums("ObjectStatus", res => {
                setTypeObjectStatusOptions(res)
            });
            updateEnums("ParkingAvailability", res => {
                setTypeParkingAvailabilityOptions(res)
            });
            updateEnums("SeparateEntrance", res => {
                setTypeSeparateEntranceOptions(res)
            });
            updateEnums("UnloadingAvalability", res => {
                setTypeUnloadingAvalabilityOptions(res)
            });
            updateEnums("NetworkAppeal", res => {
                setTypeNetworkAppealOptions(res)
            });
            updateEnums("Index", res => {
                setTypeIndexOptions(res)
            });
            updateEnums("HolidaysRequirement", res => {
                setTypeHolidaysRequirementOptions(res)
            });
            updateStatusesForTypeAndEntity("stage", "appealRent", res => {
                setStages(res)
            });
            updateStatusesForTypeAndEntity("source", "all", res => {
                setSources(res)
            });
        }
    })
    const {signalRFunk} = useSignalRFunk({
        SignalRContext,
        checkConnection
    })
    const updateCurrentClient = () => signalRFunk('GetAppealForId', [Number(params['id'] || 0)], res => {
        setCurrentAppeal(res)
        dataIsLoadedRef.current = true
    })
    const updateEnums = (accessor, callback) => signalRFunk('GetAllEnumsByAccessor', [accessor], callback)
    const updateStatusesForTypeAndEntity = (type, destEntity, callback) => signalRFunk('GetAllStatusInfoByEntityAndType', [type, destEntity], callback)
    const updateAllUsers = () => signalRFunk('GetAllUsers', [], res => {
        setAllUsers(res)
    })

    const {changeItem} = useChangeItemSignalR({
        SignalRContext,
        checkConnection,
        delay: 1000,
        method: 'ChangeAppealRentVariation',
        callbackChangeItemFunk: newItem => setCurrentAppeal(newItem)
    })
    const checkIsOfficeManagerStage = () => (currentAppeal?.stage?.statusValue === 'new' || currentAppeal?.stage?.statusValue === 'officeManager');
    const isOficeManagerStage = checkIsOfficeManagerStage();
    const {
        titleStep,
        step,
        goTO
    } = useMultiStepComponent([
        {
            component: <AppealRentDetailInformation
                currentAppeal={currentAppeal}
                changeItem={changeItem}
                setShowSelectorUsers={setShowSelectorUsers}
                stages={stages}
                sources={sources}
                typeParkingAvailabilityOptions={typeParkingAvailabilityOptions}
                typeObjectStatusOptions={typeObjectStatusOptions}
                typeCommunicationOptions={typeCommunicationOptions}
                typeDistrictOptions={typeDistrictOptions}
                typeFloorObjectOptions={typeFloorObjectOptions}
                typeLineOfPlacementOptions={typeLineOfPlacementOptions}
                typeEntranceOptions={typeEntranceOptions}
                typeHeatingsOptions={typeHeatingsOptions}
                typeIntendedPurposeOptions={typeIntendedPurposeOptions}
                typeRealtyTypeOptions={typeRealtyTypeOptions}
                typeUnloadingAvalabilityOptions={typeUnloadingAvalabilityOptions}
                typeSeparateEntranceOptions={typeSeparateEntranceOptions}
                typeNetworkAppealOptions={typeNetworkAppealOptions}
                typeHolidaysRequirementOptions={typeHolidaysRequirementOptions}
                typeIndexOptions={typeIndexOptions}
                isOficeManagerStage={isOficeManagerStage}
                dataIsLoadedRef={dataIsLoadedRef.current}
            />,
            titleStep: 'Информация'
        },
        {
            component: <Test/>,
            titleStep: 'Подборка объектов'
        },
        {
            component: <Test/>,
            titleStep: 'Предложения'
        },
    ])

    const access = useCheckAccess("client/detail")

    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/api/crm/appealrenthubdetail/?token=${userInfo?.accessToken || ''}`}
            connectEnabled={true}
            withCredentials={false}
            accessTokenFactory={() => userInfo?.accessToken || ''}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <DetailWrapper>
                <TabsBlock>
                    <ButtonTab
                        icon={<RequisitesIcon classNames={'icon-extension'}/>}
                        title={'Информация'}
                        onClick={() => goTO(0)}
                        isActive={titleStep === 'Информация'}
                    />
                    <ButtonTab
                        icon={<InvoiceIcon classNames={'icon-extension'}/>}
                        title={'Подборка объектов'}
                        onClick={() => goTO(1)}
                        isActive={titleStep === 'Подборка объектов'}
                    />
                    <ButtonTab
                        icon={<InvoiceIcon classNames={'icon-extension'}/>}
                        title={'Предложения'}
                        onClick={() => goTO(2)}
                        isActive={titleStep === 'Предложения'}
                    />
                    <ButtonTab
                        title={'Назад к списку'}
                        onClick={() => navigate(-1)}

                    />
                    <ButtonTab
                        icon={<CrossIcon classNames={'icon-extension'}/>}
                        title={'Удалить обращение'}
                        classNames={'button-extension_delete'}
                        color={'red'}
                        onClick={() => {}}
                    />
                </TabsBlock>
                <DetailBody>
                    {step}
                </DetailBody>
            </DetailWrapper>
            {showSelectorUsers &&
                <DefaultModal visible={showSelectorUsers} setVisible={e => setShowSelectorUsers(e)}>
                    <UserSelectorList
                        users={allUsers}
                        cancel={() => setShowSelectorUsers(false)}
                        currentUser={currentAppeal?.assigned}
                        setSelectedUser={newUser => {
                            if(isOficeManagerStage)
                                changeItem({...currentAppeal, stage: stages?.find(x => x?.statusValue === 'transferToBroker'), assigned: newUser}, false)
                            else
                                changeItem({...currentAppeal, assigned: newUser}, false)
                        }}
                    />
                </DefaultModal>
            }
        </SignalRContext.Provider>
    );
};

export default AppealsRentDetail;