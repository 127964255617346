import './DefaultButton.css'
const  DefaultButton = ({id = '', title, onClick, classNames = '', style, color, background, ...props}) => {
    const colors = {
        green: '--color-green',
        blue: '--color-blue',
        red: '--color-red',
        lightRed: '--color-light-red',
        white: '--color-white',
        lightGray: '--color-light-gray',
        gray: '--color-gray',
        darkGray: '--color-dark-gray',
        black: '--color-black',
    }
    const handleColor = color ?
        {
            color: `var(${colors[color]})`
        } : null;
    const handleBackground = background ?
        {
            background: `var(${colors[background]})`
        } : null;

    return (
        <button id={id} style={{...style, ...handleColor, ...handleBackground}} className={`${classNames} default-button`} onClick={() => onClick?.()} {...props}>
            {title}
        </button>
    );
};

export default DefaultButton;