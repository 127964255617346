import './ProjectInformation.css'
import TitleDetailCard from "../../../../../../../components/UI/titledetailcard/TitleDetailCard";
import DetailCardInformationBlock
    from "../../../../../../../components/detailcard/information/DetailCardInformationBlock";
import FieldBlock from "../../../../../../../components/fieldblock/FieldBlock";
import Field from "../../../../../../../components/UI/field/Field";
import ListIcon from "../../../../../../../components/UI/icons/ListIcon";
import ClockIcon from "../../../../../../../components/UI/icons/ClockIcon";
import {useState} from "react";
const ProjectInformation = ({
                                changeItem,
                                currentProject,
                                setShowSelectorUsers,
                                typeRealtyTypeOptions,
                                typeCommunicationOptions,
                                typeFloorObjectOptions,
                                typeEntranceOptions,
                                sendFilePhoto,
                                typeLineOfPlacementOptions,
                                dataIsLoadedRef,
                                typeUnloadingAvalabilityOptions,
                                typeSeparateEntranceOptions,
                            }) => {
    const [isEdit, setIsEdit] = useState(false)
    return (
        <>
            <TitleDetailCard text={'Изменить данные проекта'}/>
            <DetailCardInformationBlock>
                <FieldBlock title={'Общая информация'}>
                    <Field
                        isEdit={isEdit}
                        icon={<ListIcon/>}
                        labelText={'Наименование'}
                        type={'input'}
                        value={currentProject?.name || ''}
                        titleValue={currentProject?.name || ''}
                        placeholder={'Наименование'}
                        setValue={e => changeItem({...currentProject, name: e})}
                    />
                    <Field
                        isEdit={isEdit}
                        icon={<ListIcon/>}
                        labelText={'Информация о обращении'}
                        type={'input'}
                        value={currentProject?.note || ''}
                        titleValue={currentProject?.note || ''}
                        placeholder={'Информация'}
                        setValue={e => changeItem({...currentProject, note: e})}
                    />
                    <Field
                        icon={<ListIcon/>}
                        type={'label'}
                        value={currentProject?.address || ""}
                        labelText={'Адресс'}
                    />
                    <Field
                        icon={<ClockIcon/>}
                        type={'label'}
                        value={currentProject?.lastActivity || "В разработке"}
                        labelText={'Дата крайнего взаимодействия'}
                    />
                    <Field
                        type={'label'}
                        value={currentProject?.assignedBtProject?.fio || ""}
                        labelText={'Ответственный'}
                    />
                    {dataIsLoadedRef
                        ?
                        <>
                            <FieldBlock title={'Локация'}>
                                <Field
                                    labelText={'Линия размещения'}
                                    isEdit={isEdit}
                                    type={'selector'}
                                    placeholder={'Линия'}
                                    options={typeLineOfPlacementOptions}
                                    value={currentProject?.location?.lineOfPlacement}
                                    setValue={e => changeItem({...currentProject, location: {...currentProject?.location, lineOfPlacement: e}}, false)}
                                    titleValue={currentProject?.location?.lineOfPlacement?.value || ""}
                                    icon={<ListIcon/>}
                                />
                            </FieldBlock>
                            <FieldBlock title={'Документы'}>
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Дополнительный документ'}
                                    type={'file'}
                                    value={currentProject?.document?.additionalDocument?.name}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, document: {...currentProject?.document, additionalDocument: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                            </FieldBlock>
                            <FieldBlock title={'Прочее'}>
                                <Field
                                    type={'checkbox'}
                                    isEdit={isEdit}
                                    icon={<ListIcon/>}
                                    labelText={'Автовыгрузка на Авито'}
                                    value={currentProject?.additional?.autoUnloadAvito || false}
                                    setValue={e => changeItem({...currentProject, additional: {...currentProject?.additional, autoUnloadAvito: !currentProject.additional.autoUnloadAvito}}, false)}
                                />
                            </FieldBlock>
                        </>
                        : null
                    }
                    {dataIsLoadedRef ?
                        <Field
                            type={'button'}
                            setValue={() => setShowSelectorUsers(true)}
                            labelText={'Сменить ответственного'}
                            background={'blue'}
                            classNames={'button-extension'}
                        />
                        : null
                    }

                    <Field
                        type={'button'}
                        setValue={() => setIsEdit(!isEdit)}
                        labelText={isEdit ? "Сохранить" : 'Редактировать'}
                        background={isEdit ? "red" : 'blue'}
                        classNames={'button-extension'}
                    />
                </FieldBlock>
                {dataIsLoadedRef
                    ?
                    <>
                        <FieldBlock title={'Технические показатели'}>
                            <Field
                                labelText={'Тип недвижимости'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Тип'}
                                options={typeRealtyTypeOptions}
                                value={currentProject?.technicalSpecifications?.realtyType}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, realtyType: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.realtyType?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Коммуникации'}
                                isEdit={isEdit}
                                type={'multi-selector'}
                                placeholder={'Выбрать'}
                                options={typeCommunicationOptions}
                                value={currentProject?.technicalSpecifications?.communication || []}
                                setValue={newArray => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, communication: newArray}}, true, [], false)}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Этажи'}
                                isEdit={isEdit}
                                type={'multi-selector'}
                                placeholder={'Выбрать'}
                                options={typeFloorObjectOptions}
                                value={currentProject?.technicalSpecifications?.floorObject || []}
                                setValue={newArray => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, floorObject: newArray}}, true, [], false)}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Вход'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Вход'}
                                options={typeEntranceOptions}
                                value={currentProject?.technicalSpecifications?.entrance}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, entrance: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.entrance?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Отдельный вход'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Вход'}
                                options={typeSeparateEntranceOptions}
                                value={currentProject?.technicalSpecifications?.separateEntrance}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, separateEntrance: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.separateEntrance?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Разгрузка'}
                                isEdit={isEdit}
                                type={'selector'}
                                placeholder={'Разгрузка'}
                                options={typeUnloadingAvalabilityOptions}
                                value={currentProject?.technicalSpecifications?.unloadingAvailability}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, unloadingAvailability: e}}, false)}
                                titleValue={currentProject?.technicalSpecifications?.unloadingAvailability?.value || ""}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Мощность электросети'}
                                isEdit={isEdit}
                                type={'input'}
                                placeholder={' кВт'}
                                value={currentProject?.technicalSpecifications?.powerElectricity || 0}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, powerElectricity: e}})}
                                titleValue={currentProject?.technicalSpecifications?.powerElectricity || ""}
                                suffics={' кВт'}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Площадь'}
                                isEdit={isEdit}
                                type={'input'}
                                placeholder={'м²'}
                                value={currentProject?.technicalSpecifications?.square || 0}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, square: e}})}
                                titleValue={currentProject?.technicalSpecifications?.square || ""}
                                suffics={' м²'}
                                icon={<ListIcon/>}
                            />
                            <Field
                                labelText={'Высота потолков'}
                                isEdit={isEdit}
                                type={'input'}
                                placeholder={'м'}
                                value={currentProject?.technicalSpecifications?.ceilingHeight || 0}
                                setValue={e => changeItem({...currentProject, technicalSpecifications: {...currentProject?.technicalSpecifications, ceilingHeight: e}})}
                                titleValue={currentProject?.technicalSpecifications?.ceilingHeight || ""}
                                suffics={' м'}
                                icon={<ListIcon/>}
                            />
                            <FieldBlock title={'Фото'}>
                                <Field
                                    isEdit={isEdit}
                                    labelText={'Титульное фото'}
                                    type={'photo'}
                                    value={currentProject?.photo?.titulPhoto?.name}
                                    srcImage={currentProject?.photo?.titulPhoto?.urlPath}
                                    setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentProject, photo: {...currentProject?.photo, titulPhoto: data}}, false))}
                                    fileClassNames={'titul_photo'}
                                />
                            </FieldBlock>
                        </FieldBlock>
                    </>
                    : null
                }
            </DetailCardInformationBlock>
        </>
    );
};

export default ProjectInformation;