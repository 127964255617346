import React from 'react';
import LimitingContainer from "../../../../components/containers/limitingcontainer/LimitingContainer";
import {StylesObjects} from "../../../../features/stylesobjects/StylesObjects";
import DefaultInputSuffics from "../../../../components/UI/inputs/tkpdefaultinputsuffics/DefaultInputSuffics";

const UseGetComponentsForFieldList = ({changeItem, removeItem, openListItems}) => {
    const arrayToReturn = [
        {
            Key: 1,
            TitleColumn: 'Название',
            invokeComponent: (item, callbackAction) => {


                return (
                    <LimitingContainer width={'100%'} height={'100%'} styles={{...StylesObjects.displayFlexCenter, ...StylesObjects.paddingSliceLimitingContainer}}>
                        <DefaultInputSuffics suffics={''} placeholderDefault={'Название'} value={item?.name} setValue={e => changeItem({...item, name: e})}/>
                    </LimitingContainer>
                )

            }
        },
        {
            Key: 2,
            TitleColumn: 'Accessor',
            invokeComponent: (item, callbackAction) => {


                return (
                    <LimitingContainer width={'100%'} height={'100%'} styles={{...StylesObjects.displayFlexCenter, ...StylesObjects.paddingSliceLimitingContainer}}>
                        <DefaultInputSuffics suffics={''} placeholderDefault={'Accessor'} value={item?.accessor} setValue={e => changeItem({...item, accessor: e})}/>
                    </LimitingContainer>
                )

            }
        },
        {
            Key: 3,
            TitleColumn: 'Сущность назначения',
            invokeComponent: (item, callbackAction) => {


                return (
                    <LimitingContainer width={'100%'} height={'100%'} styles={{...StylesObjects.displayFlexCenter, ...StylesObjects.paddingSliceLimitingContainer}}>
                        <DefaultInputSuffics suffics={''} placeholderDefault={'Назначение'} value={item?.destinationEntity} setValue={e => changeItem({...item, destinationEntity: e})}/>
                    </LimitingContainer>
                )

            }
        },
        {
            Key: 4,
            TitleColumn: 'Тип',
            invokeComponent: (item, callbackAction) => {


                return (
                    <LimitingContainer width={'100%'} height={'100%'} styles={{...StylesObjects.displayFlexCenter, ...StylesObjects.paddingSliceLimitingContainer}}>
                        <DefaultInputSuffics suffics={''} placeholderDefault={'Тип'} value={item?.type} setValue={e => changeItem({...item, type: e})}/>
                    </LimitingContainer>
                )

            }
        },
        {
            Key: 5,
            TitleColumn: 'Множественное',
            invokeComponent: (item, callbackAction) => {


                return (
                    <LimitingContainer width={'100%'} height={'100%'} styles={{...StylesObjects.displayFlexCenter, ...StylesObjects.paddingSliceLimitingContainer}}>
                        <input
                            type={'checkbox'}
                            checked={item?.isMultiple}
                            onChange={e => changeItem({...item, isMultiple: !item?.isMultiple}, false)}
                        />
                    </LimitingContainer>
                )

            }
        },
        {
            Key: 6,
            TitleColumn: 'Действия',
            invokeComponent: (item, callbackAction) => {



                return (
                    <LimitingContainer width={'100%'} height={'100%'} styles={{...StylesObjects.displayFlexCenter, ...StylesObjects.paddingSliceLimitingContainer}}>
                        <div className={'btns__slices'}>
                            <div className={'icon'}>
                                <i title={'Удалить'} onClick={() => removeItem(item?.guid)}
                                   className="fa-solid fa-trash"></i>
                            </div>
                            <div className={'icon'}>
                                <i title={'Детальная карточка'} onClick={() => openListItems(item)}
                                   className="fa-solid fa-pen-to-square"></i>
                            </div>
                        </div>

                    </LimitingContainer>
                )

            }
        },
    ]
    return {
        arrayToReturn
    };
};

export default UseGetComponentsForFieldList;