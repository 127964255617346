import {useContext, useEffect, useState} from 'react';
import {UserContext} from "../features/authprovider/AuthProvider";
const UseUserInfo = () => {
    const context = useContext(UserContext)
    useEffect(() => {
        if(context?.userInfo?.roles?.some(x => x?.name === 'Admin'))
            setIsAdmin(true)
        else
            setIsAdmin(false)
    }, [context?.userInfo])
    const [isAdmin, setIsAdmin] = useState(false)
    const logout = () => {
        context?.logout()
    }
    const checkAuth = () => {
        context?.checkAuth({
            userName: context?.userInfo?.userName,
            accessToken: context?.userInfo?.accessToken,
            refreshToken: context?.userInfo?.refreshToken,
        })
    }
    const refreshToken = () => {
        context?.refreshToken({
            accessToken: context?.userInfo?.accessToken,
            refreshToken: context?.userInfo?.refreshToken,
        })
    }
    return {
        logout: logout,
        checkAuth: checkAuth,
        refreshToken: refreshToken,
        userInfo: context.userInfo,
        isAdmin
    };
};

export default UseUserInfo;