import { createSlice } from "@reduxjs/toolkit";
import EventEmitter from 'events'
import cloneDeep from 'lodash.clonedeep'

const emitterSlice = createSlice({
    name: 'globalEmitter',
    initialState: new EventEmitter(),
    reducers: {
        on: (state, action) => {
            let newState = cloneDeep(state)
            newState.on(action.payload.Name, action.payload.Handler);

            return newState
        },
        off: (state, action) => {
            let newState = cloneDeep(state)
            console.log('')
            newState.off(action.payload.Name, action.payload.Handler);

            return newState
        }
    }
})
export const { on, off } = emitterSlice.actions
export default emitterSlice.reducer;