import React from 'react';
import './AccessDenied.css'
const AccessDenied = ({isModal = false}) => {
    return (
        <h1 className={'access-denied'} style={ isModal ? {width: '50vw', height: '50vh'} : null}>
           Доступ запрещен
        </h1>
    );
};

export default AccessDenied;