import {useEffect, useRef, useState} from 'react';
import './DefaultInputSuffics.css'
import {useSpring, animated, config} from "@react-spring/web";

const DefaultInputSuffics = ({id, value, setValue, suffics = "", isPrefix, valid = true, toFixedValue, placeholderDefault = "", classNames, ...props}) => {

    const defaultType = typeof (value)
    const [onFocus, setOnFocus] = useState(false)
    const checkValue = defaultType === 'number' && ((value % 2) !== 1) ? Number(value.toFixed(2)) : value
    const prepareValue = defaultType === 'number' && toFixedValue ? checkValue : checkValue;
    const handleValue = isPrefix ? suffics + prepareValue : prepareValue + suffics
    const [inputStyle, api] = useSpring(() => ({
        border: '2px solid #EFEFEF',
        config: config.wobbly
    }))
    useEffect(() => {
        if(valid){
            api.start(() => ({
                border: '2px solid #EFEFEF',
            }))
        }
        else{
            api.start(() => ({
                border: '2px solid var(--color-red)',
            }))
        }

    }, [valid])
    useEffect(() => {
        if(!onFocus && value){
            refInput.current.value = handleValue;
        }
    }, [value])
    const refInput = useRef(null)

    return (
        <animated.input
            {...props}
            ref={refInput}
            id={id}
            placeholder={placeholderDefault}
            style={{...inputStyle, ...props.style}}
            className={`default__input-suffics ${classNames}`}
            defaultValue={value ? handleValue : suffics}
            onBlur={e => {
                api.start(() => ({
                    border: '2px solid #EFEFEF',
                }))
                setOnFocus(false)
                e.target.value = handleValue

            }}
            onFocus={e => {
                api.start(() => ({
                    border: '2px solid #009Fe3',
                }))
                setOnFocus(true)
                if(defaultType === 'number'){
                    if(value !== 0){
                        if(toFixedValue){
                            e.target.value = checkValue.toFixed(toFixedValue)
                        }
                        else{
                            e.target.value = checkValue
                        }
                    }
                    else {
                        e.target.value = ''
                    }
                }
                else{
                    e.target.value = e.target.value.replace(suffics, "")
                }
            }}
            onChange={e => {

                if(defaultType === 'number'){
                    setValue((Number(e.target.value)) || 0)
                }
                else{
                    setValue(e.target.value)
                }
            }}
        />
    );
};


export default DefaultInputSuffics;