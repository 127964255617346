import './Field.css'
import MainInput from "../inputs/maininput/MainInput";
import DefaultInputFile from "../inputs/file/DefaultInputFile";
import SelectDefault from "../selects/selectdefault/SelectDefault";
import MultiSelectDefaul from "../selects/multiselectdefault/MultiSelectDefaul";
import DefaultButton from "../buttons/button/DefaultButton";
import DefaultInputSuffics from "../inputs/tkpdefaultinputsuffics/DefaultInputSuffics";
import {getRubString} from "../../../features/formatters/forrmaterrub";

const Field = ({
                   type = 'input',
                   isEdit = false,
                   labelText = '',
                   placeholder = '',
                   setValue,
                   value,
                   titleValue = '',
                   icon,
                   inputClassNames,
                   suffics = '',
                   valid = true,
                   isPrefics = false,
                   toFixedValue,
                   srcImage,
                   options,
                   background,
                   classNames,
                   valueTwo,
                   placeholderTwo = '',
                   setValueTwo,
                   sufficsTwo,
                   titleValueTwo,
                   fileClassNames,
                   onErrorFileLoadCallback,
                   isMoney
               }) => {



    if (type === 'input')
        return (
            <div className={'field__info'}>
                <label className={'field__label'}>{labelText}</label>
                <div className={'field__input'}>
                    {icon && icon}
                    {isEdit
                        ?
                            <MainInput
                                placeholderDefault={placeholder}
                                value={value}
                                setValue={setValue}
                                classNames={inputClassNames}
                                suffics={suffics}
                                valid={valid}
                                isPrefix={isPrefics}
                                toFixedValue={toFixedValue}
                            />
                        :   <span className={'field__text'}>{isMoney ? getRubString(titleValue || 0) : titleValue ? titleValue : placeholder}</span>
                    }
                </div>
            </div>
        );
    else if (type === 'multi-input')
        return (
            <div className={'field__info'}>
                <label className={'field__label'}>{labelText}</label>
                <div className={'field__input'}>
                    {icon && icon}
                    {isEdit
                        ?
                        <div className={'filed__multi_input'}>
                            <MainInput
                                value={value}
                                suffics={suffics}
                                classNames={inputClassNames}
                                placeholderDefault={placeholder}
                                setValue={setValue}
                            />
                            <MainInput
                                value={valueTwo}
                                suffics={sufficsTwo}
                                classNames={inputClassNames}
                                placeholderDefault={placeholderTwo}
                                setValue={setValueTwo}
                            />
                        </div>
                        : <span className={'field__text'}>{`${isMoney ? getRubString(titleValue || 0) : (titleValue ? titleValue : '')}`}{`${!isMoney && !titleValueTwo ? '' : ' - '}${isMoney ? getRubString(titleValueTwo || 0) : (titleValueTwo ? titleValueTwo : '')}`}</span>
                    }
                </div>
            </div>
        );
    else if (type === 'selector')
        return (
            <div className={'field__info'}>
                <label className={'field__label'}>{labelText}</label>
                <div className={'field__input'}>
                    {icon && icon}
                    {isEdit
                        ?
                        <div className={'field__select'}>
                            <SelectDefault
                                placeholder={placeholder}
                                options={options}
                                setSelectedValue={setValue}
                                selectedValue={value}
                                isCamelCase={true}
                            />
                        </div>
                        :   <span className={'field__text'}>{titleValue}</span>
                    }
                </div>
            </div>
        );
    else if (type === 'multi-selector')
        return (
            <div className={'field__info'}>
                <label className={'field__label'}>{labelText}</label>
                <div className={'field__input'}>
                    {icon && icon}
                    {isEdit
                        ?
                        <div className={'field__select'}>
                            <MultiSelectDefaul
                                options={options}
                                placeholder={placeholder}
                                selectedValueArray={value}
                                setSelectedValueArray={setValue}
                                isCamelCase={true}
                            />
                        </div>
                        : <span className={'field__text'}>{value.reduce((acc, item) => [...acc, item?.value], []).join(", ") || ""}</span>
                    }
                </div>
            </div>
        );
    else if (type === 'checkbox')
        return (
            <div className={'field__info'}>
                <label className={'field__label'}>{labelText}</label>
                <div className={'field__input'}>
                    {icon && icon}
                    {isEdit
                        ?
                            <input
                                className={'field__checkbox'}
                                type={'checkbox'}
                                checked={value}
                                onChange={e => setValue(!value)}
                            />
                        : <span className={'field__text'}>{value ? 'Да' : 'Нет'}</span>
                    }
                </div>
            </div>
        );
    else if (type === 'date')
        return (
            <div className={'field__info'}>
                <label className={'field__label'}>{labelText}</label>
                <div className={'field__input'}>
                    {icon && icon}
                    {isEdit
                        ? <input
                            className={'field__date'}
                            type={'date'}
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />
                        : <span className={'field__text'}>{new Date(value || null)?.toLocaleDateString() || ''}</span>
                    }
                </div>
            </div>
        );
    else if (type === 'file')
        return (
            <div className={'field__info'}>
                <label className={'field__label'}>{labelText}</label>
                <div className={'field__input'}>
                    {isEdit
                        ?
                            <DefaultInputFile
                                currentFileName={value}
                                onLoadFile={setValue}
                            />
                        :   <span className={`field__text`}>{`${titleValue ? titleValue : 'Не заполнен'}`}</span>
                    }
                </div>
            </div>
        );
    else if (type === 'photo')
        return (
            <div className={'field__info'}>
                <label className={'field__label'}>{labelText}</label>
                <div className={'field__input'}>
                    {isEdit
                        ?
                            <DefaultInputFile
                                currentFileName={value}
                                onLoadFile={setValue}
                            />
                        :   <img className={`field__img ${fileClassNames}`} src={srcImage} alt="" onError={onErrorFileLoadCallback}/>
                    }
                </div>
            </div>
        );
    else if (type === 'label')
        return (
            <div className={'field__info'}>
                <label className={'field__label'}>{labelText}</label>
                <div className={'field__input'}>
                    {icon && icon}
                    <span className={'field__text'}>{value}</span>
                </div>
            </div>
        );
    else if (type === 'button')
        return (
            <div className={'field__info'}>
                <DefaultButton
                    title={labelText}
                    background={background}
                    classNames={classNames}
                    onClick={setValue}
                />
            </div>
        );
    else
        return null;
};

export default Field;