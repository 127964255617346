import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import store from './store/store';
import {routes} from './router/router';
import LoaderProvider from "./features/loaderprovider/LoaderProvider";
import './index.css';
import MessageHubProvider from "./features/messagehub/MessageHubProvider";
import './assets/vars.css'
import AuthProvider from "./features/authprovider/AuthProvider";

const router = createBrowserRouter(routes)

const root = ReactDOM.createRoot(document.getElementById('page'));
root.render(
    <Provider store={store}>
        <LoaderProvider>
            <MessageHubProvider>
                <AuthProvider>
                    <RouterProvider router={router}/>
                </AuthProvider>
            </MessageHubProvider>
        </LoaderProvider>
    </Provider>
);