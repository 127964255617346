import React from 'react';
import './Recovery.css'
const Recovery = () => {
    return (
        <div>
            Recovery
        </div>
    );
};

export default Recovery;