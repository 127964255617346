import './ButtonTab.css'
const ButtonTab = ({title, onClick, classNames = '', style, color, background, isActive, icon, ...props}) => {
    const colors = {
        green: '--color-green',
        blue: '--color-blue',
        red: '--color-red',
        lightRed: '--color-light-red',
        white: '--color-white',
        lightGray: '--color-light-gray',
        gray: '--color-gray',
        darkGray: '--color-dark-gray',
        black: '--color-black',
    }
    const handleColor = color ?
        {
            color: `var(${colors[color]})`
        } : null;
    const handleBackground = background ?
        {
            background: `var(${colors[background]})`
        } : null;

    return (
        <div className={`button-tab__wrapper ${classNames} ${isActive ? 'button-tab__active' : ''}`}>
            {icon && icon}
            <button style={{...style, ...handleColor, ...handleBackground}} className={`button-tab`} onClick={() => onClick?.()} {...props}>
                {title}
            </button>
        </div>

    );
};

export default ButtonTab;