import {useState} from 'react';
import './UserDetailCard.css'
import {useNavigate, useParams} from "react-router";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import DefaultButton from "../../../../../components/UI/buttons/button/DefaultButton";
import {createSignalRContext} from "react-signalr/signalr";
import useUserInfo from "../../../../../hooks/useUserInfo";
import useSignalRFunk from "../../../../../hooks/useSignalRFunk";
import useFileUploaderToBackend from "../../../../../hooks/useFileUploaderToBackend";
import Field from "../../../../../components/UI/field/Field";
import ListIcon from "../../../../../components/UI/icons/ListIcon";
import CalendarIcon from "../../../../../components/UI/icons/CalendarIcon";
import FieldBlock from "../../../../../components/fieldblock/FieldBlock";

const SignalRContext = createSignalRContext()
const UserDetailCard = () => {
    const [currentUser, setCurrentUser] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const params = useParams()
    const navigate = useNavigate()
    const {sendFile} = useFileUploaderToBackend()
    const {userInfo, checkAuth} = useUserInfo()
    const {
        checkConnection,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateCurrentUser()
        },
        onReconnectCallback: () => {
            updateCurrentUser()
        }
    })
    const {signalRFunk} = useSignalRFunk({
        SignalRContext: SignalRContext,
        checkConnection,
        argsExternal: []
    })
    const updateCurrentUser = () => signalRFunk('GetUserForId', [Number(params['id'] || 0)], res => setCurrentUser(res))
    const sendFilePhoto = (newFile, callback) => sendFile(newFile, 'avatars', false, callback)

    const {changeItem} = useChangeItemSignalR({
        SignalRContext,
        checkConnection,
        delay: 1000,
        method: 'ChangeUser',
        callbackChangeItemFunk: newItem => setCurrentUser(newItem)
    })

    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/api/crm/userhubdetail/`}
            connectEnabled={true}
            withCredentials={false}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <div className={'user-detail__wrapper'}>
                <div className={'user-detail__header'}>
                    <DefaultButton
                        title={'Назад к списку'}
                        background={'blue'}
                        classNames={'button-extension'}
                        onClick={() => navigate(-1)}
                    />
                    <DefaultButton
                        title={isEdit ? "Сохранить" : 'Редактировать'}
                        background={isEdit ? "red" : 'blue'}
                        classNames={'button-extension'}
                        onClick={() => {
                            setIsEdit(!isEdit);
                            let check = !isEdit
                            if(!check && userInfo?.id === currentUser?.id)
                                checkAuth()
                        }}
                    />
                </div>
                <h1 className={'user-detail_title'}>Пользователь: {currentUser?.fio || ''}</h1>

                <div className={'user-detail__body'}>
                    <FieldBlock title={'Общая информация'}>
                        <Field
                            isEdit={isEdit}
                            icon={<ListIcon/>}
                            labelText={'Имя'}
                            type={'input'}
                            value={currentUser?.firstName || ''}
                            titleValue={currentUser?.firstName || ''}
                            placeholder={'Имя пользователя'}
                            setValue={e => changeItem({...currentUser, firstName: e})}
                        />
                        <Field
                            isEdit={isEdit}
                            icon={<ListIcon/>}
                            labelText={'Фамилия'}
                            type={'input'}
                            value={currentUser?.lastName || ''}
                            titleValue={currentUser?.lastName || ''}
                            placeholder={'Фамилия пользователя'}
                            setValue={e => changeItem({...currentUser, lastName: e})}
                        />
                        <Field
                            isEdit={isEdit}
                            icon={<ListIcon/>}
                            labelText={'Отчество'}
                            type={'input'}
                            value={currentUser?.secondName || ''}
                            titleValue={currentUser?.secondName || ''}
                            placeholder={'Отчество пользователя'}
                            setValue={e => changeItem({...currentUser, secondName: e})}
                        />
                        <Field
                            isEdit={isEdit}
                            icon={<ListIcon/>}
                            labelText={'Роль'}
                            type={'input'}
                            value={currentUser?.role || ''}
                            titleValue={currentUser?.role || ''}
                            placeholder={'Роль пользователя'}
                            setValue={e => changeItem({...currentUser, role: e})}
                        />
                        <Field
                            isEdit={isEdit}
                            labelText={'Аватарка'}
                            type={'file'}
                            value={currentUser?.avatar?.name}
                            srcImage={currentUser?.avatar?.urlPath}
                            setValue={newFile => sendFilePhoto(newFile, data => changeItem({...currentUser, avatar: data}, false))}
                        />
                        <Field
                            isEdit={isEdit}
                            icon={<CalendarIcon/>}
                            labelText={'Дата рождения'}
                            type={'date'}
                            value={currentUser?.birthDate || ''}
                            setValue={newDate => changeItem({...currentUser, birthDate: newDate})}
                        />
                        <div className={'user-detail__info'}>
                            <DefaultButton
                                title={'Удалить пользователя'}
                                background={'red'}
                                classNames={'button-extension'}
                            />
                        </div>
                    </FieldBlock>
                </div>
            </div>
        </SignalRContext.Provider>
    );
};

export default UserDetailCard;