import React, {useEffect} from 'react';
import './DefaultModal.css'
import {useSpring, animated, config} from "@react-spring/web";

const DefaultModal = ({children, visible, setVisible}) => {

    const [springStyle, apiSpring] = useSpring(() => ({
        from: {
            opacity: 0,
            background: 'rgba(0, 0, 0, 0.0)'
        },
        to: {
            opacity: 1,
            background: 'rgba(0, 0, 0, 0.4)'
        },
        config: config.wobbly
    }));

    const preUnmount = () => {
        apiSpring.start(() => ({
            to: {
                background: 'rgba(0, 0, 0, 0.0)',
                opacity: 0
            },
            config: { ...config.wobbly, duration: 100},
            onRest: () => {
                setVisible(false)
            }
        }))
    }
    return (
        <animated.div onClick={() => preUnmount()} style={springStyle} className={visible ? 'default-modal__wrapper default-modal__active' : 'default-modal__wrapper'}>
            <div className={'default-modal__content'} onClick={e => e.stopPropagation()}>
                {children}
            </div>
        </animated.div>
    );
};

export default DefaultModal;