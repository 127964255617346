import {configureStore} from "@reduxjs/toolkit";
import emitterReducer from '../features/emitter/Emitter'

export default configureStore({
    reducer: {
        globalEmitter: emitterReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})
