import {useRef, useState} from 'react';
import './ProjectDetail.css'
import useLoader from "../../../../../hooks/useLoader";
import {useNavigate, useParams} from "react-router";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import DefaultModal from "../../../../../components/UI/modals/DefaultModal";
import UserSelectorList from "../../../../../components/selectoruser/UserSelectorList";
import {createSignalRContext} from "react-signalr/signalr";
import ProjectRentList from "../../projectsrent/list/ProjectRentList";
import ProjectSellList from "../../projectssell/list/ProjectSellList";
import useSignalRFunk from "../../../../../hooks/useSignalRFunk";
import useUserInfo from "../../../../../hooks/useUserInfo";
import DetailWrapper from "../../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../../components/UI/buttons/buttontab/ButtonTab";
import RequisitesIcon from "../../../../../components/UI/icons/RequisitesIcon";
import InvoiceIcon from "../../../../../components/UI/icons/InvoiceIcon";
import CrossIcon from "../../../../../components/UI/icons/CrossIcon";
import DetailBody from "../../../../../components/detailcard/body/DetailBody";
import {useMultiStepComponent} from "../../../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import ProjectInformation from "./components/information/ProjectInformation";
import useFileUploaderToBackend from "../../../../../hooks/useFileUploaderToBackend";
import Test from "../../../../testpage/Test";

const SignalRContext = createSignalRContext()

const ProjectDetail = () => {
    const [showSelectorUsers, setShowSelectorUsers] = useState(false)
    const [showVariationRentList, setShowVariationRentList] = useState(false)
    const [showVariationSellList, setShowVariationSellList] = useState(false)
    const [currentProject, setCurrentProject] = useState(null)
    const dataIsLoadedRef = useRef(false)
    const [typeLineOfPlacementOptions, setTypeLineOfPlacementOptions] = useState([])
    const [typeCommunicationOptions, setTypeCommunicationOptions] = useState([])
    const [typeFloorObjectOptions, setTypeFloorObjectOptions] = useState([])
    const [typeEntranceOptions, setTypeEntranceOptions] = useState([])
    const [typeSeparateEntranceOptions, setTypeSeparateEntranceOptions] = useState([])
    const [typeRealtyTypeOptions, setTypeRealtyTypeOptions] = useState([])
    const [typeUnloadingAvalabilityOptions, setTypeUnloadingAvalabilityOptions] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const {hideAll} = useLoader()
    const params = useParams()
    const navigate = useNavigate()
    const {userInfo} = useUserInfo()
    const {sendFile} = useFileUploaderToBackend()
    const {
        checkConnection,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateCurrentProject()
            updateAllUsers()
            updateEnums("LineOfPlacement", res => {
                setTypeLineOfPlacementOptions(res)
            });
            updateEnums("RealtyType", res => {
                setTypeRealtyTypeOptions(res)
            });
            updateEnums("Communication", res => {
                setTypeCommunicationOptions(res)
            });
            updateEnums("FloorObject", res => {
                setTypeFloorObjectOptions(res)
            });
            updateEnums("Entrance", res => {
                setTypeEntranceOptions(res)
            });
            updateEnums("SeparateEntrance", res => {
                setTypeSeparateEntranceOptions(res)
            });
            updateEnums("UnloadingAvalability", res => {
                setTypeUnloadingAvalabilityOptions(res)
            });
        },
        onReconnectCallback: () => {
            updateCurrentProject()
            updateAllUsers()
            updateEnums("LineOfPlacement", res => {
                setTypeLineOfPlacementOptions(res)
            });
            updateEnums("RealtyType", res => {
                setTypeRealtyTypeOptions(res)
            });
            updateEnums("Communication", res => {
                setTypeCommunicationOptions(res)
            });
            updateEnums("FloorObject", res => {
                setTypeFloorObjectOptions(res)
            });
            updateEnums("Entrance", res => {
                setTypeEntranceOptions(res)
            });
            updateEnums("SeparateEntrance", res => {
                setTypeSeparateEntranceOptions(res)
            });
            updateEnums("UnloadingAvalability", res => {
                setTypeUnloadingAvalabilityOptions(res)
            });
        }
    })
    const {signalRFunk} = useSignalRFunk({
        checkConnection,
        SignalRContext
    })
    const sendFilePhoto = (newFile, callback) => sendFile(newFile, 'projects', false, callback)
    const updateCurrentProject = () => signalRFunk('GetProjectForId', [Number(params['id'] || 0)], res => {
        setCurrentProject(res)
        dataIsLoadedRef.current = true
    })
    const updateEnums = (accessor, callback) => signalRFunk('GetAllEnumsByAccessor', [accessor], callback)
    const updateStatusesForTypeAndEntity = (type, destEntity, callback) => signalRFunk('GetAllStatusInfoByEntityAndType', [type, destEntity], callback)
    const updateAllUsers = () => signalRFunk('GetAllUsers', [], res => {
        setAllUsers(res)
    })

    const {changeItem} = useChangeItemSignalR({
        SignalRContext,
        checkConnection,
        delay: 1000,
        method: 'ChangeProject',
        callbackChangeItemFunk: newItem => setCurrentProject(newItem)
    })

    const {
        titleStep,
        step,
        goTO
    } = useMultiStepComponent([
        {
            component: <ProjectInformation
                currentProject={currentProject}
                changeItem={changeItem}
                setShowSelectorUsers={setShowSelectorUsers}
                typeCommunicationOptions={typeCommunicationOptions}
                typeFloorObjectOptions={typeFloorObjectOptions}
                typeLineOfPlacementOptions={typeLineOfPlacementOptions}
                typeEntranceOptions={typeEntranceOptions}
                typeUnloadingAvalabilityOptions={typeUnloadingAvalabilityOptions}
                typeSeparateEntranceOptions={typeSeparateEntranceOptions}
                typeRealtyTypeOptions={typeRealtyTypeOptions}
                sendFilePhoto={sendFilePhoto}
                dataIsLoadedRef={dataIsLoadedRef.current}
            />,
            titleStep: 'Информация'
        },
        {
            component: <ProjectRentList currentProject={currentProject} setCurrentProject={setCurrentProject} SignalRContext={SignalRContext} signalRFunk={signalRFunk}/>,
            titleStep: 'Вариации аренды'
        },
        {
            component: <Test/>,
            titleStep: 'Вариации продажи'
        },
        /*{
            component: <ProjectSellList currentProject={currentProject} setCurrentProject={setCurrentProject} SignalRContext={SignalRContext} signalRFunk={signalRFunk}/>,
            titleStep: 'Вариации продажи'
        },*/
    ])

    const access = useCheckAccess("client/detail")

    if(!access){
        hideAll()
        return <AccessDenied />
    }
    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/api/crm/projecthubdetail/?token=${userInfo?.accessToken || ''}`}
            connectEnabled={true}
            withCredentials={false}
            accessTokenFactory={() => userInfo?.accessToken || ''}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <DetailWrapper>
                <TabsBlock>
                    <ButtonTab
                        icon={<RequisitesIcon classNames={'icon-extension'}/>}
                        title={'Информация'}
                        onClick={() => goTO(0)}
                        isActive={titleStep === 'Информация'}
                    />
                    <ButtonTab
                        icon={<InvoiceIcon classNames={'icon-extension'}/>}
                        title={'Вариации аренды'}
                        onClick={() => goTO(1)}
                        isActive={titleStep === 'Вариации аренды'}
                    />
                    <ButtonTab
                        icon={<InvoiceIcon classNames={'icon-extension'}/>}
                        title={'Вариации продажи'}
                        onClick={() => goTO(2)}
                        isActive={titleStep === 'Вариации продажи'}
                    />
                    <ButtonTab
                        title={'Назад к списку'}
                        onClick={() => navigate(-1)}

                    />
                    <ButtonTab
                        icon={<CrossIcon classNames={'icon-extension'}/>}
                        title={'Удалить проект'}
                        classNames={'button-extension_delete'}
                        color={'red'}
                        onClick={() => {}}
                    />
                </TabsBlock>
                <DetailBody>
                    {step}
                </DetailBody>
            </DetailWrapper>
            {showSelectorUsers &&
                <DefaultModal visible={showSelectorUsers} setVisible={e => setShowSelectorUsers(e)}>
                    <UserSelectorList
                        users={allUsers}
                        cancel={() => setShowSelectorUsers(false)}
                        currentUser={currentProject?.assignedBtProject}
                        setSelectedUser={newUser => changeItem({...currentProject, assignedBtProject: newUser}, false)}
                    />
                </DefaultModal>
            }
            {showVariationRentList &&
                <DefaultModal visible={showVariationRentList} setVisible={e => setShowVariationRentList(e)}>
                    <ProjectRentList
                        currentProject={currentProject}
                        setCurrentProject={setCurrentProject}
                        SignalRContext={SignalRContext}
                    />
                </DefaultModal>
            }
            {showVariationSellList &&
                <DefaultModal visible={showVariationSellList} setVisible={e => setShowVariationSellList(e)}>
                    <ProjectSellList
                        currentProject={currentProject}
                        setCurrentProject={setCurrentProject}
                        SignalRContext={SignalRContext}
                    />
                </DefaultModal>
            }
        </SignalRContext.Provider>
    );
};

export default ProjectDetail;