import React from 'react';
import './Register.css'
const Register = () => {
    return (
        <div>
            Register
        </div>
    );
};

export default Register;