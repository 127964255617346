import './ClientInformation.css'
import FieldBlock from "../../../../../../components/fieldblock/FieldBlock";
import Field from "../../../../../../components/UI/field/Field";
import ListIcon from "../../../../../../components/UI/icons/ListIcon";
import ClockIcon from "../../../../../../components/UI/icons/ClockIcon";
import {useState} from "react";
import TitleDetailCard from "../../../../../../components/UI/titledetailcard/TitleDetailCard";
import DetailCardInformationBlock from "../../../../../../components/detailcard/information/DetailCardInformationBlock";
const ClientInformation = ({
                               changeItem,
                               currentClient,
                               setShowSelectorUsers,
                               typeClientOptions,
                               categoryClientOptions,
                               typeActivityOptions,
                               businessTypesOptions

                           }) => {
    const [isEdit, setIsEdit] = useState(false)
    return (
        <>
            <TitleDetailCard text={'Изменить данные клиента'}/>
            <DetailCardInformationBlock>
                <FieldBlock title={'Общая информация'}>
                    <Field
                        isEdit={isEdit}
                        icon={<ListIcon/>}
                        labelText={'Наименование'}
                        type={'input'}
                        value={currentClient?.generalName || ''}
                        titleValue={currentClient?.generalName || ''}
                        placeholder={'Наименование'}
                        setValue={e => changeItem({...currentClient, generalName: e})}
                    />
                    <Field
                        isEdit={isEdit}
                        icon={<ListIcon/>}
                        labelText={'Информация о клиенте'}
                        type={'input'}
                        value={currentClient?.infoClient || ''}
                        titleValue={currentClient?.infoClient || ''}
                        placeholder={'Информация'}
                        setValue={e => changeItem({...currentClient, infoClient: e})}
                    />
                    <Field
                        icon={<ClockIcon/>}
                        type={'label'}
                        value={currentClient?.lastActivity || "В разработке"}
                        labelText={'Дата крайнего взаимодействия'}
                    />
                    <Field
                        type={'label'}
                        value={currentClient?.assignedForClients?.fio || ""}
                        labelText={'Ответственный'}
                    />
                    <Field
                        type={'button'}
                        setValue={() => setShowSelectorUsers(true)}
                        labelText={'Сменить ответственного'}
                        background={'blue'}
                        classNames={'button-extension'}
                    />
                    <Field
                        type={'button'}
                        setValue={() => setIsEdit(!isEdit)}
                        labelText={isEdit ? "Сохранить" : 'Редактировать'}
                        background={isEdit ? "red" : 'blue'}
                        classNames={'button-extension'}
                    />
                </FieldBlock>
                <FieldBlock title={'Категоризация'}>
                    <Field
                        labelText={'Тип клиента'}
                        isEdit={isEdit}
                        type={'selector'}
                        placeholder={'Тип клиента'}
                        options={typeClientOptions}
                        value={currentClient?.clientType}
                        setValue={e => changeItem({...currentClient, clientType: e}, false)}
                        titleValue={currentClient?.clientType?.value || ""}
                        icon={<ListIcon/>}
                    />
                    <Field
                        labelText={'Категория клиента'}
                        isEdit={isEdit}
                        type={'selector'}
                        placeholder={'Категория клиента'}
                        options={categoryClientOptions}
                        value={currentClient?.categoryClient}
                        setValue={e => changeItem({...currentClient, categoryClient: e}, false)}
                        titleValue={currentClient?.categoryClient?.value || ""}
                        icon={<ListIcon/>}
                    />
                    <Field
                        labelText={'Тип деятельности'}
                        isEdit={isEdit}
                        type={'selector'}
                        placeholder={'Тип деятельности'}
                        options={typeActivityOptions}
                        value={currentClient?.typeActivity}
                        setValue={e => changeItem({...currentClient, typeActivity: e}, false)}
                        titleValue={currentClient?.typeActivity?.value || ""}
                        icon={<ListIcon/>}
                    />
                    <Field
                        labelText={'Вид бизнеса'}
                        isEdit={isEdit}
                        type={'multi-selector'}
                        placeholder={'Вид бизнеса'}
                        options={businessTypesOptions}
                        value={currentClient?.businessTypes || []}
                        setValue={newArray => changeItem({...currentClient, businessTypes: newArray}, true, [], false)}
                        icon={<ListIcon/>}
                    />
                </FieldBlock>
                <FieldBlock title={'Лента взаимодействий'}>

                </FieldBlock>
            </DetailCardInformationBlock>
        </>
    );
};

export default ClientInformation;