import React from 'react';
import './Main.css';
import Test from "../testpage/Test";


const Main = () => {


    return (
        <Test/>
    );
};

export default Main;
