import './FieldBlock.css'
import {useState} from "react";
import ChevronDownIcon from "../UI/icons/ChevronDownIcon";
const FieldBlock = ({children, title}) => {
    const [hidden, setHidden] = useState(false)
    return (
        <div className={'field__block'}>
            <div className={'field__block_header'} onClick={() => setHidden(!hidden)}>
                <h3 className={'field__title'}>{title || ''}</h3>
                <ChevronDownIcon classNames={`field__block_icon ${hidden ? 'field__block_icon_rotate' : ''}`}/>
            </div>
            {!hidden && children}
        </div>
    );
};

export default FieldBlock;