import {useRef, useState} from 'react';
import './ProjectSellDetail.css'
import useLoader from "../../../../../hooks/useLoader";
import {useNavigate, useParams} from "react-router";
import useMessageHub from "../../../../../hooks/useMessageHub";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import DefaultButton from "../../../../../components/UI/buttons/button/DefaultButton";
import DefaultInputSuffics from "../../../../../components/UI/inputs/tkpdefaultinputsuffics/DefaultInputSuffics";
import SelectDefault from "../../../../../components/UI/selects/selectdefault/SelectDefault";
import {
    converterEntityToOptionOrReverse,
    getOptionsFromArrayEntities
} from "../../../../../features/entityhelper/EntityHelper";
import MultiSelectDefaul from "../../../../../components/UI/selects/multiselectdefault/MultiSelectDefaul";
import {getRubString} from "../../../../../features/formatters/forrmaterrub";
import DefaultModal from "../../../../../components/UI/modals/DefaultModal";
import UserSelectorList from "../../../../../components/selectoruser/UserSelectorList";
import ProjectRentList from "../../projectsrent/list/ProjectRentList";
import ProjectSellList from "../list/ProjectSellList";
import {createSignalRContext} from "react-signalr/signalr";

const SignalRContext = createSignalRContext()
const ProjectSellDetail = () => {
    const [showSelectorUsers, setShowSelectorUsers] = useState(false)
    const [showVariationRentList, setShowVariationRentList] = useState(false)
    const [showVariationSellList, setShowVariationSellList] = useState(false)
    const [currentProject, setCurrentProject] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const dataIsLoadedRef = useRef(false)
    const [sources, setSources] = useState([])
    const [stages, setStages] = useState([])
    const [typeDistrictOptions, setTypeDistrictOptions] = useState([])
    const [typeLineOfPlacementOptions, setTypeLineOfPlacementOptions] = useState([])
    const [typeRealtyTypeOptions, setTypeRealtyTypeOptions] = useState([])
    const [typeCommunicationOptions, setTypeCommunicationOptions] = useState([])
    const [typeFloorObjectOptions, setTypeFloorObjectOptions] = useState([])
    const [typeEntranceOptions, setTypeEntranceOptions] = useState([])
    const [typeHeatingsOptions, setTypeHeatingsOptions] = useState([])
    const [typeIntendedPurposeOptions, setTypeIntendedPurposeptions] = useState([])
    const [typeObjectStatusOptions, setTypeObjectStatusOptions] = useState([])
    const [typeParkingAvailabilityOptions, setTypeParkingAvailabilityOptions] = useState([])
    const [typeSeparateEntranceOptions, setTypeSeparateEntranceOptions] = useState([])
    const [typeUnloadingAvalabilityOptions, setTypeUnloadingAvalabilityOptions] = useState([])
    const [typeNetworkAppealOptions, setTypeNetworkAppealOptions] = useState([])
    const [typeIndexOptions, setTypeIndexOptions] = useState([])
    const [typeHolidaysRequirementOptions, setTypeHolidaysRequirementOptions] = useState([])
    const [typeDivisionPossibleOptions, setTypeDivisionPossibleOptions] = useState([])
    const [typeWhoPaysUtilitiesOptions, setTypeWhoPaysUtilitiesOptions] = useState([])
    const [typeExclusiveContractOptions, setTypeExclusiveContractOptions] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const {show, hide} = useLoader()
    const params = useParams()
    const navigate = useNavigate()
    const addMessage = useMessageHub()
    const {
        connectionRef,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateCurrentProject()
            updateAllUsers()
            updateEnums("Districts", res => {
                setTypeDistrictOptions(res)
            });
            updateEnums("DivisionPossible", res => {
                setTypeDivisionPossibleOptions(res)
            });
            updateEnums("WhoPaysUtilities", res => {
                setTypeWhoPaysUtilitiesOptions(res)
            });
            updateEnums("ExclusiveContract", res => {
                setTypeExclusiveContractOptions(res)
            });
            updateEnums("LineOfPlacement", res => {
                setTypeLineOfPlacementOptions(res)
            });
            updateEnums("RealtyType", res => {
                setTypeRealtyTypeOptions(res)
            });
            updateEnums("Communication", res => {
                setTypeCommunicationOptions(res)
            });
            updateEnums("FloorObject", res => {
                setTypeFloorObjectOptions(res)
            });
            updateEnums("Entrance", res => {
                setTypeEntranceOptions(res)
            });
            updateEnums("Heatings", res => {
                setTypeHeatingsOptions(res)
            });
            updateEnums("IntendedPurpose", res => {
                setTypeIntendedPurposeptions(res)
            });
            updateEnums("ObjectStatus", res => {
                setTypeObjectStatusOptions(res)
            });
            updateEnums("ParkingAvailability", res => {
                setTypeParkingAvailabilityOptions(res)
            });
            updateEnums("SeparateEntrance", res => {
                setTypeSeparateEntranceOptions(res)
            });
            updateEnums("UnloadingAvalability", res => {
                setTypeUnloadingAvalabilityOptions(res)
            });
            updateEnums("NetworkAppeal", res => {
                setTypeNetworkAppealOptions(res)
            });
            updateEnums("Index", res => {
                setTypeIndexOptions(res)
            });
            updateEnums("HolidaysRequirement", res => {
                setTypeHolidaysRequirementOptions(res)
            });
            updateStatusesForTypeAndEntity("stage", "sellProject", res => {
                setStages(res)
            });
            updateStatusesForTypeAndEntity("source", "all", res => {
                setSources(res)
            });
        },
        onReconnectCallback: () => {
            updateCurrentProject()
            updateAllUsers()
            updateEnums("Districts", res => {
                setTypeDistrictOptions(res)
            });
            updateEnums("DivisionPossible", res => {
                setTypeDivisionPossibleOptions(res)
            });
            updateEnums("WhoPaysUtilities", res => {
                setTypeWhoPaysUtilitiesOptions(res)
            });
            updateEnums("ExclusiveContract", res => {
                setTypeExclusiveContractOptions(res)
            });
            updateEnums("LineOfPlacement", res => {
                setTypeLineOfPlacementOptions(res)
            });
            updateEnums("RealtyType", res => {
                setTypeRealtyTypeOptions(res)
            });
            updateEnums("Communication", res => {
                setTypeCommunicationOptions(res)
            });
            updateEnums("FloorObject", res => {
                setTypeFloorObjectOptions(res)
            });
            updateEnums("Entrance", res => {
                setTypeEntranceOptions(res)
            });
            updateEnums("Heatings", res => {
                setTypeHeatingsOptions(res)
            });
            updateEnums("IntendedPurpose", res => {
                setTypeIntendedPurposeptions(res)
            });
            updateEnums("ObjectStatus", res => {
                setTypeObjectStatusOptions(res)
            });
            updateEnums("ParkingAvailability", res => {
                setTypeParkingAvailabilityOptions(res)
            });
            updateEnums("SeparateEntrance", res => {
                setTypeSeparateEntranceOptions(res)
            });
            updateEnums("UnloadingAvalability", res => {
                setTypeUnloadingAvalabilityOptions(res)
            });
            updateEnums("NetworkAppeal", res => {
                setTypeNetworkAppealOptions(res)
            });
            updateEnums("Index", res => {
                setTypeIndexOptions(res)
            });
            updateEnums("HolidaysRequirement", res => {
                setTypeHolidaysRequirementOptions(res)
            });
            updateStatusesForTypeAndEntity("stage", "rentProject", res => {
                setStages(res)
            });
            updateStatusesForTypeAndEntity("source", "all", res => {
                setSources(res)
            });
        }
    })
    const updateCurrentProject = () => {
        show()
        SignalRContext.invoke('GetProjectForId', Number(params['idVariation'] || 0))
            .then(res => {
                setCurrentProject(res)
                dataIsLoadedRef.current = true
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const updateEnums = (accessor, callback) => {
        show()
        SignalRContext.invoke('GetAllEnumsByAccessor', accessor)
            .then(res => {
                if(callback)
                    callback?.(res);
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const updateStatusesForTypeAndEntity = (type, destEntity, callback) => {
        show()
        SignalRContext.invoke('GetAllStatusInfoByEntityAndType', type, destEntity)
            .then(res => {
                if(callback)
                    callback?.(res);
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const updateAllUsers = () => {
        show()
        SignalRContext.invoke('GetAllUsers')
            .then(res => {
                setAllUsers(res)
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }

    const {changeItem} = useChangeItemSignalR({
        SignalRContext: SignalRContext,
        connectionRef: connectionRef,
        delay: 1000,
        method: 'ChangeProject',
        callbackChangeItemFunk: newItem => setCurrentProject(newItem)
    })
    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/api/crm/projectsellhubdetail/`}
            connectEnabled={true}
            withCredentials={false}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <div className={'project-detail__wrapper'}>
                <div className={'project-detail__header'}>
                    <DefaultButton
                        title={'Назад к списку'}
                        background={'blue'}
                        classNames={'button-extension'}
                        onClick={() => navigate(-1)}
                    />
                    <DefaultButton
                        title={isEdit ? "Сохранить" : 'Редактировать'}
                        background={isEdit ? "red" : 'blue'}
                        classNames={'button-extension'}
                        onClick={() => setIsEdit(!isEdit)}
                    />
                </div>
                <h1 className={'project-detail_title'}>Вариация продажи: {currentProject?.name || ''}</h1>
                <div className={'project-detail__body'}>
                    <div className={'project-detail__block'}>
                        <h3 className={'project-detail__block_title'}>Общая информация</h3>
                        <div className={'project-detail__info'}>
                            <label className={'project-detail__label'}>Наименование вариации:</label>
                            {isEdit
                                ? <DefaultInputSuffics
                                    value={currentProject?.name || ''}
                                    placeholderDefault={'Название'}
                                    setValue={e => changeItem({...currentProject, name: e})}
                                    classNames={'input-extension'}
                                />
                                : <span className={'project-detail__text'}>{currentProject?.name || ""}</span>
                            }
                        </div>
                        <div className={'project-detail__info'}>
                            <label className={'project-detail__label'}>Стадия:</label>
                            {isEdit
                                ?
                                <div className={'project-detail__select'}>
                                    <SelectDefault
                                        placeholder={'Стадия'}
                                        options={getOptionsFromArrayEntities({
                                            entities: stages || [],
                                            accessorName: 'stages',
                                            valueCallback: item => item?.statusName,
                                            titleCallback: item => item?.statusName,
                                            idCallback: item => item?.id
                                        })}
                                        setSelectedValue={e => changeItem({...currentProject, stage: converterEntityToOptionOrReverse({
                                                endpoint: 'entity',
                                                arrayEntities: stages,
                                                accessorName: 'stages',
                                                valueCallback: item => item?.statusName,
                                                titleCallback: item => item?.statusName,
                                                idCallback: item => item?.id,
                                                searchPropertyForArray: e?.id
                                            })}, false)}
                                        selectedValue={converterEntityToOptionOrReverse({
                                            endpoint: 'option',
                                            arrayEntities: stages,
                                            accessorName: 'stages',
                                            valueCallback: item => item?.statusName,
                                            titleCallback: item => item?.statusName,
                                            idCallback: item => item?.id,
                                            searchPropertyForArray: currentProject?.stage?.id
                                        })}
                                        isCamelCase={true}
                                    />
                                </div>
                                :   <span className={'project-detail__text'}>{currentProject?.stage?.statusName || ""}</span>
                            }
                        </div>
                        <div className={'project-detail__info'}>
                            <label className={'project-detail__label'}>Ответственный:</label>
                            <span className={'project-detail__text'}>{currentProject?.assignedForVariation?.fio || ""}</span>
                        </div>
                        {dataIsLoadedRef.current ?
                            <>
                                <div className={'project-detail__info'}>
                                    <DefaultButton
                                        title={'Сменить ответственного'}
                                        background={'blue'}
                                        classNames={'button-extension'}
                                        onClick={() => setShowSelectorUsers(true)}
                                    />
                                </div>
                                <div className={'project-detail__info'}>
                                    <DefaultButton
                                        title={'Удалить вариацию'}
                                        background={'red'}
                                        classNames={'button-extension'}
                                    />
                                </div>
                            </>
                            : null
                        }
                    </div>
                    {dataIsLoadedRef.current
                        ?
                        <>
                            <div className={'project-detail__block'}>
                                <h3 className={'project-detail__block_title'}>Технические показатели</h3>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Коммуникации:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <MultiSelectDefaul
                                                options={typeCommunicationOptions}
                                                placeholder={'Выбрать'}
                                                selectedValueArray={currentProject?.technicalSpecificationsSell?.communications || []}
                                                setSelectedValueArray={newArray => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, communications: newArray}}, true, [], false)}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.technicalSpecificationsSell?.communications?.reduce((acc, item) => [...acc, item?.value], []).join(", ") || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Этажи объекта:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <MultiSelectDefaul
                                                options={typeFloorObjectOptions}
                                                placeholder={'Выбрать'}
                                                selectedValueArray={currentProject?.technicalSpecificationsSell?.floor || []}
                                                setSelectedValueArray={newArray => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, floor: newArray}}, true, [], false)}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.technicalSpecificationsSell?.floor?.reduce((acc, item) => [...acc, item?.value], []).join(", ") || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Вход:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Вход'}
                                                options={typeEntranceOptions}
                                                setSelectedValue={e => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, entrance: e}}, false)}
                                                selectedValue={currentProject?.technicalSpecificationsSell?.entrance}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.technicalSpecificationsSell?.entrance?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Отдельный вход:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Вход'}
                                                options={typeSeparateEntranceOptions}
                                                setSelectedValue={e => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, separateEntrance: e}}, false)}
                                                selectedValue={currentProject?.technicalSpecificationsSell?.separateEntrance}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.technicalSpecificationsSell?.separateEntrance?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Разгрузка:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Разгрузка'}
                                                options={typeUnloadingAvalabilityOptions}
                                                setSelectedValue={e => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, unloadingAvailability: e}}, false)}
                                                selectedValue={currentProject?.technicalSpecificationsSell?.unloadingAvailability}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.technicalSpecificationsSell?.unloadingAvailability?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Парковка:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Парковка'}
                                                options={typeParkingAvailabilityOptions}
                                                setSelectedValue={e => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, parkingAvailability: e}}, false)}
                                                selectedValue={currentProject?.technicalSpecificationsSell?.parkingAvailability}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.technicalSpecificationsSell?.parkingAvailability?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Состояние объекта:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Состояние'}
                                                options={typeObjectStatusOptions}
                                                setSelectedValue={e => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, objectStatus: e}}, false)}
                                                selectedValue={currentProject?.technicalSpecificationsSell?.objectStatus}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.technicalSpecificationsSell?.objectStatus?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Отопление:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Отопление'}
                                                options={typeHeatingsOptions}
                                                setSelectedValue={e => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, heating: e}}, false)}
                                                selectedValue={currentProject?.technicalSpecificationsSell?.heating}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.technicalSpecificationsSell?.heating?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Возможно деление:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Деление'}
                                                options={typeDivisionPossibleOptions}
                                                setSelectedValue={e => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, divisionPossible: e}}, false)}
                                                selectedValue={currentProject?.technicalSpecificationsSell?.divisionPossible}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.technicalSpecificationsSell?.divisionPossible?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Тип недвижимости:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Тип'}
                                                options={typeRealtyTypeOptions}
                                                setSelectedValue={e => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, realtyType: e}}, false)}
                                                selectedValue={currentProject?.technicalSpecificationsSell?.realtyType}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.technicalSpecificationsSell?.realtyType?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Мощность электросети:</label>
                                    {isEdit
                                        ?
                                        <DefaultInputSuffics
                                            value={currentProject?.technicalSpecificationsSell?.powerElectricity || 0}
                                            suffics={' кВт'}
                                            placeholderDefault={'кВт'}
                                            setValue={e => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, powerElectricity: e}})}
                                            classNames={'input-extension'}
                                        />
                                        : <span className={'project-detail__text'}>{`${currentProject?.technicalSpecificationsSell?.powerElectricity || ""} кВт`}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Площадь:</label>
                                    {isEdit
                                        ?
                                        <DefaultInputSuffics
                                            value={currentProject?.technicalSpecificationsSell?.square || 0}
                                            suffics={' м²'}
                                            placeholderDefault={'м²'}
                                            setValue={e => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, square: e}})}
                                            classNames={'input-extension'}
                                        />
                                        :   <span className={'project-detail__text'}>{`${currentProject?.technicalSpecificationsSell?.square || ""} м²`}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Высота потолков:</label>
                                    {isEdit
                                        ?
                                        <DefaultInputSuffics
                                            value={currentProject?.technicalSpecificationsSell?.ceilingHeight || 0}
                                            suffics={' м'}
                                            placeholderDefault={'м'}
                                            setValue={e => changeItem({...currentProject, technicalSpecificationsSell: {...currentProject?.technicalSpecificationsSell, ceilingHeight: e}})}
                                            classNames={'input-extension'}
                                        />
                                        :   <span className={'project-detail__text'}>{`${currentProject?.technicalSpecificationsSell?.ceilingHeight || ""} м`}</span>
                                    }
                                </div>
                            </div>
                            <div className={'project-detail__block'}>
                                <h3 className={'project-detail__block_title'}>Коммерческие показатели</h3>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Назначения объекта:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <MultiSelectDefaul
                                                options={typeIntendedPurposeOptions}
                                                placeholder={'Выбрать'}
                                                selectedValueArray={currentProject?.commercialSpecificationSell?.intendedPurpose || []}
                                                setSelectedValueArray={newArray => changeItem({...currentProject, commercialSpecificationSell: {...currentProject?.commercialSpecificationSell, intendedPurpose: newArray}}, true, [], false)}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.commercialSpecificationSell?.intendedPurpose?.reduce((acc, item) => [...acc, item?.value], []).join(", ") || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Арендные каникулы:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Каникулы'}
                                                options={typeHolidaysRequirementOptions}
                                                setSelectedValue={e => changeItem({...currentProject, commercialSpecificationSell: {...currentProject?.commercialSpecificationSell, rentalHolidays: e}}, false)}
                                                selectedValue={currentProject?.commercialSpecificationSell?.rentalHolidays}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.commercialSpecificationSell?.rentalHolidays?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Эксклюзивный договор:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Эксклюзив'}
                                                options={typeExclusiveContractOptions}
                                                setSelectedValue={e => changeItem({...currentProject, commercialSpecificationSell: {...currentProject?.commercialSpecificationSell, exclusiveContract: e}}, false)}
                                                selectedValue={currentProject?.commercialSpecificationSell?.exclusiveContract}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.commercialSpecificationSell?.exclusiveContract?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Индексация:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Индексация'}
                                                options={typeIndexOptions}
                                                setSelectedValue={e => changeItem({...currentProject, commercialSpecificationSell: {...currentProject?.commercialSpecificationSell, indexType: e}}, false)}
                                                selectedValue={currentProject?.commercialSpecificationSell?.indexType}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.commercialSpecificationSell?.indexType?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Кто оплачивает коммунальные платежи:</label>
                                    {isEdit
                                        ?
                                        <div className={'project-detail__select'}>
                                            <SelectDefault
                                                placeholder={'Выбор'}
                                                options={typeWhoPaysUtilitiesOptions}
                                                setSelectedValue={e => changeItem({...currentProject, commercialSpecificationSell: {...currentProject?.commercialSpecificationSell, whoPaysUtilities: e}}, false)}
                                                selectedValue={currentProject?.commercialSpecificationSell?.whoPaysUtilities}
                                                isCamelCase={true}
                                            />
                                        </div>
                                        :   <span className={'project-detail__text'}>{currentProject?.commercialSpecificationSell?.whoPaysUtilities?.value || ""}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Арендная плата:</label>
                                    {isEdit
                                        ?
                                        <DefaultInputSuffics
                                            value={currentProject?.commercialSpecificationSell?.rentalPrice || 0}
                                            suffics={' ₽'}
                                            placeholderDefault={'₽'}
                                            setValue={e => changeItem({...currentProject, commercialSpecificationSell: {...currentProject?.commercialSpecificationSell, rentalPrice: e}})}
                                            classNames={'input-extension'}
                                        />
                                        : <span className={'project-detail__text'}>{getRubString(currentProject?.commercialSpecificationSell?.rentalPrice || 0)}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Комиссия:</label>
                                    {isEdit
                                        ?
                                        <DefaultInputSuffics
                                            value={currentProject?.commercialSpecificationSell?.commission || 0}
                                            suffics={' ₽'}
                                            placeholderDefault={'₽'}
                                            setValue={e => changeItem({...currentProject, commercialSpecificationSell: {...currentProject?.commercialSpecificationSell, commission: e}})}
                                            classNames={'input-extension'}
                                        />
                                        :   <span className={'project-detail__text'}>{getRubString(currentProject?.commercialSpecificationSell?.commission || 0)}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Цена за квадратный метр:</label>
                                    {isEdit
                                        ?
                                        <DefaultInputSuffics
                                            value={currentProject?.commercialSpecificationSell?.priceForSquareMeter || 0}
                                            suffics={' ₽'}
                                            placeholderDefault={'₽'}
                                            setValue={e => changeItem({...currentProject, commercialSpecificationSell: {...currentProject?.commercialSpecificationSell, priceForSquareMeter: e}})}
                                            classNames={'input-extension'}
                                        />
                                        :   <span className={'project-detail__text'}>{getRubString(currentProject?.commercialSpecificationSell?.priceForSquareMeter || 0)}</span>
                                    }
                                </div>
                                <div className={'project-detail__info'}>
                                    <label className={'project-detail__label'}>Процент индексации:</label>
                                    {isEdit
                                        ?
                                        <DefaultInputSuffics
                                            value={currentProject?.commercialSpecificationSell?.percentIndex || 0}
                                            suffics={'%'}
                                            placeholderDefault={'%'}
                                            setValue={e => changeItem({...currentProject, commercialSpecificationSell: {...currentProject?.commercialSpecificationSell, percentIndex: e}})}
                                            classNames={'input-extension'}
                                        />
                                        :   <span className={'project-detail__text'}>{`${currentProject?.commercialSpecificationSell?.percentIndex || ""}%`}</span>
                                    }
                                </div>
                            </div>
                            <div className={'project-detail__block'}>
                                <div className={'project-detail__block'}>
                                    <h3 className={'project-detail__block_title'}>Фото</h3>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Титульное фото:</label>
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Фото входной группы:</label>
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Фото разгрузки:</label>
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Фото локации с карты:</label>
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Фото планировок:</label>
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Внешние фото:</label>
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Внутренние фото:</label>
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Остальные фото:</label>
                                    </div>
                                </div>
                                <div className={'project-detail__block'}>
                                    <h3 className={'project-detail__block_title'}>Документы</h3>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Кадастровый номер:</label>
                                        {isEdit
                                            ?
                                            <DefaultInputSuffics
                                                value={currentProject?.document?.cadastralNumber || ''}
                                                suffics={''}
                                                placeholderDefault={'Номер'}
                                                setValue={e => changeItem({...currentProject, document: {...currentProject?.document, cadastralNumber: e}})}
                                                classNames={'input-extension'}
                                            />
                                            :   <span className={'project-detail__text'}>{currentProject?.document?.cadastralNumber || ""}</span>
                                        }
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Дата заключения договора:</label>
                                        {isEdit
                                            ?
                                            <input
                                                type={'date'}
                                                value={currentProject?.document?.dateAgencyAgreement || ''}
                                                placeholder={'Дата заключения'}
                                                onChange={e => changeItem({...currentProject, document: {...currentProject?.document, dateAgencyAgreement: e.target.value}})}
                                                className={'input-extension'}
                                            />
                                            :   <span className={'project-detail__text'}>{new Date(currentProject?.document?.dateAgencyAgreement || null).toLocaleDateString()}</span>
                                        }
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Коммерческое предложение:</label>
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Агентский договор:</label>
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Коммунальный договор:</label>
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Выписка из ЕГРН:</label>
                                    </div>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Технический или кадастровый паспорт:</label>
                                    </div>
                                </div>
                                <div className={'project-detail__block'}>
                                    <h3 className={'project-detail__block_title'}>Маркетинг</h3>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Преимущества:</label>
                                    </div>
                                </div>
                                <div className={'project-detail__block'}>
                                    <h3 className={'project-detail__block_title'}>Прочее</h3>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Автовыгрузка на Avito:</label>
                                        {isEdit
                                            ?
                                            <input
                                                type={'checkbox'}
                                                onChange={e => changeItem({...currentProject, additional: {...currentProject?.additional, autoUnloadAvito: !currentProject.additional.autoUnloadAvito}}, false)}
                                                checked={currentProject?.additional?.autoUnloadAvito || false}
                                            />
                                            :   <span className={'project-detail__text'}>{currentProject?.additional?.autoUnloadAvito ? 'Да' : 'Нет'}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        : null
                    }
                </div>
            </div>
            {showSelectorUsers &&
                <DefaultModal visible={showSelectorUsers} setVisible={e => setShowSelectorUsers(e)}>
                    <UserSelectorList
                        users={allUsers}
                        cancel={() => setShowSelectorUsers(false)}
                        currentUser={currentProject?.assignedForVariation}
                        setSelectedUser={newUser => changeItem({...currentProject, assignedForVariation: newUser}, false)}
                    />
                </DefaultModal>
            }
            {showVariationRentList &&
                <DefaultModal visible={showVariationRentList} setVisible={e => setShowVariationRentList(e)}>
                    <ProjectRentList
                        currentProject={currentProject}
                        setCurrentProject={setCurrentProject}
                        SignalRContext={SignalRContext}
                    />
                </DefaultModal>
            }
            {showVariationSellList &&
                <DefaultModal visible={showVariationSellList} setVisible={e => setShowVariationSellList(e)}>
                    <ProjectSellList
                        currentProject={currentProject}
                        setCurrentProject={setCurrentProject}
                        SignalRContext={SignalRContext}
                    />
                </DefaultModal>
            }
        </SignalRContext.Provider>
    );
};

export default ProjectSellDetail;