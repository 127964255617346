import React, {useState} from 'react';
import './FieldList.css'
import {createSignalRContext} from "react-signalr/signalr";
import DefaultModal from "../../../../../components/UI/modals/DefaultModal";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import useLoader from "../../../../../hooks/useLoader";
import useMessageHub from "../../../../../hooks/useMessageHub";
import FieldItemList from "../../components/fielditemlist/FieldItemList";
import DefaultButton from "../../../../../components/UI/buttons/button/DefaultButton";
import DefaultTable from "../../../../../components/defaulttable/DefaultTable";
import useGetComponentsForFieldList from "../../features/useGetComponentsForFieldList";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
const SignalRContext = createSignalRContext()
const VALUE_SIZE_ITEM = '1fr 1fr 1fr 1fr 0.4fr 0.5fr'
const FieldList = () => {
    const [showFieldItemList, setShowFieldItemList] = useState(false)
    const [fieldInItemList, setFieldInItemList] = useState(null)
    const [items, setItems] = useState([])
    const {show, hide} = useLoader()
    const addMessage = useMessageHub()
    const {
        connectionRef,
        onError,
        onConnect,
        onReconnect
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateAllFields()
        },
        onReconnectCallback: () => {
            updateAllFields()
        }
    })

    const updateAllFields = () => {
        show()
        SignalRContext.invoke('GetAllFields')
            .then(res => {
                if(res && Array.isArray(res))
                    setItems(res)
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const addNewField = () => {
        SignalRContext.invoke('CreateNewField')
            .then(res => {
                setItems(prev => [...prev, res])
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const removeField = (guid) => {
        SignalRContext.invoke('DeleteField', guid)
            .then(() => {
                setItems(prev => prev.filter(x => x?.guid !== guid))
            })
            .catch(res => {
                addMessage(`Ошибка: ${res}`)
            })
            .finally(() => {
                hide()
            })
    }
    const {changeItem} = useChangeItemSignalR({
        method: 'ChangeField',
        SignalRContext: SignalRContext,
        connectionRef: connectionRef,
        delay: 1000,
        callbackChangeItemFunk: newItem => setItems(prev => prev.map(item => {
            if(item?.guid === newItem?.guid) return newItem;
            else return item;
        }))
    })

    const {arrayToReturn} = useGetComponentsForFieldList({
        changeItem: changeItem,
        removeItem: removeField,
        openListItems: field => {
            setShowFieldItemList(true)
            setFieldInItemList(field);
        }

    })
    return (
        <SignalRContext.Provider
            connectEnabled={true}
            withCredentials={false}
            url={`${BaseUrl}/api/crm/fieldhublist/`}
            onOpen={onConnect}
            onReconnect={onReconnect}
            onError={onError}
        >
            <div className={'fields-list__wrapper'}>
                <div className={'fields-list__header'}>
                    <DefaultButton
                        title={'Добавить field'}
                        background={'blue'}
                        classNames={'button-extension'}
                        onClick={addNewField}
                    />
                </div>
                <div className={'fields-list__items'}>
                    <DefaultTable
                        items={items}
                        components={arrayToReturn}
                        selectKeys={item => item?.guid}
                        VALUE_SIZES={VALUE_SIZE_ITEM}
                    />
                </div>
            </div>
            {showFieldItemList &&
                <DefaultModal visible={showFieldItemList} setVisible={e => {setShowFieldItemList(e); setFieldInItemList(null)}}>
                    <FieldItemList
                        fieldInItemList={fieldInItemList}
                        SignalRContext={SignalRContext}
                        connectionRef={connectionRef}
                        setNewField={newField => {
                            setItems(prev => prev.map(item => {
                                if(item?.guid === newField?.guid) return newField;
                                else return item;
                            }))
                            setFieldInItemList(newField)
                        }}
                    />
                </DefaultModal>
            }
        </SignalRContext.Provider>
    );
};

export default FieldList;