import {useContext} from 'react';
import {LoaderContext} from "../features/loaderprovider/LoaderProvider";

const UseLoader = () => {
    const context = useContext(LoaderContext)

    const show = () => {
        context?.show();
    }
    const hide = () => {
        context?.hide();
    }
    const hideAll = () => {
        context?.hideAll();
    }
    return {
        show,
        hideAll,
        hide
    };
};

export default UseLoader;