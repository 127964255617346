import {useEffect, useRef, useState} from 'react';
import useDebounce from "./useDebounce";
import useLoader from "./useLoader";
import useMessageHub from "./useMessageHub";

const UseChangeItemSignalR = ({SignalRContext, checkConnection, delay = 1000, method, callbackChangeItemFunk, argsExternal = []}) => {

    const addMessageHub = useMessageHub()
    const {show, hide} = useLoader()
    const [endpointItem, setEndpointItem] = useState(null)
    const debouncedEndpointItem = useDebounce({delay: delay, value: endpointItem})
    const argsFunkRef = useRef([])
    const useLoaderRef = useRef(true)
    const isLoadFunkSignalR = useRef(false)
    const [entitiesToUpdate, setEntitiesToUpdate] = useState([])

    useEffect(() => {
        if(!checkConnection?.()){
            setEndpointItem(null)
            setEntitiesToUpdate([])
        }

        if(debouncedEndpointItem && checkConnection?.()){
            if(useLoaderRef.current)
                show()
            isLoadFunkSignalR.current = true
            SignalRContext.invoke(method, debouncedEndpointItem, ...argsFunkRef.current, ...argsExternal)
                .then(() => {
                    addMessageHub("Новое значение успешно установлено, данные обновлены у всех пользователей")
                    if(entitiesToUpdate.length > 0){
                        setEndpointItem(entitiesToUpdate[0])
                        setEntitiesToUpdate(prev => prev?.filter(x => x?.guid !== entitiesToUpdate[0]?.guid))
                    }
                    else
                        setEndpointItem(null)


                })
                .catch(res => {
                    addMessageHub(`Ошибка ${res}`)
                })
                .finally(() => {
                    if(useLoaderRef.current)
                        hide()
                    if(!useLoaderRef.current)
                        useLoaderRef.current = true;

                    argsFunkRef.current = []
                    isLoadFunkSignalR.current = false
                })
        }
        else if(debouncedEndpointItem && !checkConnection?.()){
            addMessageHub("Соединение потеряно, данные не сохранены, попробуйте еще раз обновить значение")
        }
    }, [debouncedEndpointItem])
    const changeItem = (newItem, useDebounce = true, argsFunk = [], useLoader = true) => {
        if(!checkConnection?.())
            return;

        argsFunkRef.current = argsFunk;
        useLoaderRef.current = useLoader;

        callbackChangeItemFunk?.(newItem, argsFunk, argsExternal)

        if(useDebounce){
            if(!endpointItem)
                setEndpointItem(newItem)
            else if(newItem?.guid !== endpointItem?.guid){
                setEntitiesToUpdate(prev => {
                    let newArray = prev?.map(item => {
                        if(item?.guid === newItem?.guid) {
                            return {
                                ...item, ...newItem
                            }
                        }
                        else return item;
                    })
                    if(!newArray?.some(x => x?.guid === newItem?.guid))
                        newArray = [...newArray, newItem]

                    return newArray;
                })

            }
            else{
                if(isLoadFunkSignalR.current){
                    setEntitiesToUpdate(prev => {
                        let newArray = prev?.map(item => {
                            if(item?.guid === newItem?.guid) {
                                return {
                                    ...item, ...newItem
                                }
                            }
                            else return item;
                        })
                        if(!newArray?.some(x => x?.guid === newItem?.guid))
                            newArray = [...newArray, newItem]

                        return newArray;
                    })
                }
                else
                    setEndpointItem(newItem)
            }
        }
        else{
            if(useLoader)
                show()
            isLoadFunkSignalR.current = true
            SignalRContext.invoke(method, newItem, ...argsFunk, ...argsExternal)
                .then(res => {
                    addMessageHub("Новое значение успешно установлено, данные обновлены у всех пользователей")
                })
                .catch(res => {
                    addMessageHub(`Ошибка ${res}`)
                })
                .finally(() => {
                    if(useLoader)
                        hide()
                    isLoadFunkSignalR.current = false
                }
            )
        }
    }

    return {changeItem};
};

export default UseChangeItemSignalR;