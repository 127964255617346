import React, {useRef, useState} from 'react';
import './ProjectRentDetail.css'
import useLoader from "../../../../../hooks/useLoader";
import {useNavigate, useParams} from "react-router";
import useMessageHub from "../../../../../hooks/useMessageHub";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import DefaultButton from "../../../../../components/UI/buttons/button/DefaultButton";
import DefaultInputSuffics from "../../../../../components/UI/inputs/tkpdefaultinputsuffics/DefaultInputSuffics";
import MultiSelectDefaul from "../../../../../components/UI/selects/multiselectdefault/MultiSelectDefaul";
import SelectDefault from "../../../../../components/UI/selects/selectdefault/SelectDefault";
import DefaultModal from "../../../../../components/UI/modals/DefaultModal";
import UserSelectorList from "../../../../../components/selectoruser/UserSelectorList";
import ProjectRentList from "../list/ProjectRentList";
import ProjectSellList from "../../projectssell/list/ProjectSellList";
import {createSignalRContext} from "react-signalr/signalr";
import {getRubString} from "../../../../../features/formatters/forrmaterrub";
import {
    converterEntityToOptionOrReverse,
    getOptionsFromArrayEntities
} from "../../../../../features/entityhelper/EntityHelper";
import useSignalRFunk from "../../../../../hooks/useSignalRFunk";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import {useMultiStepComponent} from "../../../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import ProjectInformation from "../../projects/detail/components/information/ProjectInformation";
import ProjectRentInformation from "./components/information/ProjectRentInformation";
import Test from "../../../../testpage/Test";
import useUserInfo from "../../../../../hooks/useUserInfo";
import TabsBlock from "../../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../../components/UI/buttons/buttontab/ButtonTab";
import RequisitesIcon from "../../../../../components/UI/icons/RequisitesIcon";
import InvoiceIcon from "../../../../../components/UI/icons/InvoiceIcon";
import CrossIcon from "../../../../../components/UI/icons/CrossIcon";
import DetailBody from "../../../../../components/detailcard/body/DetailBody";
import DetailWrapper from "../../../../../components/detailcard/wrapper/DetailWrapper";
import useFileUploaderToBackend from "../../../../../hooks/useFileUploaderToBackend";

const SignalRContext = createSignalRContext()
const ProjectRentDetail = () => {
    const [showSelectorUsers, setShowSelectorUsers] = useState(false)
    const [showVariationRentList, setShowVariationRentList] = useState(false)
    const [showVariationSellList, setShowVariationSellList] = useState(false)
    const [currentProject, setCurrentProject] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const dataIsLoadedRef = useRef(false)
    const [sources, setSources] = useState([])
    const [stages, setStages] = useState([])
    const [typeDistrictOptions, setTypeDistrictOptions] = useState([])
    const [typeLineOfPlacementOptions, setTypeLineOfPlacementOptions] = useState([])
    const [typeRealtyTypeOptions, setTypeRealtyTypeOptions] = useState([])
    const [typeCommunicationOptions, setTypeCommunicationOptions] = useState([])
    const [typeFloorObjectOptions, setTypeFloorObjectOptions] = useState([])
    const [typeEntranceOptions, setTypeEntranceOptions] = useState([])
    const [typeHeatingsOptions, setTypeHeatingsOptions] = useState([])
    const [typeIntendedPurposeOptions, setTypeIntendedPurposeptions] = useState([])
    const [typeObjectStatusOptions, setTypeObjectStatusOptions] = useState([])
    const [typeParkingAvailabilityOptions, setTypeParkingAvailabilityOptions] = useState([])
    const [typeSeparateEntranceOptions, setTypeSeparateEntranceOptions] = useState([])
    const [typeUnloadingAvalabilityOptions, setTypeUnloadingAvalabilityOptions] = useState([])
    const [typeNetworkAppealOptions, setTypeNetworkAppealOptions] = useState([])
    const [typeIndexOptions, setTypeIndexOptions] = useState([])
    const [typeHolidaysRequirementOptions, setTypeHolidaysRequirementOptions] = useState([])
    const [typeDivisionPossibleOptions, setTypeDivisionPossibleOptions] = useState([])
    const [typeWhoPaysUtilitiesOptions, setTypeWhoPaysUtilitiesOptions] = useState([])
    const [typeExclusiveContractOptions, setTypeExclusiveContractOptions] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const {hideAll} = useLoader()
    const params = useParams()
    const navigate = useNavigate()
    const {userInfo} = useUserInfo()
    const {
        checkConnection,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateCurrentProject()
            updateAllUsers()
            updateEnums("Districts", res => {
                setTypeDistrictOptions(res)
            });
            updateEnums("DivisionPossible", res => {
                setTypeDivisionPossibleOptions(res)
            });
            updateEnums("WhoPaysUtilities", res => {
                setTypeWhoPaysUtilitiesOptions(res)
            });
            updateEnums("ExclusiveContract", res => {
                setTypeExclusiveContractOptions(res)
            });
            updateEnums("LineOfPlacement", res => {
                setTypeLineOfPlacementOptions(res)
            });
            updateEnums("RealtyType", res => {
                setTypeRealtyTypeOptions(res)
            });
            updateEnums("Communication", res => {
                setTypeCommunicationOptions(res)
            });
            updateEnums("FloorObject", res => {
                setTypeFloorObjectOptions(res)
            });
            updateEnums("Entrance", res => {
                setTypeEntranceOptions(res)
            });
            updateEnums("Heatings", res => {
                setTypeHeatingsOptions(res)
            });
            updateEnums("IntendedPurpose", res => {
                setTypeIntendedPurposeptions(res)
            });
            updateEnums("ObjectStatus", res => {
                setTypeObjectStatusOptions(res)
            });
            updateEnums("ParkingAvailability", res => {
                setTypeParkingAvailabilityOptions(res)
            });
            updateEnums("SeparateEntrance", res => {
                setTypeSeparateEntranceOptions(res)
            });
            updateEnums("UnloadingAvalability", res => {
                setTypeUnloadingAvalabilityOptions(res)
            });
            updateEnums("NetworkAppeal", res => {
                setTypeNetworkAppealOptions(res)
            });
            updateEnums("Index", res => {
                setTypeIndexOptions(res)
            });
            updateEnums("HolidaysRequirement", res => {
                setTypeHolidaysRequirementOptions(res)
            });
            updateStatusesForTypeAndEntity("stage", "rentProject", res => {
                setStages(res)
            });
            updateStatusesForTypeAndEntity("source", "all", res => {
                setSources(res)
            });
        },
        onReconnectCallback: () => {
            updateCurrentProject()
            updateAllUsers()
            updateEnums("Districts", res => {
                setTypeDistrictOptions(res)
            });
            updateEnums("DivisionPossible", res => {
                setTypeDivisionPossibleOptions(res)
            });
            updateEnums("WhoPaysUtilities", res => {
                setTypeWhoPaysUtilitiesOptions(res)
            });
            updateEnums("ExclusiveContract", res => {
                setTypeExclusiveContractOptions(res)
            });
            updateEnums("LineOfPlacement", res => {
                setTypeLineOfPlacementOptions(res)
            });
            updateEnums("RealtyType", res => {
                setTypeRealtyTypeOptions(res)
            });
            updateEnums("Communication", res => {
                setTypeCommunicationOptions(res)
            });
            updateEnums("FloorObject", res => {
                setTypeFloorObjectOptions(res)
            });
            updateEnums("Entrance", res => {
                setTypeEntranceOptions(res)
            });
            updateEnums("Heatings", res => {
                setTypeHeatingsOptions(res)
            });
            updateEnums("IntendedPurpose", res => {
                setTypeIntendedPurposeptions(res)
            });
            updateEnums("ObjectStatus", res => {
                setTypeObjectStatusOptions(res)
            });
            updateEnums("ParkingAvailability", res => {
                setTypeParkingAvailabilityOptions(res)
            });
            updateEnums("SeparateEntrance", res => {
                setTypeSeparateEntranceOptions(res)
            });
            updateEnums("UnloadingAvalability", res => {
                setTypeUnloadingAvalabilityOptions(res)
            });
            updateEnums("NetworkAppeal", res => {
                setTypeNetworkAppealOptions(res)
            });
            updateEnums("Index", res => {
                setTypeIndexOptions(res)
            });
            updateEnums("HolidaysRequirement", res => {
                setTypeHolidaysRequirementOptions(res)
            });
            updateStatusesForTypeAndEntity("stage", "rentProject", res => {
                setStages(res)
            });
            updateStatusesForTypeAndEntity("source", "all", res => {
                setSources(res)
            });
        }
    })
    const {signalRFunk} = useSignalRFunk({
        SignalRContext,
        checkConnection
    })
    const {sendFile} = useFileUploaderToBackend()
    const updateCurrentProject = () => signalRFunk('GetProjectForId', [Number(params['idVariation'] || 0)], res => {
        setCurrentProject(res)
        dataIsLoadedRef.current = true
    })
    const updateEnums = (accessor, callback) => signalRFunk('GetAllEnumsByAccessor', [accessor], callback)
    const updateStatusesForTypeAndEntity = (type, destEntity, callback) => signalRFunk('GetAllStatusInfoByEntityAndType', [type, destEntity], callback)
    const updateAllUsers = () => signalRFunk('GetAllUsers', [], res => {
        setAllUsers(res)
    })

    const sendFilePhoto = (newFile, callback) => sendFile(newFile, 'projects', false, callback)

    const {changeItem} = useChangeItemSignalR({
        SignalRContext,
        checkConnection,
        delay: 1000,
        method: 'ChangeProject',
        callbackChangeItemFunk: newItem => setCurrentProject(newItem)
    })

    const {
        titleStep,
        step,
        goTO
    } = useMultiStepComponent([
        {
            component: <ProjectRentInformation
                currentProject={currentProject}
                changeItem={changeItem}
                setShowSelectorUsers={setShowSelectorUsers}
                typeCommunicationOptions={typeCommunicationOptions}
                typeFloorObjectOptions={typeFloorObjectOptions}
                typeLineOfPlacementOptions={typeLineOfPlacementOptions}
                typeEntranceOptions={typeEntranceOptions}
                typeUnloadingAvalabilityOptions={typeUnloadingAvalabilityOptions}
                typeSeparateEntranceOptions={typeSeparateEntranceOptions}
                typeDivisionPossibleOptions={typeDivisionPossibleOptions}
                typeExclusiveContractOptions={typeExclusiveContractOptions}
                typeHeatingsOptions={typeHeatingsOptions}
                typeHolidaysRequirementOptions={typeHolidaysRequirementOptions}
                typeIndexOptions={typeIndexOptions}
                typeIntendedPurposeOptions={typeIntendedPurposeOptions}
                typeObjectStatusOptions={typeObjectStatusOptions}
                typeParkingAvailabilityOptions={typeParkingAvailabilityOptions}
                typeRealtyTypeOptions={typeRealtyTypeOptions}
                stages={stages}
                sendFilePhoto={sendFilePhoto}
                dataIsLoadedRef={dataIsLoadedRef.current}
            />,
            titleStep: 'Информация'
        },
        {
            component: <Test/>,
            titleStep: 'Обращения'
        },
        {
            component: <Test/>,
            titleStep: 'Предложения'
        },
        {
            component: <Test/>,
            titleStep: 'Сделки'
        },
    ])

    const access = useCheckAccess("client/detail")
    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <SignalRContext.Provider
            url={`${BaseUrl}/api/crm/projectrenthubdetail/?token=${userInfo?.accessToken || ''}`}
            connectEnabled={true}
            withCredentials={false}
            accessTokenFactory={() => userInfo?.accessToken || ''}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <DetailWrapper>
                <TabsBlock>
                    <ButtonTab
                        icon={<RequisitesIcon classNames={'icon-extension'}/>}
                        title={'Информация'}
                        onClick={() => goTO(0)}
                        isActive={titleStep === 'Информация'}
                    />
                    <ButtonTab
                        icon={<InvoiceIcon classNames={'icon-extension'}/>}
                        title={'Обращения'}
                        onClick={() => goTO(1)}
                        isActive={titleStep === 'Обращения'}
                    />
                    <ButtonTab
                        icon={<InvoiceIcon classNames={'icon-extension'}/>}
                        title={'Предложения'}
                        onClick={() => goTO(2)}
                        isActive={titleStep === 'Предложения'}
                    />
                    <ButtonTab
                        icon={<InvoiceIcon classNames={'icon-extension'}/>}
                        title={'Сделки'}
                        onClick={() => goTO(3)}
                        isActive={titleStep === 'Сделки'}
                    />
                    <ButtonTab
                        title={'Назад к списку'}
                        onClick={() => navigate(-1)}

                    />
                    <ButtonTab
                        icon={<CrossIcon classNames={'icon-extension'}/>}
                        title={'Удалить вариацию'}
                        classNames={'button-extension_delete'}
                        color={'red'}
                        onClick={() => {}}
                    />
                </TabsBlock>
                <DetailBody>
                    {step}
                </DetailBody>
            </DetailWrapper>
            <div className={'project-detail__wrapper'}>
                <div className={'project-detail__body'}>

                    {dataIsLoadedRef.current
                        ?
                        <>
                            <div className={'project-detail__block'}>

                                <div className={'project-detail__block'}>
                                    <h3 className={'project-detail__block_title'}>Маркетинг</h3>
                                    <div className={'project-detail__info'}>
                                        <label className={'project-detail__label'}>Преимущества:</label>
                                    </div>
                                </div>
                            </div>
                        </>
                        : null
                    }
                </div>
            </div>
            {showSelectorUsers &&
                <DefaultModal visible={showSelectorUsers} setVisible={e => setShowSelectorUsers(e)}>
                    <UserSelectorList
                        users={allUsers}
                        cancel={() => setShowSelectorUsers(false)}
                        currentUser={currentProject?.assignedForVariation}
                        setSelectedUser={newUser => changeItem({...currentProject, assignedForVariation: newUser}, false)}
                    />
                </DefaultModal>
            }
            {showVariationRentList &&
                <DefaultModal visible={showVariationRentList} setVisible={e => setShowVariationRentList(e)}>
                    <ProjectRentList
                        currentProject={currentProject}
                        setCurrentProject={setCurrentProject}
                        SignalRContext={SignalRContext}
                    />
                </DefaultModal>
            }
            {showVariationSellList &&
                <DefaultModal visible={showVariationSellList} setVisible={e => setShowVariationSellList(e)}>
                    <ProjectSellList
                        currentProject={currentProject}
                        setCurrentProject={setCurrentProject}
                        SignalRContext={SignalRContext}
                    />
                </DefaultModal>
            }
        </SignalRContext.Provider>
    );
};

export default ProjectRentDetail;